@media only screen and (min-width: 768px) {
	.service-result__text {
		font-size: 1.8rem;
	}

	.dropdown-content {
		display: flex;
		width: auto;
		background: var(--color-white);
		font-weight: 400;
		border-radius: 5px;
		overflow: hidden;
		margin-top: 2rem;
		padding-bottom: 1.5rem;
		box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
		padding-top: 1.5rem;
	}

	.dropdown-content--left {
		width: 265px;
		font-weight: 400;
		border-radius: 20px;
		padding-left: 2rem;
		padding-right: 1rem;
	}

	.dropdown-content--right {
		width: 265px;
		font-weight: 400;
		border-radius: 20px;
		padding-left: 1rem;
		padding-right: 2rem;
	}

	.dropdown-content .menu-option {
		padding: 1.2rem 2rem 1.2rem 1rem;
		font-size: 1.3rem;
	}

	.dropdown-content .menu-option:hover {
		border-radius: 5px;
		background-color: var(--color-soft-orange);
		color: var(--color-orange);
	}

	.list-event {
		width: 130px;
	}
	.footer__list_link ul li button {
		padding: 0;
		border:none;
		width: auto;
		font-weight: 400;
	}

	.menu__desktop {
		display: block;
	}

	.header__template--new {
		background-image: url('https://movicenter-web.s3.us-east-2.amazonaws.com/index/desktop/home_new.jpg');
	}

	.header__template--used {
		background-image: url('https://movicenter-web.s3.us-east-2.amazonaws.com/index/desktop/home_used.jpg');
	}

	.header__template--blog {
		background: linear-gradient(#8585843d 85%, #fff 22%);
		min-height: 300px;
	}

	.blog__preview__date {
		order: 1;
	}

	.route__form-label {
    	font-size: 1.2rem;
    }

	.last__post__description {
		order: 3;
		padding-bottom: 4rem;
	}

	/**.last__post:after {
		content: url('../../images/blog/circle.png');
		height: auto;
		display: inline-block;
		position: absolute;
    	bottom: 14px;
	}**/

	.blog__content__main {
		height: 100%;
	}

	.title-posts {
		font-size: 3rem;
		margin-top: 7rem;
	}

	.last__post__container {
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: flex-start;
	}

	.last__post {
		text-align: left;
		padding-top: 4rem;
	}

	.last__post__title {
		font-size: 3rem;
		padding-top: 2rem;
		order: 2;
		line-height: 3rem;
	}

	.last__post .blog__button {
		text-align: left;
		order: 4;
	}

	.last__post .blog__button a {
		width: auto;
	}

	.last__post--imagen {
		width: 50%;
	}

	.main__call_action--blog {
		display: none;
	}

	.main--top--blog  .main__title--default {
		display: none;
	}

	.main--top {
		margin-top: -20.5rem;
	}
	
	.header__logo {
		order: 1;
	}

	.header {
		height: 80px;
	}

	.menu__desktop {
		order: 2;
	}

	.header__route {
		order: 3;
	}

	.header__menu {
		display: none;
	}

	.footer__container {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
	}

	.footer__list_link {
		width: 15%;
	}

	.footer__schedule {
		margin-top: 0;
		width: 25%;
	}

	.footer__logo {
		height: 279px;
    	padding-top: 7rem;
    	width: 20%;
	}

	.footer__list_link ul li {
		padding: 5px 0px;
	}

	.footer__list_link ul li.list-event {
		padding: 3px 0px;
	}

	.footer__list_link ul li a {
		font-weight: normal;
	}

	.schedule__cars {
		margin-top: 0;
	}

	.footer__logo img {
		width: 77%;
	}

	.schedule__title-hidden {
		display: block;
	}

	.main__title--default, .main__title--used {
		font-size: 2.8rem;
	}

	.main__call_action--default, .main__call_action--used {
		font-size: 2.5rem;
		font-weight: normal;
		padding: 0px 15px 2px 8px;
	}

	.card_panel--used, .card_panel--new {
	    margin-top: 2rem;
	}

	.header {
	    height: 70px;
	    padding: 0;
	}

	.menu__desktop--item li a, .menu__desktop--item li .dropdown-service {
		display: flex;
	    align-items: center;
	    justify-content: center;
	}

	.slider {
    	width: 200px;
  	}

  	.thumb {
   		width: 205px;
 	}

 	.filter-price .slider {
	  width: 120px;
	}

	.filter-price .thumb {
	  width: 125px;
	}

	.options-container--top {
		margin-top: 9rem;
	}
}


@media only screen and (min-width: 992px) {

	.header__route figure figcaption {
		font-size: 1.5rem;
	}

	.main__title--default, .main__title--used {
		font-size: 5rem;
		margin-bottom: 1.5rem;
	}

	.main__call_action--default, .main__call_action--used {
		font-size: 3rem;
	}

	.main--top {
		margin-top: -27.5rem;
	}

	.last__post__title {
	    font-size: 4rem;
		width: 80%;
		line-height: 5rem;
	}

	.last__post__description {
		font-size: 1.5rem;
	}

	.slider {
    	width: 250px;
  	}

  	.thumb {
   		width: 255px;
 	}

 	.filter-price .slider {
	  width: 180px;
	}

	.filter-price .thumb {
	  width: 185px;
	}

	.menu__desktop--item li {
		padding: 0 2rem;
	}
}

@media only screen and (min-width: 1200px) {
	.main--top {
		margin-top: -25.5rem;
	}

	.last__post__title {
	    font-size: 5rem;
	}

	.last__post__description {
	    font-size: 2rem;
	}

	.slider {
    	width: 300px;
  	}

  	.thumb {
   		width: 305px;
 	}

 	.filter-price .slider {
	  width: 200px;
	}

	.filter-price .thumb {
	  width: 205px;
	}

	.last__post:after{
		bottom: -135px;
	}

	.menu__desktop--item li {
		padding: 0 2.5rem;
	}
}