.bg-result-item {
    margin-top: -4rem;
    padding-top: 3rem;
    background-color: var(--color-soft-gray);
    margin-right: -15px;
    margin-left: -15px;
}

.result-items {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
    padding: 3rem 0;
}

.item {
    background: var(--color-white);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 1.5rem 1rem;
    min-height: 280px;
}

.item-title {
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-gray-1);
    line-height: 1.5rem;
    text-align: center;
    height: 50px;
}

.item-price {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-orange);
    margin-bottom: 0;
    height: 40px;
    text-align: center;
}

.item-trasmission {
    font-size: 1rem;
    font-weight: 400;
    color: var(--color-gray-5);
    padding-bottom: 1rem;
    border-bottom: 1px solid #e3e3e3;
    text-align: center;
    height: 30px;
}

.item-container-photo {
    height: 70px;
    width: 100%;
    margin-bottom: 2rem;
}

.item-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item-check {
    background: var(--color-white);
    border: 0.6px solid var(--color-orange);
    border-radius: 2px;
    width: 15px;
    height: 15px;
    appearance: none;
    outline: none;
    margin-right: 5px;
    position: relative;
    cursor: pointer;
}

.item-check:checked {
    background: var(--color-orange);
    border: none;
}

.item-check:focus {
    outline: none;
}

.item-check:checked::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 3px;
    width: 12px;
    height: 2px;
    background: var(--color-white);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.item-container-check {
    text-align: center;
}

.item-check:checked::after {
    content: "";
    position: absolute;
    top: 9px;
    left: 2px;
    width: 4px;
    height: 2px;
    background: var(--color-white);
    transform: rotate(45deg);
}


.item-check-label {
    font-size: 1.5rem;
    font-weight: normal;
    color: var(--color-gray-5);
    margin: .8rem 0 0 .5rem;
    cursor: pointer;
}

.container-title-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 6rem;
}

.title-search__h1 {
    font-size: 2rem;
    font-weight: 600;
    color: var(--color-gray-3);
    margin-bottom: 0;
}

.title-search {
    font-size: 2rem;
    font-weight: 600;
    color: var(--color-gray-3);
    margin-bottom: 0;
}

.title-search__count {
    font-size: 1.3rem;
    font-weight: 400;
    color: var(--color-gray-5);
    margin-bottom: 0;
    padding-right: 1rem;
}

.options-search {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.options-search--icon {
    width: 17px;
    margin-right: 7px;
    cursor: pointer;
}

.options-search--icon-top {
    transform: rotate(180deg);
}

.options-search--icon-bottom {
    transform: rotate(0deg);
}

.title-search__order {
    cursor: pointer;
    color: #212529;
}

/*************************Header de concesionario*****************/
.header-container-photo {
    margin-top: 2rem;
    position: relative;
    height: 150px;
}

.header-photo {
    object-fit: cover;
    width: 100%;
    border-radius: 5px;
    height: 100%;
}

.header-container-logo {
    width: 115px;
    height: 115px;
    background: var(--color-white);
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, 8%);
    overflow: hidden;
    z-index: 1;
}

.header-logo { 
    width: 80%;
}

.header-title {
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: 3rem;
    text-transform: uppercase;
}

.header-category {
    font-weight: normal;
    color: var(--color-gray-5);
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 4rem;
}

.header-description {
    font-size: 1.4rem;
    font-weight: normal;
    color: var(--color-gray-5);
    text-align: center;
    margin-bottom: 5rem;
}

.header-separator {
    margin-top: 7rem;
    margin-bottom: -1.5rem;
}

.panel {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px!important;
    margin-bottom: 3.5rem;
}
.panel-card {
    width: 100%;
    margin: 0 auto;
    border-radius: 0.7rem;
    box-shadow: 0px 3px 6px var(--color-gray-8);
}

.panel-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 1 auto;
    padding: 1.25rem 2rem;
}

.panel-features {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.panel-item {
    width: 100%;
    margin-bottom: .8rem;
    display: flex;
    flex-direction: row;
}

.panel-container-buttom {
    padding-left: 3rem;
}

.feature--details {
    margin-left: .5rem;
}

.panel-button {
    width: 155px;
    height: 34px;
    font-size: 1.3rem!important;
    font-weight: 400;
}

.panel-item__title {
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--color-gray-1);
    margin-bottom: 0;
}

.panel-item__value {
    font-size: 1.3rem;
    font-weight: normal;
    color: var(--color-gray-5);
    margin-bottom: 0;
}

.panel-item__value--red {
    color: #D83009;
    text-decoration: underline;
}

.panel-item__imagen {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: .5rem;
}

.features__imagen--category {
    content: url('../assets/images/category.svg');
}

.features__imagen--local {
    content: url('../assets/images/local.svg');
}

.features__imagen--phone {
    content: url('../assets/images/telephone.svg');
}

a.panel-item__value:hover {
    color: var(--color-orange);
}

.widget-foooter {
    background: var(--color-white);
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    left: 0;
    right: 0;
}

.widget-foooter-buttom {
    height: 88px;
    display: flex;
    align-items: center;
}

.widget-container {
    position: fixed;
    bottom: 1rem;
    cursor: pointer;
    display: none;
    box-shadow: 0px 3px 6px #f0f0f0;
    z-index: 10000;
    width: 80px;
    height: 80px;
    background: var(--color-orange);
    border-radius: 50%;
}

.widget-container-text {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}

.widget-container-subtitle {
    height: 30px;
    background: var(--color-white);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 30px;
    margin-bottom: 0;
    padding: .5rem 1rem;
    top: 1rem;
    right: -7rem;
    font-weight: 500;
    font-size: 1.3rem;
    color: var(--color-orange);
    position: absolute;
    top: 1rem;
    right: -18rem;
    width: 195px;
    text-align: center;
    cursor: pointer;
}

.widget-container-image {
    width: 50%;
    cursor: pointer;
}

.btn-search {
    width: 198px;
    height: 42px;
    border-radius: 5px;
    font-size: 2rem!important;
    font-weight: 500;
}

.form-search {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    margin: 0 auto;
}

.form-search__select {
    box-shadow: 0px 3px 6px #00000012;
    border: 0.30000001192092896px solid #858584;
    border-radius: 5px;
    width: 93%;
    height: 37px;
    font-size: 1.6rem;
    letter-spacing: 0px;
    color: #212529;
    padding-bottom: 0;
    margin: 1rem auto 2rem;
    padding-left: 1rem;
}

.bg-result-top {
    padding-top: 7rem;
}

@media only screen and (min-width: 380px) {
	.item-title {
        font-size: 1.5rem;

    }
    
    .item-price {
        font-size: 1.4rem;
    }
    
    .item-trasmission {
        font-size: 1.2rem;
    }

    .item {
        padding: 1.5rem
    }
}

@media only screen and (min-width: 414px) {
    .item-container-photo {
        height: 90px;            
    }

    .result-items {
        row-gap: 5rem;
        column-gap: 3rem;
    }
}

@media only screen and (min-width: 450px) {
	.item-title {
        font-size: 1.5rem;

    }
    
    .item-price {
        font-size: 1.4rem;
    }
    
    .item-trasmission {
        font-size: 1.4rem;
    }
    
}

@media only screen and (min-width: 450px) {
	.item-container-photo {
        height: 100px;            
    }

    .result-items {
        column-gap: 5rem;
    }
    
}

@media only screen and (min-width: 768px) {
    .form-search {
        flex-direction: row;
        background: #FFFFFF;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        width: 550px;
        padding: 0 2rem;
        height: 108px;
    }

    .form-search__select {
        width: 300px;
        height: 50px;
        margin: 0 2rem 0 0;
    }

    .btn-search {
        height: 50px;
        border-radius: 6px;
    }

    .bg-result-top {
        padding-top: 10rem;
    }

    .panel-container-buttom {
        width: 200px;
        padding-top: 2rem;
    }

    .panel-item__imagen {
        width: 50px;
        height: 50px;
    }
    
	.result-items {
        grid-template-columns: repeat(3,1fr);
        column-gap: 4rem;
    }

    .item-title {
        font-size: 1.7rem;
        line-height: 2rem;
    }

    .item-trasmission {
        font-size: 1.5rem;
    }

    .title-search__h1 {
        font-size: 2.5rem;
    }

    .title-search {
        font-size: 3rem;
        margin-bottom: 3rem;
    }
    
    .title-search__count {
        font-size: 1.5rem;
    }

    .item-container-photo {
        height: 90px;
    }

    .item-photo {
        object-fit: contain;
    }

    .item-price {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: .5rem;
        font-size: 1.6rem;
    }

    .header-container-photo, .header-photo {
        height: 180px;
    }

    .header-title {
        font-size: 3rem;
    }

    .header-category {
        font-size: 1.6rem;
    }

    .header-description {
        font-size: 1.6rem;
    }

    .header-separator {
        margin-top: 6rem;
        margin-bottom: -1rem;
    }

    .panel-body {
        padding: 3rem 2rem;
    }

    .panel-features {
        flex-direction: row;
    }

    .panel-buttom {
        padding: 0;
    }

    .panel-item__title {
        font-size: 1.4rem;
    }

    .panel-button {
        width: 100%;
        font-size: 1.2rem!important;
    }

    .widget-foooter {
        display: none;
    }

    .widget-container {
        display: block;
    }
}

@media only screen and (min-width: 992px) {

    .panel-container-buttom {
        width: auto;
        padding-top: 0;
    }

	.result-items {
        grid-template-columns: repeat(4,1fr);
        column-gap: 3rem;
    }
    
    .panel-body {
        flex-direction: row;
        justify-content: space-around;
    }

    .panel-features {
        width: 65%;
    }

    .panel-buttom {
        margin-top: 0;
        width: 30%;
        padding-left: 2rem;
    }

    .panel-item__title {
        font-size: 1.7rem;
    }

    .panel-button {
        font-size: 2rem!important;
        width: 250px;
        height: 60px;
    }
}

@media only screen and (min-width: 1200px) {

    .item {
        padding: 1.5rem 2rem 1rem;
        height: 310px;
    }

    .header-title {
        font-size: 4rem;
    }

    .result-items {
        column-gap: 5rem;
    }

    .panel-item__title {
        font-size: 2rem;
    }
    
}