.col-12-p {
    padding-left: 0px!important;
    padding-right: 0px!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
}

.container-p {
    padding-left: 0px!important;
    padding-right: 0px!important;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title-container {
    width: 85%;
    margin: 0 auto 4rem;
}

.title {
    font-size: 2rem;
    font-weight: bold;
    color: var(--color-gray-1);
    margin-bottom: rem;
    margin-top: 4rem;
}

.sub-title {
    font-size: 1.5rem;
    color: var(--color-dark-gray);
    width: 200px;
    margin: 0 auto;
}

.sub-title-new {
    color: var(--color-dark-gray);
    font-size: 1.3rem;
    line-height: 2rem;
    margin-bottom: 3rem;
}

.btn-go {
    width: 240px;
    height: 52px;
    display: flex!important;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 1.8rem!important;
    border-radius: 10px!important;
}

.sub-title-footer {
    font-size: 1.3rem;
    font-weight: normal;
    color: var(--color-gray-1);
    margin-top: 8rem;
    margin-bottom: .5rem;
}

.msocial_media {
    padding-top: 1.6rem;
    padding-bottom: 6.5rem;
    display: flex;
    justify-content: center;
}

.m-footer_bg {
    background-color: var(--color-orange);
    height: 15px;
    width: 100%;
}

.m-footer--p {
    position: absolute;
    right: 2rem;
    top: -2.2rem;
}

.m-footer {
    position: relative;
}

.m-footer--image {
    width: 82%;
}

.container-h {
    height: 120px;
}

@media only screen and (min-width: 380px) {
    .title {
        font-size: 2.4rem;
    }

    .sub-title {
        font-size: 1.6rem;
        width: 230px;
    }

    .sub-title-new {
        font-size: 1.5rem;
    }

    .sub-title-footer {
        font-size: 1.5rem;
    }
}


@media only screen and (min-width: 768px) {
    .container-h {
        height: 180px;
    }

    .title {
        font-size: 2.8rem;
        margin-bottom: .5rem;
    }

    .sub-title {
        font-size: 1.9rem;
        width: 100%;
    }

    .sub-title-new {
        font-size: 1.9rem;
        width: 480px;
        margin: 0 auto 3rem
    }

    .sub-title-footer {
        font-size: 1.6rem;
    }
}

@media only screen and (min-width: 992px) {
    .container-h {
        height: 240px;
    }

    .title {
        font-size: 3rem;
        margin-bottom: .5rem;
    }

    .sub-title {
        font-size: 2rem;
    }

    .sub-title-new {
        font-size: 2rem;
        width: 600px;
    }

    .sub-title-footer {
        font-size: 1.8rem;
    }

    .title-container {
        margin: 0 auto 6rem;
    }
}

@media only screen and (min-width: 1200px) {
    .container-h {
        height: 300px;
    }

    .title {
        font-size: 3.5rem;
        margin-bottom: 1rem;
    }

    .sub-title {
        font-size: 2.5rem;
    }

    .sub-title-new {
        font-size: 2.2rem;
        width: 650px;
    }

    .sub-title-footer {
        font-size: 2rem;
    }
}