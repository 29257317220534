.modal-soap {
    font-family: 'Poppins', sans-serif;
}

.soap-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--color-gray-1);
    text-align: left;
}

.soap-subtitle {
    font-size: 1.1rem;
    font-weight: 400;
    color: #707070;
    text-align: left;
}

.soap-subtitle__link {
    color: rgb(65, 104, 255);
    font-weight: 600;
    cursor: pointer;
}

.soap-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 259px;
}

.soap-item__images {
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.soap-item__images .img-logo__renta, .soap-item__images .img-logo__mapfre {
    width: 120px;
}

.soap-item__images .img-logo__hdi {
    width: 84px;
}

.soap-item__title {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--color-gray-1);
    margin-bottom: 0;
}

.soap-item__subtitle {    
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--color-gray-1);
}

.soap-item__price {
    font-size: 2rem;
    font-weight: 600;
    color: var(--color-orange);
}

.soap-item__validity {    
    font-size: 1.1rem;
    font-weight: 400;
    color: #707070;
    position: relative;
    padding-left: 1rem;
}

.soap-item__validity::before {
    content: url("../../images/calendar.png");
    position: absolute;
    left: -0.5rem;
    bottom: 1.4rem;
}

.soap-item__btn {
    height: 37px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--color-white);
}

.soap-item__btn:hover {
    background-color: rgb(244 116 34 / 80%);
    border-color: rgb(244 116 34 / 80%);
}

.soap-item__recommend {
    background: var(--color-orange);
    border-radius: 0px 0px 5px 5px;
    height: 24px;
    position: absolute;
    top: 0;
    left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1.5rem;
    padding-right: 2.5rem;
}

.item__recommend {    
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-white);
    margin-bottom: 0;
}

.item__recommend::after {
    content: url("../../images/info.png");
    position: absolute;
    right: 1rem;
    top: 0.5rem;
}

.soap-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.card-item {
    margin-right: 0;
    margin-bottom: 2rem;
    height: 259px;
}

.modal-90w__soap .modal-content {
    min-height: 80vh;
}

.container-soap {
    margin-top: 30rem;
}

.notification-text__title--soap {
    margin-bottom: 1rem;
    font-size: 2.5rem;
}

.notification-text__small {
    font-size: 1.3rem;
    color: var(--color-gray-1);
    font-weight: 600;
}

.soap-filter__label {
    color: var(--color-orange);
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    width: 170px;
}

.soap-filter__select, .soap-filter__select option {
    font-size: 1.4rem;
    height: 32px;
    width: 100%;
    cursor: pointer;
}

.soap-filter__select:focus {
    color: #495057;
    background-color: #fff;
    border-color: var(--color-orange);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(244 116 34 / 25%);
}

.soap-filter__label::before {
    content: url("../../images/filter-soap-mobile.png");
    margin-right: .5rem;
    display: inline-block;
    margin-top: 0.5rem;
}

.soap-filter__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3rem;
    margin-top: 3rem;
}

@media only screen and (min-width: 360px) {
    .soap-item__validity::before {
        bottom: -0.2rem;
    }
}

@media only screen and (min-width: 375px) {
    .soap-title {
        font-size: 2rem;
        font-weight: 600;
        color: var(--color-gray-1);
        text-align: left;
    }

    .soap-subtitle {
        font-size: 1.5rem;
    }

    .soap-item__validity::before {
        bottom: -0.2rem;
    }
}

@media only screen and (min-width: 768px) {
    .card-item {
        width: 249px;
        margin-bottom: 3rem;
        margin-right: 1rem
    }

    .soap-container {
        max-height: 520px;
        overflow-y: scroll;
        flex-direction: row;
        justify-content: center;
    }

    .soap-container::-webkit-scrollbar {
        width: 10px;
    }
    
    .soap-container::-webkit-scrollbar-track {
        background-color: var(--color-white);
        border-radius: 5px;
        box-shadow: none;
    }
    
    .soap-container::-webkit-scrollbar-thumb {
        background: #8A8B8D;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 30px;
    }

    .notification-soap {
        width: 160px;
        height: 160px;
    }

    .modal-90w__soap {
        width: 90%;
        max-width: none!important;
    }

    .soap-title {
		text-align: center;
		font-size: 2.3rem;
	}

	.soap-subtitle {
		text-align: center;
		font-size: 1.8rem;
	}

    .soap-filter__group {
        flex-direction: row;
        margin-left: 4rem;
    }

    .soap-filter__select {
        width: 500px;
    }

    .soap-filter__label {
        font-size: 1.6rem;
    }
}

@media only screen and (min-width: 992px) {
    .card-item {
        width: 249px;
        margin-bottom: 3rem;
        margin-right: 1.5rem;
    }
}

@media only screen and (min-width: 1200px) {
    .card-item {
        width: 260px;
        margin-bottom: 3rem;
        margin-right: 2rem;
    }
}

@media only screen and (min-width: 1900px) {
    .card-item {
        width: 270px;
        margin-bottom: 3rem;
        margin-right: 2rem;
    }
}