@media only screen and (min-width: 768px) {
	.row-quote__bg {
		display: none;
	}
	.page-car__section_1 {
	    flex-direction: row;
	    justify-content: space-between;
	}

	.banner-result img {
		object-fit: contain;
	}

	.banner-filter {
    	height: 300px;
    }

	.page-car__information, .accordion-car__row {
		width: 100%;
		margin-right: 1.5rem;
		margin-left: 0;
	}

	.accordion-header  p {
		padding-left: 1rem;
	}

	.form-custom__group label {
    	font-size: 1.1rem;
    }

    .form-custom__title {
    	margin-bottom: 1.5rem;
    	font-size: 1.5rem;
    	margin-top: 1rem;
    }

    .form-custom--label {
    	font-size: .75rem;
    }

    .form-custom__button {
    	font-size: 1.5rem;
    }

	.mySwiperMain {
	    height: 260px!important;
	}

	.form-contact__car {
		margin-top: 0;
	}

	.mySwiperMain .swiper-slide img {
	    width: 100%;
	    height: 100%;
	    object-fit: contain;
	}

	.info-car__marca {
		order: 1;
		display: block;
	}

	.info-car__description--info {
		order: 2;
		align-self: flex-start;
		width: 100%;
	}

	.infor-car__share {
		order: 1;
	}

	.info-car__description {
		order: 2;
		justify-content: flex-start;
	}

	.info-car-features {
		order: 3;
	}

	.page-car__section_2 {
		justify-content: space-between;
		flex-direction: row;
	}

	.accordion-car .accordion .card {
		border: 0;
	}

	.accordion-car .accordion .card .card-header {
		padding: .75rem 0rem;
	}

	.details-car--title {
    	font-size: 1.2rem;
    }

    .details-car__group {
    	border-bottom: 1px solid rgba(0,0,0,.125);
    	padding-bottom: .5rem;
    }

    .details-car__group:last-child{
    	border-bottom: 0;
    	margin-bottom: 3rem;
    }

    .mapa-row__container {
    	width: 40%;
	    margin-top: -3rem;
	    padding-right: 2rem;
	    padding-left: .5rem;
    }

    .banner_up_right {
    	display: block;
    }

    .accordion-car {
    	margin-top: 1rem;
    }

    .car-service {
		flex-direction: row;
		height: 550px;
	}

	.page-car {
	    margin-right: -15px;
	    margin-left: -15px;
	}

	.page-car .car-financing {
		border-radius: 0;
		background: var(--color-orange);
	}

	.car-service__title {
		color: var(--color-white);
		font-size: 1.2rem;
	}

	.car-service-button--title {
		color: var(--color-white);
		font-size: 1.4rem;
	}

	.car-service__description {
		padding: 4rem 6rem 1rem 5rem;
	}

	.car-service-button {
    	border-bottom: 1px solid rgb(255 255 255 / 13%);
    }

    .car-service-button--subtitle {
    	color: var(--color-white);
    	font-size: 1.1rem;
    }

    .option-filter-d {
    	display: none;
    }

    .car-service-button--image img {
		width: 30px;
	}

	.car-service__image {
		height: auto;
		margin-top: -1px;
	    margin-left: -1px;
	    margin-bottom: -1px;
	}

	.car-service__image img {
		height: 400px;
	}

	.car-banner__detail {
		height: 96px;
	}

	.car-banner__tired {
		height: 96px;
	}

	.file-to-car {
		border: 1px solid rgba(0,0,0,.125);
		box-shadow: 0px 3px 6px var(--color-gray-8);
		margin-bottom: 2rem;
	}

	.file-to-car__title {
		font-size: 2rem;
    	font-weight: 600;
    	color: var(--color-gray-5);
	}

	.file-to-car__title-des {
		font-size: 2.3rem;
	}


	.banner_up_right {
	    height: 400px;
	}

	.form-custom__input {
		font-size: 1rem;
	}

	.accordion .card {
		margin-left: 0;
	}

	/***********************Resultado de búsqueda******************************************/
	.options-search {
		display: none;
	}

	.search-programada {
		background: #FEEEE4;
    	padding: 1rem;
	}

	.search-programada--return {
		display: none;
	}

	.search-result__small {
		margin-bottom: 0;
	}

	.options-search--order {
		text-align: right;
		width: 100%;
	}

	.container-info-row {
		flex-direction: row;
		justify-content: space-between;
	}

	.car-features {
		width: 31%;
	}

	.infor-car__result {
		flex-direction: column;
    	align-items: flex-start;
	}
	
	.car-features__imagen {
		width: 11px;
	}

	.info-car__description--price {
		margin-bottom: 0;
	}

	.infor-car__result .result-details__image img {
		object-fit: cover;
	}

	/**.option-search__container {
		display: none;
	}**/

	.option-filter__text{
		font-size: 1.2rem;
	    font-weight: normal;
	    font-family: 'Poppins', sans-serif;
	    color: var(--color-gray-1);
	    margin-bottom: 0;
	    opacity: 0.6;
	}

	.modal-result__form {
    	width: 100%;
    }

    .option-filter__container .option-item-text {
    	font-size: .8rem;
    }

    .slide-car {
    	width: 100%;
    }

    .result-details__slide {
	    width: 40%;
	}

	.info-car__view {
		width: 55%;
	}

	.info-car__description--modelo {
		font-size: 1.8rem;
	}

	.info-car__description--type {
		margin-bottom: 1rem;
	}

	.favorite-car img {
		width: 40px;
    	height: 40px;
	}

	.result-foooter {
		display: none;
	}

	.d-result-compare__button {
		display: block;
	}

	.modal-spinner {
		max-width: 600px;
	}

	.form-custom__input {
		font-size: 1.3rem;
	}

	.modal-spinner .loading-car__text {
		font-size: 2.5rem;
		margin-top: -6rem;
	}

	.modal-spinner .loading--tire{
		margin-top: -2rem;
	}

	.service-result__small {
		font-size: 1.5rem;
	}

	.service-result__h1 {
		font-size: 2.5rem;
	}

	.panel-detail__car .info-car__description--info {
		margin-left: 2rem;
	}

	.panel-detail__car .price-car {
		padding-left: 8rem;
	}

	.car-service-button--text {
		width: 80%;
	}

	.banner-home__position_4 {
		height: 151px;
		margin-bottom: 7rem;
	}


	.car-service__container {
		width: 520px;
	}

	.car-service-button {
		height: 7rem;
	}

	.car-service-button--arrow {
		content: url('../../images/icons/down-arrow-white.svg');
		height: 20px;
	}

	.car-service__title {
		margin-bottom: 4rem;
	}

	.page-car{ 
		margin-top: 7rem;
	}

	 .banner__top_mobile--6{
	 	margin-top: -45px;
	 }
}


@media only screen and (min-width: 992px) {
	.page-car__information {
		width: 65%;
	}

	.car-service__image img {
	    height: 100%;
	}

	.mySwiperMain {
	    height: 300px!important;
	}

	.mySwiperMain .swiper-slide {
	    height: 100%;
	}

	.mySwiperSecondary .swiper-slide img {
	    border-radius: 6px;
	    width: 100%;
	    object-fit: contain;
	    height: 100%;
	}

	.form-custom--label {
	    font-size: .9rem;
	}
	
	.infor-car__share--link {
		font-size: 1.5rem;
	}

	.banner-filter {
    	height: 400px;
    }

	.car-feature__title, .car-feature__value {
    	font-size: 1rem;
    }

    .details-car--title {
	    font-size: 1.5rem;
	}

	.details-car {
		padding-left: 1rem;
	}

	.car-banner__detail {
	    height: 140px;
	}

	.car-banner__tired {
		height: 140px;
	}

	.banner_up_right {
	    height: 500px;
	}

	.mapa-row__container {
	    padding-right: 0;
	    padding-left: 5.5rem;
    }

    .filter__select {
    	display: flex;
    	flex-direction: row;
    }

    .filter__item .search-advanced_form .search-advanced_form__control {
	    height: 25px;
	    width: 90%;
	}

	.form__filter-label, .form-filter__title {
		font-size: 1.4rem;
	}

	.filter__button button {
		padding: .375rem 1.5rem;
    	font-size: 1.1rem;
	}

	.option-filter__container .option-item-text {
	    font-size: 1rem;
	}

	.slider-car--height {
    	height: 230px;
    }

    .slider-car--height .swiper-slide {
    	width: 330px;
    }

    .result-details__slide {
		width: 48%;
	}

    .result-details__image {
	    margin: 0 auto;
	    height: 280px;
    	width: 280px;
	}

	.result-details__image img {
	    height: auto;
	    width: 100%;
	    object-fit: contain;
	}

	.car-feature__title, .car-feature__value {
	    font-size: 1.2rem;
	}

	.car-service-button--title {
		font-size: 1.9rem;
	}

	.car-service-button--subtitle {
		font-size: 1.2rem;
	}

	.car-service__image {
		width: 45%;
	}

	.car-service__title_2 {
    	font-size: 4rem;
    }

    .car-service__title {
    	font-size: 1.7rem;
    }

	.banner-home__position_4 {
		height: 200px;
	}
}

@media only screen and (min-width: 1200px) {
	.mySwiperMain {
	    height: 325px!important;
	}

	.banner-filter {
    	height: 500px;
    }

	.page-car__information {
	    width: 60%;
	}

	.form-custom--label {
	    font-size: 1.2rem;
	}

	.form-custom__group label {
	    font-size: 1.2rem;
	}

	.form-custom__button {
	    font-size: 2.5rem;
	    padding: 1rem 1rem;
	}

	.car-feature__title, .car-feature__value {
	    font-size: 1.3rem;
	}

	.car-banner__detail {
	    height: 162px;
	}

	.car-banner__tired {
	    height: 156px;
	}

	.mapa-row__container {
	    padding-right: 0;
	    padding-left: 5rem;
	}

	.banner_up_right {
	    height: 530px;
	}

	.option-filter__link {
		flex-direction: row;
	}

	.banner-home__position_4 {
		height: 238px;
	}
}