.title-h1 {
	font-size: 2rem;
	font-weight: 600;
	color: var(--color-gray-1);
	
}

.main {
	display: flex;
	flex-direction: column;
	padding-top: 8rem;
}

.container-featured {
	display: flex;
	flex-direction: column;
}

.carousel-initial {

}

.form-contact {

}

.featured {
	padding-top: 3rem;
}

.featured-title {
	background: var(--color-orange);
	color: var(--color-white);
	width: 300px;
    height: 43px;
    margin-bottom: 2rem;
    margin-top: 5rem;
    border-radius: 7px;
    font-weight: 600;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-recommend {
	display: flex;
	flex-direction: column;
	margin-bottom: 3rem;
}

@media only screen and (min-width: 768px) {
	.main {
		flex-direction: row;
		justify-content: space-between;
	}

	.carousel-initial {
		width: 55%;
	}

	.form-contact {
		width: 40%;
	}

	.form-contact {
		padding-top: 5rem;
	}

	

	.banner-map {
		margin-top: -29rem;
	}

	.container-featured {
		flex-direction: row;
		justify-content: space-between;
	}
}

@media only screen and (min-width: 1200px) {
	.carousel-initial {
		width: 60%;
	}

	.form-contact {
		width: 37%;
	}

	.featured {
		width: 60%;
	}

	.banner-map {
		width: 37%;
	}


}