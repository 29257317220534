
.blog__preview__text {
	font-size: 1.5rem;
    color: #858584;
    margin-top: 1.5rem;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
}

.blog__content__text { 
	font-size: 1.5rem;
	color:#858584;
	text-align: justify;
}


.my-blog-back  {
	position: absolute;
    right: 0;
    top: 9rem
}

.blog__content__title {
	font-size: 1.3rem;
	color:#858584;
	margin-bottom: 0;
}

.content__title_border {
	border-left: none;
    padding-left: 0;
}

.container-title {
	display: flex;
	flex-direction: column;
}

.blog__content__img {
	width: 100%;
	/**height: 70vh;**/
	object-fit: cover;
	overflow:hidden;
	border-radius:10px;
}

.blog__content__main {
	width: 100%;
	object-fit: none;
	overflow:hidden;
	height: 100%;
}

.last__post__title {
	font-size: 2rem;
    color: var(--color-white);
    font-weight: bold;
    line-height: 2rem;
}

.last__post__description {
	font-size: 1.3rem;
	font-weight: 600;
	font-family: 'Poppins', sans-serif;
	color: var(--color-white);
	line-height: 1.6rem;
	margin-top: 1rem;
}

.blog__preview__img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	overflow:hidden;
	border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card-posts {
	border-radius: 10px !important;
	box-shadow: 0 0rem 1rem rgba(0,0,0,.175)!important;
}

.blog_cathegory {
	display: inline-block;
	border: 1px solid #F47422;
	border-radius: 5px;
	padding: 5px;
	color:#F47422;
	margin-bottom: 0;
	font-size: 1.2rem;
	font-family: 'Poppins', sans-serif;
}

.card__content {
	padding: 20px 15px 15px;
    min-height: 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-dark-gray);
    height: 215px;
}

.blog__preview__date {
	margin-bottom: 0;
    color: var(--color-white);
    font-size: 1.4rem;
}

.container-main--img {
	height: 350px;
	background-image: linear-gradient(to left, rgb(255 255 255 / 10%), rgb(0 0 0 / 75%));
}

.blog__date__post {
	margin-bottom: 0;
    color: var(--color-orange);
    font-size: 1.4rem;
}

.header__template--blog {
	background-image: url('../../images/blog/background.png');
	background-repeat: no-repeat;
	object-fit: cover;
	min-height: 500px;
    background-position: right top;
	background-size: 100%;
}

.blog-row {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 3rem;
}

.blog-item {
	width: 100%;
	min-height: 380px;
	margin-bottom: 5rem;
	background-color: var(--color-white);
}

.main--top--blog {
	margin-top: 10rem;
}

.main__call_action--blog {
    font-size: 1rem;
    font-weight: 600;
}

.line__article {
	width: 80px;
    border: 2px solid;
    border-color: #F47422;
}

.responsive__img {
	display:none;
}
.blog__container .swiper-container .swiper-pagination .swiper-pagination-bullet-active {
	opacity: 1;
    background: var(--color-orange);
	overflow: hidden;
}

.last__post__container {
	display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    margin-top: 3rem;
}

.last__post {
	text-align: center;
	display: flex;
	flex-direction: column;
}

.last__post--imagen {
	width: 100%;
}

.last__post .blog__button a {
	width: 100%;
}

.blog_main--title {
	font-size: 1.7rem;
    color: var(--color-orange);
    font-weight: 600;
    line-height: 2.3rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 71px;
    margin-bottom: .5rem;
}


.youtube-responsive {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
	}
	
	.youtube-responsive iframe {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	}



.p-absolute__post {
	position: absolute;
	background-image: linear-gradient(to left, rgb(255 255 255 / 10%), rgb(0 0 0 / 75%));
	height: 350px;
}

.row-last {
	background: var(--color-ligth-gray);
}

.title-posts {
	margin-top: 5rem;
}

@media only screen and (min-width: 400px) {
	.main__call_action--blog {
    	font-size: 1.3rem;
   	}

	.blog_main--title {
		font-size: 2rem;
	}
	.btn-reed-article {
		width: 200px!important;
	}
}

@media only screen and (min-width: 600px) {
	.blog-item {
		width: 250px;
	}
   
	
}