@media only screen and (min-width: 768px) {
	.service__backdrop--tire {
	    background: url('../../images/background/home_tire_desktop.jpg') no-repeat;
	    background-size: 100% 80%;
    	height: 240px;
	}

	.services__title__tire {
    	font-size: 2.5rem;
    }

	.services__title__authorized {
    	font-size: 2.5rem;
    }

	

	.service-search__control {
		flex-direction: row;
		justify-content: space-between;
	}

	.d-tire__return span {
	    width: 25px;
	    height: 25px;
	}
	

	.service-search__tab {
		height: 49px;
	}

	.service-search__tab p {
		font-size: 1.7rem;
	}

	.services__title {
    	font-size: 5.5rem;
    }

    .banner__tire {
    	height: 13rem;
    	margin-top: 0;
    	margin-bottom: 3rem;
    }

    .carousel_tire__title {
    	margin-top: 5rem;
    }

    .carousel_tire__title {
    	font-size: 2.8rem;
    }

    .form-contact__container {
		width: 70%;
	}

	.form-opciones__group {
		flex-direction: row;
	}

	.message-alert {
		width: 350px;
		justify-content: flex-start;
    	padding-left: 2rem;
	}


	.service-result__container {
		justify-content: flex-start;
	}

	.service-result-item {
	    width: 200px;
    	/**height: 250px;**/
        margin-right: 3rem;
        margin-bottom: 3rem;
	}

	.result-item__title {
    	font-size: 1.4rem;
    	height: 50px;
    }

    .result-item__number  {
    	font-size: 1.5rem;
    }

    .result-item__brand {
    	width: 100%;
    }

    .tires__imagen {
    	width: 130px;
    	height: 70px;
    }
}

@media only screen and (min-width: 992px) {
	

	.services__title__tire {
	    font-size: 3.5rem;
	}

	.services__title__authorized {
	    font-size: 3.5rem;
	}

	.service-search__row {
		width: 30%;
	}

	.carousel_tire__title  {
		font-size: 3.5rem;
	}

	.result-item__title {
    	font-size: 1.7rem;
    }

    .tires__imagen {
	    width: 150px;
	    height: 90px;
	}

	.service-info__measure {
    	font-size: 1.4rem;
    }

}

@media only screen and (min-width: 1200px) {

	.service-result-item {
	   /** height: 250px;**/
	    width: 240px;
	    margin-right: 3rem;
	    margin-bottom: 3rem;
	}

	.tires__imagen {
	    width: 160px;
	    height: 100px;
	}

	.services__title__tire {
	    font-size: 5rem;
	}

	.services__title__authorized {
	    font-size: 7rem;
	}
}