@media only screen and (min-width: 768px) {
	.route__title {
		font-size: 3rem;
		line-height: 4rem;
	}

	.route__text {
    	font-size: 1.5rem;
    }

    .route-title {
    	width: 60%;
    }

    .route__features .route__image {
	    width: 50%;
	    margin-top: -10rem;
	}

	.route {
		height: 300px;
		margin-top: 3rem;
	}

	.route-info-car {
		flex-direction: row;
		justify-content: space-between;
	}

	.car-selected {
		width: 50%;
		margin-top: 0;
	}

	.route-form-contact {
		width: 42%;
	}

	.car-selected__title {
		font-size: 1.5rem;
	}

	.car-selected__type {
    	font-size: 1.3rem;
    }

    .car-selected__price {
    	font-size: 1.8rem;
    }

    .car-selected__image {
	    width: 300px;
	    height: 100px;
	}

	.car-selected__marca {
	    width: 80px;
	}

	.car-selected__deleted {
    	align-self: start;
    }

    .car-selected__details {
    	padding-left: 1rem;
    }

    .car-selected__images {
    	margin-bottom: 0;
    	width: 40px;
    	height: 30px;
    }

    .car-selected__images img {
    	height: 100%;
    	object-fit: contain;
    }

    .route__features ul {
    	display: flex;
	    justify-content: space-between;
	    margin-top: 1.5rem;
	    width: 65%;
    }

    .route__features ul li {
	    font-size: 1.1rem;
	}

	.route-title-left, .route-title-right  {
		display: block;
	}

	.icon__route--star::before {
		content: url('../../images/my-route/route-icon-star-d.png');
		width: 25px;
	    height: 25px;
	}

	.icon__route--find::before {
		content: url('../../images/my-route/route-icon-find-d.png');
		width: 25px;
	    height: 25px;
    }

	.icon__route--visit::before {
		content: url('../../images/my-route/route-icon-visit-d.png');
		width: 25px;
	    height: 25px;
	}

	.car-selected__image img {
		object-fit: contain;
	}
}

@media only screen and (min-width: 992px) {
	.route__title {
		font-size: 4rem;
		line-height: 4rem;
		margin-bottom: 2rem;
	}

	.route__text {
    	font-size: 1.6rem;
    }

    .route-title {
    	width: 60%;
    }

    .route__features ul li {
	    font-size: 1.3rem;
	}

	.car-selected {
		width: 48%;
	}

	.route-form-contact {
	    width: 48%;
	}

	.car-selected__image {
	    width: 250px;
	    height: 100px;
	}

	.car-selected__marca {
	    width: 80px;
	    height: 25px;
	}

	.route__features .route__image {
	    width: 40%;
	}

	.car-selected__price {
		flex-direction: row;
    	justify-content: space-between;
	}
}

@media only screen and (min-width: 1200px) {
	.car-selected {
	    width: 45%;
	}

	.route-form-contact {
	    width: 38%;
	}
	.route__text {
	    font-size: 1.9rem;
	}
}