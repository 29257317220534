.service-search__control {
	display: flex;
	flex-direction: column;
}

.service-search__button {
    width: 100%;
    margin: 3rem auto 0 auto;
}

.tires__carousel .swiper-slide {

}

.tires__imagen {
	width: 100px;
	height: 50px;
	text-align: center;
	margin-bottom: 1rem;
	display: flex;
    align-items: center;
    justify-content: center;
}

.tires__imagen img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.tires__carousel .swiper-pagination-bullet {
	background: var(--color-gray-6);
}

.tires__carousel .swiper-pagination-bullet-active {
	background: var(--color-orange);
}

.form-opciones__subtitle {
	font-family: 'Poppins', sans-serif;
	font-size: 2rem;
	font-weight: normal;
	color: var(--color-gray-5);
	margin-bottom: 0;
}


