/**********Message del detalle del auto******************/
.message {
	width: 233px;
	height: 49px;
	background: #FEEEE4;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
	font-family: 'Poppins', sans-serif;
	position: absolute;
    z-index: 100;
    right: 67px;
    top: 8px;
}

.message p {
	font-size: 1.3rem;
	font-weight: normal;
	color: var(--color-orange);
}

.contenedor-svg {
	position: relative;
}

.contenedor-svg object {
	cursor: pointer;
}

.arrow-mapa {
	width: 0;
     height: 0;
     border-right: 12px solid transparent;
     border-top: 12px solid transparent;
     border-left: 12px solid transparent;
     border-bottom: 12px solid #fff;
     position: absolute;
     top: -21px;
    left: 50%;
}

.info-svg{
	position: absolute;
    display: block;
    left: 223px;
    top: 176px;
    width: 198px;
	height: 44px;
    background: white;
    z-index: 100;
}

.info-svg section {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.info-svg--image {
	width: 45px;
	height: 44px;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px 0px 0px 2px;
	display: flex;
    align-items: center;
    justify-content: center;
}

.info-svg--image img {
	width: 100%;
}

.info-svg__container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
	margin-left: 1rem;
	width: 70%;
	font-family: 'Poppins', sans-serif;
}

.info-svg__sesion {
	margin-bottom: 0;
	font-size: .7rem;
	font-weight: 400;
	color: var(--color-gray-5);
}

.info-svg__title {
	margin-bottom: 0;
	font-size: 1.1rem;
	font-weight: 600;
	color: var(--color-gray-3);
	line-height: 1rem;
}

.info-svg__local {
	margin-bottom: 0;
	font-size: .9rem;
	font-weight: 400;
	color: var(--color-orange);
}

.info-svg__link {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
}

.info-svg__link p {
	font-size: .9rem;
	font-weight: 400;
	font-family: 'Poppins', sans-serif;
	color: var(--color-gray-5);
	margin-bottom: 0;
}

.orientation__container {
	width: 45px;
    height: 40px;
    position: absolute;
    right: 106px;
    top: 46px;
}

.orientation__container img {
	width: 100%;
}