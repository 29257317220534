@media only screen and (min-width: 768px) {
  .aspRatio .advertising-cursor img {
    height: 100%;
    object-fit: fill;
    aspect-ratio: 1;
  }

  .aspRatio .advertising-cursor {
    height: 120px;
  }

  .blog__details {
    justify-content: flex-start;
  }

  .panels__catalogy {
    padding: 4rem 2rem 0;
  }

  .btn-soap__header--white {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
  }

  .container-main--img {
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
  }

  .p-absolute__post {
    height: 500px;
  }

  .container-main--img__first {
    object-fit: cover;
    display: block;
  }

  .container-main--img__second {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .blog-row {
    margin-bottom: 12rem;
    margin-top: 6rem;
  }

  .tag_container {
    border-radius: 7px;
  }

  .panel__title--align {
    margin-top: 42px !important;
    margin-bottom: 50px !important;
  }

  .banner-baic--home img {
    object-fit: contain !important;
  }
  .service__title {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .service__container--default {
    grid-template-columns: repeat(4, 1fr);
  }

  .banner__top--35 {
    height: 231px;
  }

  .grafic-home__electric {
    height: 162px;
  }

  .blog-item {
    width: 336px;
  }

  .number-cars {
    top: 3px;
    right: -5px;
  }

  .blog__content__title {
    font-size: 1.2rem;
  }

  .not-found {
    width: 50%;
  }

  .container-title {
    flex-direction: row;
  }

  .content__title_border {
    border-left: 1px solid #858584;
    padding-left: 1rem;
  }

  .tag__image--fast {
    width: 130px;
    padding-right: 2rem;
  }

  .interest_container {
    padding-top: 4rem;
  }

  .list-carroceria .swiper-slide {
    width: 60px !important;
  }

  .type_car_interest .search_type_details {
    justify-content: flex-start;
  }

  .tag_container--new {
    width: 323px;
  }

  .tag_container--new .tag_container__text {
    font-size: 3rem;
  }

  .slide-service .swiper-wrapper {
    height: 230px;
  }

  .container__interest__title--used {
    margin-top: 4rem;
  }

  .featured__title {
    font-size: 1.3rem;
  }

  .featured__price {
    font-size: 1.8rem;
  }

  .search_advanced__select .search-advanced_form__control,
  .search-advanced--label {
    font-size: 1.1rem;
  }

  .card__feature--new .featured__image img,
  .card__feature--default .featured__image img {
    object-fit: cover;
  }

  .information__title1--new {
    /**font-size: 5.5rem;**/
    display: none;
  }

  .information__title2--new {
    /**font-size: 5rem;
		margin-top: -22px;**/
    display: none;
  }

  .information__call--new {
    /**font-size: 2.3rem;
		width: 229px;**/
    display: none;
  }

  .featured-used {
    height: 330px;
  }

  .disclaime-price__text {
    font-size: 1rem;
  }

  .panel__title--new {
    font-size: ;
  }

  .featured > .swiper-pagination-bullets {
    bottom: 18px !important;
  }

  .panels__catalogy {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .panel__title--new {
    text-align: left;
    width: 100%;
    margin-right: 1rem;
    font-size: 2.5rem;
    align-items: center;
    margin-top: 0;
  }

  .catalogy__images {
    margin-top: 4.7rem;
  }

  .carousel__new .swiper-pagination-bullet {
    background: var(--color-white);
  }

  .carousel__new .swiper-pagination-bullet-active {
    background: var(--color-orange);
  }

  .service__container--used {
    margin-bottom: 1rem;
  }

  .blog__map {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 80%;
    margin-top: 10rem;
  }

  .card-ubication {
    width: 300px;
    position: absolute;
    top: 50%;
    right: 22%;
    transform: translate(50%, -50%);
  }

  .btn__text--align {
    font-size: 1.3rem;
  }

  .panel__title--map {
    width: 100% !important;
    margin-top: 2rem;
  }

  .service--used {
    font-size: 2rem;
  }

  .imagen-map {
    height: 300px;
    margin-bottom: 10rem;
  }

  .imagen-map img {
    transform: rotate(289deg);
    margin-top: -15rem;
    width: auto;
  }

  .blog__imagen {
    height: 130px;
    width: 100%;
  }

  .blog__button {
    text-align: center;
  }

  .blog__button button {
    width: 564px;
    height: 65px;
  }

  .blog__title {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .title-col,
  .title-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  .featured--default > .swiper-pagination-bullets {
    bottom: -4px !important;
  }

  /**.service__details {
		width: 200px;
    	height: 180px;
	}**/

  .container__interest--other {
    background: var(--color-soft-gray);
    padding-bottom: 1rem;
  }

  .service__container--other {
    margin-bottom: -2rem;
  }

  .featured--default {
    height: 334px;
  }

  .featured--default > .swiper-pagination-bullets {
    bottom: 0px !important;
  }

  .container__interest {
    margin-top: -2rem;
  }

  .container__interest__title--default {
    margin-top: 7rem;
    font-size: 3rem;
    margin-bottom: 5rem;
  }

  .marque-link__title {
    font-size: 1.4rem;
  }

  .container__interest__title--other {
    font-size: 3rem;
    margin-top: 5rem;
  }

  .catalogy__marca--default {
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: left;
    width: 100%;
    margin-right: 7rem;
  }

  .key_car--top {
    margin-bottom: 0;
    margin-top: 29px;
  }
  .key_car--top {
    height: 250px;
  }

  .key__card {
    flex-direction: row;
    padding: 1rem 5rem;
    align-items: center;
  }

  .key__text {
    margin-top: 0;
  }

  .key__image {
    width: 30%;
    text-align: center;
  }

  .key__image img {
    width: 100%;
  }

  .button-vender {
    width: 40%;
  }

  .key_card__title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 2rem;
  }

  .key_card__subtitle {
    font-size: 1.1rem;
  }

  .tag__container .card__tag {
    width: 48%;
    min-height: 100px;
    padding-top: 0;
  }

  .tag__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .panel__title--align {
    width: 60%;
  }

  .bg-tag {
    background: var(--color-orange);
    margin-top: -2rem;
  }

  .bg-quote {
    background: linear-gradient(#f0f0f0 64%, #f47422 55%);
    display: block;
  }

  .bg_quote {
    height: 250px;
    margin-top: 0;
  }

  .discover__title {
    font-size: 3rem;
  }

  .card_tag__items {
    margin-top: 0;
    margin-bottom: 0;
  }

  .quote-mobile {
    display: none;
  }

  .panel--bottom {
    margin-bottom: 5rem;
  }

  .quote__container {
    flex-direction: row;
    padding: 1rem 5rem;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .quote__text {
    width: 50%;
  }

  .quote__title {
    font-size: 3rem;
    line-height: 3rem;
  }

  .quote__button {
    width: 30%;
    position: relative;
  }

  .search__header {
    flex-direction: row;
    margin-bottom: 1rem;
  }

  .card__title {
    order: 1;
    width: 45%;
  }

  .search {
    order: 2;
    width: 55%;
  }

  .card__title--normal {
    font-size: 1.8rem;
    padding-right: 1rem;
    line-height: 2.5rem;
    width: 60%;
  }

  .search__main {
    flex-direction: row;
    justify-content: space-between;
  }

  .search__main--brand-model {
    width: 32%;
  }

  .search__main--type,
  .search__main--bodywork {
    padding-right: 2rem;
    border-right: 1px solid rgb(33 37 41/20%);
    width: 32%;
    height: 270px;
  }

  .search_type_details {
    flex-direction: row;
    justify-content: space-between;
  }

  .carousel-next {
    display: none;
  }

  .search-advanced__container {
    width: 241px;
  }

  .search-advanced__container .search-advanced--title {
    font-size: 1.5rem;
  }

  .search__service {
    display: block;
    width: 41%;
  }

  .search__service--text {
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    color: var(--color-gray-1);
    margin-bottom: 0;
  }

  .search__link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 2.5rem;
    margin-top: 0;
  }

  .search__service--link {
    font-family: "Poppins", sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--color-orange);
    margin-bottom: 0;
    text-decoration: underline;
  }

  .search__service--link:hover {
    color: var(--color-orange);
  }

  .btn__search__link {
    width: 35%;
    height: 50px;
  }

  .card-search {
    padding: 1.25rem 2rem;
  }

  .blog__mapa--default {
    width: 100%;
    margin-top: 0;
  }

  .bg-main--new {
    background: url("https://movicenter-web.s3.us-east-2.amazonaws.com/index/desktop/cumple_tu_viaje.png")
      no-repeat;
    background-size: 100%;
    height: 139px;
  }

  .bg-main--used {
    background: url("https://movicenter-web.s3.us-east-2.amazonaws.com/index/desktop/banner_used_site.png")
      no-repeat;
    background-size: 100%;
    /**height: 139px;**/
  }

  .main__title--information,
  .main__call--information,
  .main__title--information {
    display: none;
  }

  .type-interest {
    width: 110px;
  }

  .type-interest__image {
    width: 70px;
    height: 60px;
  }

  .type-interest p {
    font-size: 1.3rem;
  }

  .my-interest__small {
    font-size: 1.5rem;
  }

  .search_type_price {
    width: 60%;
  }

  .search_advanced__select,
  .search_type_combustible {
    padding-right: 1rem;
    padding-left: 1rem;
    border-right: 1px solid rgb(33 37 41/20%);
    width: 34%;
    height: auto;
    margin-bottom: 0;
  }

  .search_type_combustible {
    border-right: none;
  }

  .advanced__details {
    width: 100%;
  }

  .search__main_container {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
  }

  .search_type_transmission {
    margin-top: 3rem;
    margin-bottom: 4rem;
    display: block;
  }

  .search_mobile_transmission {
    display: none;
  }

  .search-medium__square {
    width: 150px;
  }

  .search-advanced_form__control,
  .search-advanced--label {
    font-size: 1.1rem;
  }

  .search-normal__container {
    margin-top: 1rem;
  }

  .search-normal__square {
    width: 110px;
  }

  .square__text {
    font-size: 1.1rem;
  }

  .form-select__subtitle {
    font-size: 1.2rem;
  }

  .type-interest__radio {
    height: 60px;
  }

  .featured-used .swiper-slide .card__feature {
    width: 215px;
    height: 280px;
  }

  .container__interest__title--new.service--used {
    margin-top: 5rem;
  }

  .featured--default .swiper-slide {
    width: 215px !important;
    height: 280px !important;
  }

  .featured--default .swiper-slide .card__feature {
    width: 215px;
    height: 280px;
  }

  .type_carroceria_list {
    height: auto;
  }

  .search__link {
    border-top: 1px solid rgb(33 37 41/20%);
    padding-top: 2rem;
    margin-top: 1rem;
  }

  .col_featured {
    height: 330px;
  }

  .border-20 {
    border-radius: 20px;
  }

  .slide-service .swiper-slide .service__details--used {
    width: 200px !important;
    height: 200px !important;
  }

  .service__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .search__car {
    margin-bottom: 0;
  }

  .container__interest__title--used {
    margin-top: 3rem;
  }

  .catalogy_form {
    width: 300px;
  }

  .marcas__images--used {
    width: 120px;
    margin-bottom: 30px;
    height: 50px;
  }

  .featured__image {
    height: 150px;
  }

  .featured__title {
    height: 54px;
    font-size: 1.8rem;
  }

  .marca-list {
    min-height: 65vh;
  }

  .marca-list .card__body {
    flex: 0;
  }

  .btn-list-brand {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .error_not_search {
    text-align: center;
  }

  .error_not_search img {
    width: 60%;
  }

  .marcas__images {
    width: 100px;
    margin-bottom: 30px;
  }

  .catoly-title .panel__title {
    font-size: 2.5rem;
    text-align: left;
    width: 350px;
  }

  .catoly-title {
    align-items: flex-start;
    margin-bottom: 0;
  }

  .carousel__new .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }

  .modal_initial-home,
  .modal_initial-result {
    width: 90%;
    max-width: none !important;
  }

  .modal__header--title {
    font-size: 4rem;
  }

  .benefits-car__title span {
    font-size: 2.5rem;
  }

  .benefits-car__title {
    font-size: 1.8rem;
    line-height: 2rem;
    text-align: left;
  }

  .benefits-car__choose {
    padding-left: 13rem;
    padding-top: 0;
    margin-bottom: 0;
    margin-top: 0;
  }

  .benefits-car__visit {
    padding-right: 0;
    padding-left: 13.5rem;
  }

  .benefits-car__left::after {
    right: 142px;
    transform: rotate(-90deg);
    top: -50px;
    height: 120px;
    overflow: hidden;
  }

  .benefits-car__planned::after {
    right: 46px;
    top: -24px;
    transform: rotate(-90deg);
    height: 40px;
    overflow: hidden;
  }

  .benefits-car__planned {
    padding-left: 0;
  }

  .benefits-car {
    padding: 0;
    margin-top: 30px;
  }

  .benefits-car__image {
    margin-top: -17rem;
    text-align: center;
  }

  .benefits-car__image img {
    width: 60%;
    height: 100%;
    object-fit: scale-down;
  }

  .modal__footer p {
    font-size: 2.2rem;
  }

  .modal__footer img {
    width: 200px;
  }

  .benefits-car__button button {
    width: 40%;
  }

  .modal__header img {
    width: 30px;
  }

  .benefits-car__visit::after {
    left: -12px;
    height: 20px;
    overflow: hidden;
    bottom: -36px;
    transform: rotate(90deg);
  }

  .group_trans_fuel {
    flex-direction: row;
    margin-top: 3rem;
    margin-bottom: 0;
  }

  .group_trans_fuel .search_type_details {
    flex-direction: row;
    justify-content: flex-start;
  }

  .group_trans_fuel .search_type_details .search-medium__square {
    width: 100px;
  }

  .group_trans_fuel .search__type--title {
    margin-bottom: 0;
  }

  .group_trans_fuel .search_type_transmission--m {
    width: 40%;
  }

  .col_featured--default .container {
    padding-right: 15px;
  }

  .panel__title--align {
    line-height: 4rem;
    font-size: 3.5rem;
    width: 74%;
  }

  .modal-home {
    max-width: 700px;
  }

  .banner__financing {
    margin-bottom: 6rem;
    margin-top: 0;
  }

  .header-top .btn-soap__header {
    font-size: 1rem;
    padding: 1rem 0;
    width: 91px;
    color: var(--color-white);
  }

  .d-concessionaire__description {
    font-size: 1.6rem;
  }

  .d-concessionaire__category {
    font-size: 1.6rem;
  }

  .map-link-in {
    padding-bottom: 7rem;
    /**background: linear-gradient(#fff 70%, #F0F0F0 30%);**/
  }
}

@media only screen and (min-width: 992px) {
  .container-main--img,
  .p-absolute__post {
    height: 600px;
  }

  .filter-selected-container {
    align-items: center;
    flex-direction: row;
  }

  .blog__imagen {
    height: 170px;
    width: 100%;
  }

  .blog-item {
    width: 300px;
  }

  .key_card__title {
    font-size: 2.5rem;
  }

  .card-search {
    padding: 1.25rem 3.5rem;
  }

  .featured-used .swiper-slide .card__feature {
    width: 220px;
    height: 283px;
  }

  .featured--default .swiper-slide {
    width: 220px !important;
    height: 280px !important;
  }

  .featured--default .swiper-slide .card__feature {
    width: 220px;
    height: 280px;
  }

  .featured__title {
    font-size: 1.8rem;
  }

  .featured__price {
    font-size: 2rem;
  }

  .panel__title--new {
    font-size: 3.5rem;
    line-height: 4rem;
    width: 500px;
  }

  .catalogy_form {
    width: 350px;
  }

  .slide-service .swiper-slide,
  .service__details--used {
    width: 205px !important;
    height: 200px !important;
  }

  .tag__image--width {
    width: 130px;
  }

  .service__details--used .service__image {
    width: 100%;
    height: 100%;
  }

  .disclaime-price {
    text-align: right;
  }

  .col_featured {
    height: 351px;
  }

  .featured > .swiper-pagination-bullets {
    bottom: 0px !important;
  }

  .service--used {
    font-size: 3.5rem;
    margin-top: 2rem;
    line-height: 4rem;
  }

  .container__interest__title--default {
    font-size: 3.5rem;
  }

  .catalogy__marca--default {
    font-size: 3.5rem;
    line-height: 4.5rem;
    text-align: left;
    width: 500px;
    margin-right: 7rem;
  }

  .quote__title {
    font-size: 4rem;
    line-height: 4rem;
  }

  .tag__container .card__tag {
    width: 48%;
    min-height: 98px;
  }

  .search__header {
    flex-direction: row;
  }

  .card__title {
    order: 1;
    width: 60%;
  }

  .search {
    order: 2;
    width: 40%;
  }

  .card__title--normal {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .search_type_details {
    flex-direction: row;
  }

  .banner__top--35 {
    height: 365px;
  }

  .bg-main--new,
  .bg-main--used {
    height: 180px;
  }

  .form-select__subtitle {
    font-size: 1.5rem;
  }

  .type-interest {
    width: 95px;
  }

  .my-interest__small {
    font-size: 1.8rem;
  }

  .search-medium__square {
    width: 90px;
  }

  .search_type_transmission .search-medium__square {
    width: 110px;
  }

  .search__service--text {
    font-size: 1.8rem;
  }

  .search__service--link {
    font-size: 2rem;
  }

  .btn__search__link {
    height: 50px;
  }

  .search__link {
    margin-top: 0;
  }

  .catoly-title .panel__title {
    font-size: 3rem;
    line-height: 3.5rem;
    width: 450px;
  }

  .benefits-car {
    padding: 0 20px;
  }

  .benefits-car__choose {
    padding-left: 20rem;
  }

  .benefits-car__choose::after {
    left: 154px;
    top: -43px;
    height: 105px;
  }

  .benefits-car__visit {
    padding-left: 19.5rem;
  }

  .benefits-car__visit::after {
    left: 8px;
    height: 53px;
    bottom: -80px;
  }

  .benefits-car__search::after {
    height: 72px;
  }

  .container__interest__title--other {
    font-size: 3.5rem;
  }

  .search_type_price {
    width: 40%;
  }

  .main--top-new {
    margin-top: -21rem;
  }

  .card_panel--used,
  .card_panel--new {
    margin-top: 5rem;
  }

  .group_trans_fuel .search_type_transmission--m {
    width: 30%;
  }

  .panel__title--align {
    width: 54%;
  }

  .slider-blog .swiper-slide {
    height: auto;
  }

  .modal-home {
    max-width: 800px;
  }

  .header-top .btn-soap__header {
    font-size: 1.2rem;
    padding: 0.5rem;
    width: 120px;
  }

  .d-concessionaire__description {
    font-size: 1.8rem;
  }

  .search__service {
    width: 34%;
  }

  .search-normal--vehicle {
    width: 46%;
  }

  .square__text {
    font-size: 1.4rem;
  }

  .slide-service .swiper-button-next,
  .slide-service .swiper-button-prev {
    top: 43% !important;
  }

  .container__interest__title {
    font-size: 3.5rem;
    margin-top: 70px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 1200px) {
  .filter-price .container-input {
    flex-direction: row;
  }

  .filter-price .container-input .container-input-group {
    width: 47%;
  }

  .blog-item {
    width: 350px;
  }

  .blog__imagen {
    height: 200px;
  }

  .panels__catalogy {
    padding: 4rem 5rem 0;
  }

  .blog__details {
    flex-direction: column;
    width: 90%;
  }

  .key_card__title {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .bg-main--new,
  .bg-main--used {
    height: 290px;
  }

  .type-interest {
    width: 115px;
  }

  .type-interest__image {
    width: 95px;
    height: 80px;
  }

  .search-medium__square {
    width: 100px;
  }

  .type-interest__radio {
    height: 80px;
  }

  .featured-used .swiper-slide .card__feature,
  .featured--default .swiper-slide .card__feature {
    width: 250px;
    height: 280px;
  }

  .featured--default .swiper-slide {
    width: 250px !important;
    height: 280px !important;
  }

  .catalogy_form {
    width: 400px;
  }

  .modal_initial-home,
  .modal_initial-result {
    width: 90%;
    max-width: 1150px !important;
  }

  .benefits-car {
    padding: 0 50px;
  }

  .benefits-car__title {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  .benefits-car__title span {
    font-size: 3.2rem;
  }

  .benefits-car__search::after {
    height: 81px;
    right: 120px;
  }

  .benefits-car__left::after {
    right: 198px;
    top: -67px;
    height: 155px;
  }

  .benefits-car__planned::after {
    right: 46px;
    top: -87px;
    height: 70px;
  }

  .benefits-car__image img {
    width: 50%;
  }

  .benefits-car__visit::after {
    left: 13px;
    height: 70px;
    bottom: -147px;
  }

  .modal__header--title {
    font-size: 5rem;
  }

  .modal__footer p {
    font-size: 2.5rem;
  }

  .benefits-car__planned::after {
    right: 62px;
    top: -74px;
    height: 85px;
  }

  .benefits-car__choose {
    padding-left: 24rem;
  }

  .benefits-car__choose::after {
    left: 173px;
    top: -62px;
    height: 144px;
  }

  .benefits-car__visit {
    padding-left: 26rem;
  }

  .search_type_transmission .search-medium__square {
    width: 130px;
  }

  .search-type-car .search-normal__square {
    width: 140px;
  }

  .search-type-car {
    top: 6px;
    left: 30px;
  }

  .panel__title--align {
    width: 45%;
  }

  .list-carroceria .swiper-slide {
    width: 70px !important;
  }

  .modal-home {
    max-width: 1100px;
  }

  .header-top .btn-soap__header {
    font-size: 1.5rem;
    padding: 0.5rem 2rem;
    width: 100%;
  }
}
