.mtb-page {
	margin-top: 12rem;
	margin-bottom: 12rem;
}

.compara-main {
    background: var(--color-white);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    width: 280px;
    padding: 1rem 1rem 3rem 1rem;
    margin: 8rem auto;
}

.compara-main__logo {
	display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.bg-compara {
	background: rgb(133 133 132 / 40%);
	height: 100vh;
}

@media only screen and (min-width: 350px) {
	.compara-main {
		width: 300px;
	    padding: 1rem 2rem 3rem 2rem;
	}
}

@media only screen and (min-width: 370px) {
	.compara-main {
		width: 320px;
	    padding: 1rem 2rem 3rem 2rem;
	}
}

@media only screen and (min-width: 768px) {
	.compara-main {
		width: 420px;
	    padding: 1rem 2rem 3rem 2rem;
	    margin: 8rem auto;
	}
}