:root {
  --color-white: #ffffff;
  --color-orange: #f47422;
  --color-soft-orange: #ffe1ce;
  --color-soft-gray: #f0f0f0;
  --color-gray-1: #212529;
  --color-gray-2: #1e2125;
  --color-ligth-gray: #00000012;
  --color-gray-3: #7f8082;
  --color-gray-4: #00000029;
  --color-gray-5: #858584;
  --color-warn-orange: #d83009;
  --color-black: #000000;
  --color-gray-6: #d1d1d1;
  --color-gray-7: #afb7ba;
  --color-gray-8: #0000001d;
  --color-gray-9: #ededed;
  --color-gray-10: #acacac;
  --color-gray-11: #dfdfdf;
  --color-dark-gray: #858584;
  --background-header: #f47422;
  --color-primary: #e24322;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  border: none;
  outline: none;
}

html {
  font-size: 62.5%;
  font-family: "Poppins", sans-serif;
}

body {
  background: var(--color-white);
  font-family: "Poppins", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f47422;
  border-radius: 5px;
}

textarea {
  resize: none;
}

a:hover {
  text-decoration: none;
}

.btn-lg {
  font-size: 2rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px 5px;
}

.header__menu {
  cursor: pointer;
}

.line__menu {
  border: 0;
  border-top: 1px solid white;
  margin: 0px -15px;
}

.header__template--default {
  height: 100%;
  position: relative;
  padding-top: 9rem;
  padding-bottom: 2rem;
}

.row-slider__home {
  z-index: 1000;
  position: relative;
}

.header-home {
  width: 100%;
}

.slider-header-home {
  border-radius: 20px;
}

.header-home__img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.header__template--used {
  background-image: url("https://movicenter-web.s3.us-east-2.amazonaws.com/index/mobile/home_used.png");
  background-repeat: no-repeat;
  object-fit: none;
  background-size: 100%;
  height: 300px;
  position: relative;
}

.header__template--new {
  background-image: url("https://movicenter-web.s3.us-east-2.amazonaws.com/index/mobile/home_new.png");
  background-repeat: no-repeat;
  object-fit: none;
  background-size: 100%;
  height: 300px;
  position: relative;
}

.header__white {
  background: var(--background-header);
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
}

.row-top {
  padding-top: 7rem;
}

.header__logo {
  width: 180px;
  text-align: center;
  height: 18px;
  cursor: pointer;
}

.header__logo img {
  width: 100%;
}

.main--top {
  margin-top: -21rem;
}

.main__title--default {
  font-size: 2.5rem;
  color: var(--color-white);
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0;
  line-height: 2.5rem;
  width: 80%;
  margin-bottom: 0.5rem;
}

.main__title--used {
  font-size: 2.9rem;
  color: var(--color-white);
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: -2px;
}

.main__title--bottom {
  margin-bottom: -17px;
}

.main__call_action {
  background: var(--color-orange);
  padding: 0px 5px;
  color: var(--color-white);
  border-radius: 5px;
  margin-bottom: 0;
  display: initial;
}

.main__call_action--default {
  font-size: 1.2rem;
  font-weight: 600;
}

.main__call_action--used {
  font-size: 1.3rem;
  font-weight: 500;
}

/**Title**/
.tag_container {
  height: 37px;
  background: var(--color-orange);
  border-radius: 0px 7px 7px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 3.2rem;
}

.tag_container__text {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  color: var(--color-white);
  margin-bottom: 0;
  text-align: center;
}

.tag_container--default {
  width: 173px;
  margin-top: 3rem;
  font-size: 2.4rem;
}

.tag_container--used .tag_container__text,
.tag_container--used_other .tag_container__text {
  font-size: 2.4rem;
}

.tag_container--used {
  width: 262px;
  margin-top: 4.5rem;
  border-radius: 0px 30px 30px 0px;
}

.tag_container--used_other {
  width: 184px;
  border-radius: 0px 30px 30px 0px;
  margin-bottom: 1.5rem;
}

.tag_container--new {
  width: 263px;
  margin-top: 61px;
  border-radius: 0px 30px 30px 0px;
}

.title-col,
.title-container {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

/**Destacados**/
.col_featured {
  padding-right: 0;
  height: 325px;
}

.col_featured--default {
  background: linear-gradient(#fff 60%, #f47422 30%);
}

.col_featured--used-featured {
  background: linear-gradient(#fff 60%, #f0f0f0 30%);
  margin-top: 0.8rem;
}

.col-gray-mapa {
  /**background: linear-gradient(#F0F0F0 20%, #fff 10%);**/
}

.card__feature--used {
  border-radius: 0;
}

.card__feature--new {
  border-radius: 0;
}

.featured__image {
  width: 100%;
  height: 112px;
  text-align: center;
}

.featured__container {
  cursor: pointer;
}

.featured__image img {
  width: 100%;
  margin-top: -3px;
  height: 100%;
  object-fit: cover;
}

.featured__item__container {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.featured__type {
  font-size: 1rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-5);
  margin-bottom: 0;
  margin-top: 1rem;
}

.featured__title {
  font-size: 1.1rem;
  font-weight: normal;
  color: var(--color-orange);
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  height: 40px;
}

.featured__price {
  color: var(--color-gray-1);
  font-weight: 600;
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
}

.featured__price span {
  font-size: 0.8rem;
}

.card__feature .card__body {
  padding: 0 0 0 0;
  justify-content: flex-start;
}

.featured--default .swiper-slide .card__feature {
  width: 170px;
  height: 243px;
  margin-top: 0;
}

.featured--default .swiper-slide {
  width: 170px !important;
  height: 243px !important;
}

.col_featured--default .container {
  padding-right: 0;
}

.featured--default .swiper-pagination-bullet {
  background: var(--color-white);
  opacity: 1;
}

.featured--default .swiper-pagination-bullet-active {
  background: var(--color-gray-5);
}

.featured--default .swiper-pagination .swiper-pagination-bullets {
  bottom: 47px;
}

.featured-used .swiper-pagination-bullet {
  background: var(--color-white);
  opacity: 1;
}

.featured-used .swiper-pagination-bullet-active {
  background: var(--color-orange);
}

/*****Panel *******/
.card__container {
  width: 100%;
  margin: 0 auto;
  border-radius: 0.7rem;
  box-shadow: 0px 3px 6px var(--color-gray-8);
}

.card__container--top {
  margin-top: -6rem;
  z-index: 100;
}

.card__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  padding: 1.25rem 2rem;
}

.card_panel {
  margin-top: -80px;
}

.card_panel--used {
  margin-top: -125px;
}

.card_panel--new {
  margin-top: 4rem;
}

.card__title {
  color: var(--color-black);
  font-size: 1.3rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  height: 70px;
  display: flex;
  align-items: center;
}

.card__subtitle {
  color: var(--color-black);
  font-size: 1.3rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
}

.service--used {
  font-size: 2rem;
}

.container__interest--carroceria {
  margin-top: 5rem;
  margin-bottom: 1.5rem;
}

.featured {
  height: 295px;
}

/**Filtro normal**/

.search__header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search {
  background: var(--color-soft-gray);
  width: 100%;
  height: 39px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.search-normal-advanced {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.search__main {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.search__main--type,
.search__main--bodywork {
  border-right: none;
  width: 100%;
  height: auto;
}

.normal-advanced--input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: 85%;
  width: 100%;
  cursor: pointer;
}

.normal-advanced--input:checked {
  background: var(--color-white);
  box-shadow: 0px 3px 6px #00000021;
  border-radius: 30px;
}

.normal-advanced--text {
  position: absolute;
  font-size: 1rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-5);
  opacity: 0.56;
  padding-top: 0.5rem;
  cursor: pointer;
}

.normal-advanced--input:checked + .normal-advanced--text {
  color: var(--color-orange);
  opacity: 1;
  font-weight: 600;
}

.search__type__range {
  width: 100%;
}

.search__type--title {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: var(--color-black);
}

.search_type_details {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  flex-direction: row;
}

.search__type {
  width: 100%;
  margin-bottom: 2.5rem;
}

.search_type_transmission {
  display: none;
}

.search_type_transmission--m .search-medium__square {
  width: 100px;
}

.search_mobile_transmission {
  display: block;
}

.search-normal__container {
  position: relative;
}

.search-normal__square {
  width: 100px;
  height: 33px;
  background: var(--color-white);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
}

.square__text {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
  color: var(--color-gray-1);
  margin-bottom: 0;
  position: absolute;
  top: 8px;
  left: 16px;
  cursor: pointer;
}

.search-normal__square:checked,
.search-medium__square:checked {
  background: var(--color-orange);
  box-shadow: 0px 3px 6px var(--color-gray-4);
}

.search-normal__square:checked + .square__text,
.search-medium__square:checked + .medium__square__text {
  color: var(--color-white);
}

.w-90 {
  width: 90%;
}

.search-foooter {
  height: 88px;
  display: flex;
  align-items: center;
}

.btn-view--modelo {
  width: 91px;
  height: 23px;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.btn-view {
  position: relative;
}

.btn-view span {
  background: url("../../images/icons/down-arrow-modelo.svg");
  background-repeat: no-repeat;
  width: 22px;
  height: 15px;
  position: absolute;
  top: 7px;
  right: -9px;
}

.items {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.item-search {
  height: 26px;
  background: var(--color-soft-gray);
  border-radius: 5px;
  font-size: 1.4rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-1);
  margin-bottom: 0;
  padding-left: 5px;
  position: relative;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.item-search .item-text {
  min-width: 62px;
  max-height: 75px;
}

.form-select__imagen {
  display: inline-block;
  width: 15px;
  height: 18px;
  background: url("../../images/icons/down-arrow-search.svg") no-repeat;
  vertical-align: middle;
  margin-top: -0.5rem;
  transform: rotate(90deg);
}

.form-select__imagenY {
  transform: rotate(90deg);
}

.form-rotate__imagen {
  transform: rotate(270deg);
}

.search-form-select.search-selected .form-select__imagen {
  background: url("../../images/icons/down-arrow-orange.svg") no-repeat;
}

.search-form-select:hover .form-select__imagen {
  background: url("../../images/icons/down-arrow-orange.svg") no-repeat;
}

.search-form-select:hover .form-select__subtitle,
.search-form-select:hover .form-select__title,
.search-form-selected {
  color: var(--color-orange) !important;
}

.search-advanced__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.6rem;
  cursor: pointer;
}

.search-advanced__imagen {
  background: var(--color-orange);
  width: 23px;
  border-radius: 10px;
  height: 23px;
  margin-right: 10px;
}

.search-advanced--down {
  display: inline-block;
  width: 15px;
  height: 18px;
  background: url("../../images/icons/down-arrow-search.svg") no-repeat;
  vertical-align: middle;
  transform: rotate(273deg);
  margin-right: 10px;
  cursor: pointer;
}

.search-advanced--top {
  transform: rotate(90deg);
}

.search-advanced--title {
  width: 100%;
}

.search-advanced__details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  /**height: 70px;**/
}

.search-advanced_form {
  position: relative;
  width: 48%;
  height: 100%;
}

.search-normal--vehicle {
  width: 48%;
}

.search-normal--vehicle .search-normal__square {
  width: 100%;
}

.search_type_details--vehicle {
  justify-content: space-between;
}

.search-normal--vehicle .square__text {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: block;
  text-align: center;
}

.search-select {
  margin-bottom: 3.5rem;
}

.search-type--bottom {
  margin-bottom: 2.5rem;
}

.search-advanced_form__control {
  font-size: 1.1rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #858584;
  height: 40px;
  background: var(--color-white);
  box-shadow: 0px 3px 6px var(--color-ligth-gray);
  border: 0.3px solid rgb(133 133 132 / 53%);
  border-radius: 5px;
  cursor: pointer;
}

.search-advanced--label {
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: var(--color-gray-1);
  cursor: pointer;
}

.search-medium__square {
  width: 70px;
  height: 28px;
  background: var(--color-soft-gray);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}

.medium__square__text {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
  color: var(--color-gray-1);
  margin-bottom: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.range__text {
  font-size: 1.4rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #212529;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.search_carroceria {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search_carroceria__details {
  /**display: flex!important;
	align-items: center;
	flex-direction: column;
	padding:0 10px;
	cursor: pointer;
	width: 75px;**/
}

.type_carroceria_list {
  height: 55px;
}

.type_carroceria_list .swiper-slide {
  width: 75px !important;
  margin-right: 0.5rem !important;
  margin-bottom: 0;
}

.carroceria__checkbox {
  width: 100%;
  height: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.carroceria__text {
  font-size: 1.1rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-1);
  cursor: pointer;
  text-align: center;
  height: 23px;
  margin-top: 1.2rem;
}

.form-select__title {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-5);
  text-decoration: none;
}

.search__service {
  display: none;
}

.search__link {
  border-top: none;
  padding-top: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.btn__search__link {
  width: 100%;
  background: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
}

.btn-red-primary {
  background: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
}

.card-search {
  padding: 1.25rem 2rem;
}

.form-selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carousel-next {
  width: 12px;
  height: 38px;
  padding: 0 0.5rem;
  background: rgb(255 255 255 / 29%);
  box-shadow: -1px 3px 6px #00000045;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-select__details {
  display: flex;
  /**justify-content: space-between;**/
  align-items: center;
  height: 40px;
}

.form-select__subtitle {
  font-size: 1.2rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-5);
  opacity: 0.56;
  margin-bottom: 0;
  width: 100%;
}

/***key movicenter**/
.key_fast {
  position: absolute;
  z-index: 100;
  right: 24px;
  top: 56px;
}

.key_car--top {
  margin-top: 38px;
  min-height: 29rem;
  z-index: 10;
  margin-bottom: -42px;
}

.key__card {
  justify-content: flex-start;
}

.key__text {
  margin-top: 28px;
}

.key__image {
  width: 100%;
  text-align: right;
}

.key_card__title {
  text-align: left;
  font-size: 2.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: var(--color-gray-1);
  line-height: 2.5rem;
}

.key_card__subtitle {
  text-align: left;
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: var(--color-gray-7);
}

.button-vender {
  width: 100%;
}

.quote__container {
  width: 100%;
  position: relative;
  margin-top: 77px;
  margin-bottom: 52px;
  display: flex;
  flex-direction: column;
}

.bg-quote {
  display: none;
}

.quote__text,
.quote__button {
  position: relative;
}

.quote__title {
  font-size: 2.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: var(--color-white);
  line-height: 3rem;
}

.quote__subtitle {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: var(--color-white);
  line-height: 2.5rem;
}

.bg_quote {
  background-image: url("../../images/background-quote.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.card_tag__items {
  margin-top: -7rem;
  margin-bottom: -2rem;
}

.bg_quote:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.btn-quote--margin {
  margin-top: 29px;
  margin-bottom: 72px;
}

/**Descubre**/
.targ--top {
  /**margin-top: -44px;**/
}

.card__tag {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding: 0.5rem 1rem;
  justify-content: flex-start;
  border-radius: 10px;
}

.tag__image {
  width: 75%;
  text-align: center;
}

.tag__image img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.tag__title {
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: var(--color-gray-1);
  margin-bottom: 0;
}

.awto__title {
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: var(--color-gray-1);
  margin-bottom: 0;
}

.tag__subtitle {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: var(--color-gray-5);
}

.tag__image--awto {
  width: 35%;
}

.awto__subtitle {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: var(--color-gray-5);
}

.awto__subtitle_2 {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: var(--color-orange);
}

.time--top {
  /**margin-top: -72px;**/
}

.time--background {
  background: var(--color-soft-gray);
}

.tag__image--width {
  width: 171px;
  padding-right: 0;
}

.tag__image--fast {
  width: 56%;
  padding-right: 1rem;
}

.discover__container {
  position: relative;
}

.discover__title {
  margin-top: 66px;
  font-size: 1.9rem;
  font-weight: 600;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-1);
}

.card_tag__container {
  margin-top: -40px;
  margin-bottom: -37px;
}

.blog__map {
  width: 100%;
}

.map-frame {
  width: 100%;
  height: 350px;
  border: 0;
}

.title_marca {
  font-size: 1.5rem;
  font-weight: 600;
}

.carousel-cars {
  overflow: hidden;
}

.slick-slide {
  width: 80px !important;
}

.text-transparent {
  background: transparent;
}

/***Información interes*****/
.interest_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: -73px;
  padding-top: 0;
}

.container__interest__title {
  font-size: 1.7rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  line-height: 2.5rem;
  color: var(--color-gray-1);
  margin-bottom: 29px;
  padding-right: 23px;
  padding-left: 23px;
}

.container__interest__title--other {
  font-size: 2rem;
  margin-top: 10rem;
}

.container__interest__title--width {
  width: 100%;
}

.container__interest__title--service {
  color: var(--color-gray-3);
  margin-top: 2rem;
  margin-bottom: 4.5rem;
}

.title--service-other {
  color: var(--color-gray-3);
  margin-top: 9rem;
  margin-bottom: 4rem;
}

.container__interest__title--default {
  color: var(--color-gray-1);
  /**margin-top: -17px;**/
}

.container__interest__title--used {
  color: var(--color-gray-5);
}

.container__interest__title--new {
  color: var(--color-gray-5);
  margin-top: 4rem;
}

.container__interest--bottom {
  padding-bottom: 115px;
}

/******services*****/
.service__container--default {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  grid-gap: 0 2rem;
}

.service__new_result--default {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  grid-gap: 2rem;
}

.service__container--used {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.service__details {
  /**width: 120px;
	height: 120px;**/
  box-shadow: 0px 3px 6px var(--color-gray-4);
  position: relative;
  margin-bottom: 30px;
  border-radius: 7px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.service__details-notCursor {
  cursor: default;
}

.result-item--other {
  height: 100%;
}

.result-item__other--img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.service__details--other {
  background: white;
  padding: 0 10px;
  border-radius: 20px;
  background: var(--color-white);
  border-radius: 20px;
  height: 100%;
  margin-bottom: 30px;
}

.service__details:hover .opacity-services {
  background-color: rgb(240 240 240 / 54%);
}

.service__details:hover .opacity-services {
  background-color: rgb(240 240 240 / 54%);
}

.service__details:hover .service__title {
  color: #212529;
}

.opacity-services {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  height: 100%;
  width: 100%;
}

.service__details img {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}

.service__title {
  color: var(--color-white);
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0px !important;
  font-family: "Poppins", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  text-transform: uppercase;
  line-height: 1.8rem;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 130px;
}

.service__container--position {
  position: absolute;
  width: 92%;
  top: 10rem;
}

.bg-orange-gray {
  background: linear-gradient(#f47422 63%, #f0f0f0 30%);
}

.service__details--used {
  width: 100%;
  height: 140px;
  position: relative;
  margin-bottom: 30px;
  border-radius: 7px;
  cursor: pointer;
}

.slide-service .swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 30px;
}

.service__details--used::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 7px;
}

.service__image {
  width: 100%;
  height: 100%;
}

/*****Catalogo de marca****/

.panels_container {
  position: relative;
}

.panels__catalogy {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 4rem 0 0;
}

.row-top-brand {
  margin-top: 1rem;
}

.panel__title {
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: var(--color-gray-3);
  /** margin-top: 34px;**/
  line-height: 2.5rem;
  text-align: center;
  /** margin-bottom: 4.5rem;**/
  order: 1;
}

.panel__title--align {
  text-align: left;
  padding-left: 10px;
  margin-top: 7px !important;
  margin-bottom: 33px !important;
}

.catalogy_form {
  position: relative;
  order: 2;
  width: 100%;
}

.catalogy_form:hover .titulo {
  color: var(--color-orange);
}

.catalogy_form--car {
  width: 100% !important;
}

.selectbox__select--car {
  padding: 10px !important;
  font-size: 1.5rem;
  color: #434549;
  border-radius: 5px;
  height: 45px;
}

.selectbox__select--car.active .selectbox__select--contenido .titulo--car {
  color: var(--color-orange);
}

.selectbox__select--car.active .selectbox__select--contenido .titulo--car {
  color: var(--color-orange);
}

.selectbox__select--car:hover .selectbox__select--contenido .titulo--car {
  color: var(--color-orange);
}

.selectbox__select.active .selectbox__select--contenido .titulo {
  color: var(--color-orange);
}

.range-price:hover .price_from_text {
  color: var(--color-orange);
}

.titulo--car {
  font-size: 1.5rem;
  color: #434549;
  margin-bottom: 0;
}

.catalogy_form__control {
  background: var(--color-white);
  box-shadow: 0px 3px 6px var(--color-gray-4);
  border: 0.4000000059604645px solid var(--color-gray-1);
  border-radius: 5px;
  opacity: 1;
  height: 47px;
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-5);
  padding-left: 1.5rem;
  appearance: none;
}

.catalogy_form__control--down {
  background: url("../../images/icons/down-arrow.svg");
  background-repeat: no-repeat;
  width: 22px;
  height: 15px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.catalogy_form__control:focus {
  color: #212529;
  background-color: var(--color-white);
  border-color: var(--color-gray-1);
  outline: 0;
  box-shadow: none;
}

.catalogy__images {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 3.5rem;
}

.marcas__images {
  width: 70px;
  margin-bottom: 0;
  height: 70px;
}

.carousel__new .swiper-pagination-bullets {
  bottom: -4px;
}

.marcas__images img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tires__carousel .swiper-slide {
  /**margin: 0 auto!important;**/
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel__new .swiper-slide {
  /**margin: 0 auto!important;**/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 85px !important;
}

.carousel__new .swiper-pagination-bullet {
  background: var(--color-gray-6);
  opacity: 1;
}

.carousel__new .swiper-pagination-bullet-active {
  background: var(--color-orange);
}

.tires__carousel .swiper-pagination-bullets {
  bottom: -2px;
}

.tires__carousel .swiper-pagination-bullet {
  background: var(--color-white);
}

.tires__carousel .swiper-pagination-bullet-active {
  background: var(--color-orange);
}

.marcas__images--used {
  width: 80px;
  margin-bottom: 30px;
  height: 40px;
}

.city_car--default {
  margin-top: -1rem;
}

.marcas__images--used img {
  width: 91%;
}

.catology--used {
  margin-top: 2rem;
  margin-bottom: 2.2rem;
  font-size: 1.8rem;
}

.panel__title--used {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/**Banner*/
.banner {
  margin-top: 20px;
  text-align: center;
  height: 90px;
}

.banner-num__result {
  margin-top: 20px;
  text-align: center;
}

.banner-num__result img {
  width: 100%;
}

.banner--default {
  margin-bottom: 3rem;
}

.banner--used {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
}

.banner--new {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
}

.banner img {
  width: 100%;
}

.banner__top--40 {
  margin-top: 40px;
}

.banner__top_mobile--35 {
  margin-top: 0;
  height: auto;
}

.banner__top--35 {
  margin-top: -35px;
  height: 150px;
}

.banner__top_mobile--6 {
  margin-top: -48px;
}

/***Map AUto****/
.imagen-map {
  /**height: 217px;
	width: 100%;
    text-align: center;**/
  min-height: 100px;
  width: 100%;
  text-align: center;
  margin-top: -36px;
}

.imagen-map img {
  width: 93%;
}

.mapa-botons {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 3rem;
  margin-bottom: 6rem;
}

.btn__text {
  font-size: 1.4rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  display: flex;
  height: 42px;
  align-items: center;
  justify-content: flex-start;
}

.btn__icon {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-left: 8px;
  vertical-align: middle;
  margin-right: 10px;
}

.btn__icon--waze {
  background: url("../../images/icons/waze.svg") center/cover no-repeat;
}

.btn__icon--pin {
  background: url("../../images/icons/pin.svg") center/cover no-repeat;
  width: 12px;
  height: 17px;
}

.btn__icon--grados {
  background: url("../../images/icons/360-grados.svg") center/cover no-repeat;
}

.bg-main {
  background-repeat: no-repeat;
  object-fit: none;
  background-size: 100%;
  height: 165px;
}

.bg-main--used {
  background: url("https://movicenter-web.s3.us-east-2.amazonaws.com/banners/mobile/banner_secundario_usados_movil.png")
    no-repeat;
  background-size: 100%;
  object-fit: cover;
}

.bg-main--new {
  background: url("../../images/background/happy-woman-driving.png");
}

.main__title--information {
  font-size: 2rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0;
  color: var(--color-white);
  margin-bottom: 0;
  padding-left: 10px;
}

.main__call--information {
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  width: 272px;
  height: 22px;
  background: var(--color-orange);
  color: var(--color-white);
  margin-bottom: 0;
  line-height: 2.2rem;
  margin-left: -15px;
  padding-left: 22px;
}

.container--information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

/*Informacion***/

.information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 165px;
}

.information__title1--new {
  font-size: 2.2rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--color-white);
  margin-bottom: 0;
  padding-left: 1rem;
}

.information__title2--new {
  font-size: 3.1rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--color-orange);
  margin-bottom: 0;
  padding-left: 1rem;
  margin-top: -15px;
  margin-bottom: -4px;
}

.information__call--new {
  font-size: 1.8rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--color-white);
  background: rgb(244 116 34 / 56%);
  border-radius: 0 100px 100px 0;
  margin-left: -18px;
  width: 193px;
  height: 30px;
  padding-left: 3rem;
}

.panel__title--new {
  margin-top: 45px;
  margin-bottom: 0;
}

.simulador__title {
  font-size: 1.6rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-1);
  margin-top: 0.8rem;
  margin-bottom: 2.2rem;
  width: 100%;
}

.simulador__advertencia {
  font-size: 1.1rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #acacac;
  text-align: justify;
}

.simulador_range {
  flex-direction: column;
}

.simulador {
  padding-top: 4rem;
  padding-bottom: 5rem;
}

.search__car {
  padding-bottom: 2.5rem;
}

.container-plazo {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.plazo-details {
  width: 31px;
  height: 30px;
  position: relative;
  cursor: pointer;
}

.plazo-radio {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 31px;
  height: 30px;
  background: var(--color-soft-gray);
  border-radius: 50%;
}

.plazo-radio:checked {
  background: var(--color-orange);
}

.plazo-radio:checked + label {
  color: var(--color-white);
}

.plazo-details label {
  font-size: 1.2rem;
  font-weight: normal;
  color: var(--color-gray-1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container__interest--default {
  margin-top: -6.5rem;
}

.container__interest--other {
  margin-top: -8rem;
  background: linear-gradient(#f0f0f0 63%, #fff 30%);
}

.container__interest--carroceria {
  margin-top: 5rem;
}

.catalogy__marca--default {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.input-simulador .input-range__label-container {
  display: none;
}

.input-orange {
  color: var(--color-orange);
}

/***Busqueda marca-modelo***/

.search-marca-group {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  margin-bottom: 18px;
}

.input-search {
  background: var(--color-white);
  border-radius: 5px;
  height: 36px;
  padding-left: 35px;
  width: 100%;
}

.input-search::placeholder {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: var(--color-gray-5);
  opacity: 0.56;
}

.input-search--icon {
  background: url("../../images/icons/search.svg");
  background-repeat: no-repeat;
  width: 22px;
  height: 15px;
  position: absolute;
  top: calc(50% - 7px);
  left: 12px;
}

.marca___container {
  display: flex;
  justify-content: space-between;
}

.marca--text {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-gray-1);
}

.text--return:before {
  content: url("../../images/icons/down-arrow-return.svg");
}

.text--return {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-gray-1);
  text-decoration: none;
  margin-right: 10px;
  border: none;
  background: #fff;
  outline: none;
  cursor: pointer;
}

.marca-list {
  height: 52vh;
  overflow-y: scroll;
}

.marca-list .card__body {
  flex: 0 !important;
}

.marca-selected {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.marca-form {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-check {
  display: flex;
  align-items: center;
  padding-left: 0;
}

.search-form-check {
  background: var(--color-white);
  border: 1px solid var(--color-gray-3);
  border-radius: 1px;
  width: 15px;
  height: 15px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
}

.search-form-check--route {
  width: 20px;
}

.search-form-check:focus {
  outline: none;
}

.search-form-check:checked {
  background: var(--color-orange);
  border: none;
}

.search-form-check:checked::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 3px;
  width: 12px;
  height: 2px;
  background: var(--color-white);
  transform: rotate(-45deg);
}
.search-form-check:checked::after {
  content: "";
  position: absolute;
  top: 9px;
  left: 2px;
  width: 4px;
  height: 2px;
  background: var(--color-white);
  transform: rotate(45deg);
}

.search-form-label {
  font-size: 1.3rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-5);
  padding-top: 5px;
  cursor: pointer;
}

.search-form-select {
  box-shadow: none;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 65px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
  background-color: var(--color-white);
  padding-top: 1rem;
}

.marca-form-label {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  color: var(--color-gray-1);
  margin-left: 5px;
  padding-top: 5px;
  cursor: pointer;
}

.search-form-select:hover,
.search-selected,
.search-form-select:active {
  /*border: 1px solid var(--color-orange);*/
  cursor: pointer;
}

.search-form-check--orange .form-select__title {
  color: var(--color-orange);
}

.search-form {
  display: flex;
  align-items: center;
}

/**Modal**/
.modal-content {
  padding: 2rem;
  border-radius: 7px;
}

.modal-content--orange .modal-content {
  background: transparent
    radial-gradient(closest-side at 60% 68%, #f09559 0%, #f47422 100%) 0% 0%;
}

.modal-initial .modal-content--white {
  background: var(--color-white);
}

.modal__closed {
  text-align: right;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.modal__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal__header--title {
  font-size: 3rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: var(--color-white);
  text-align: center;
  line-height: 4rem;
  margin-bottom: 2.2rem;
}

.modal__header--title span {
  font-weight: bold;
}

.modal__container {
  display: flex;
  flex-direction: column;
}

.benefits-car {
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 20px;
}

.benefits-car__left,
.benefits-car__right {
  width: 47%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.benefits-car__search::after,
.benefits-car__choose::after {
  content: none;
}

.benefits-car__left {
  padding-right: 20px;
}

.benefits-car__right {
  padding-left: 20px;
}

.benefits-car__title {
  font-size: 1.2rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--color-white);
  line-height: 1.5rem;
}

.benefits-car__title span {
  font-size: 1.7rem;
  font-weight: 500;
}

.benefits-car__planned {
  padding-left: 3rem;
}

.benefits-car__visit {
  padding-right: 3rem;
}

.benefits-car__search {
  text-align: right;
}

.benefits-car__choose {
  padding-top: 1.5rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.benefits-car__button {
  width: 100%;
  text-align: center;
  margin-top: 3rem;
}

.benefits-car__button button {
  width: 100%;
}

.modal__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.modal__footer p {
  font-size: 1.7rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--color-white);
  text-align: center;
  line-height: 2.5rem;
  margin-bottom: 2rem;
}

.modal__footer img {
  width: 60%;
}

/**Blog**/

.border-20 {
  border-radius: 0 0 20px 20px;
  margin-bottom: 2rem;
}

.blog__text--mapa {
  font-size: 1.5rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-1);
  margin-bottom: 2.5rem;
}

.btn_container {
  width: 19%;
  text-align: center;
  height: 95%;
}

.blog--used {
  margin-top: 2rem;
}

.blog__button {
  font-family: "Poppins", sans-serif;
  margin-top: 2rem;
}

.blog__button button {
  width: 100%;
}

.text--orange {
  color: var(--color-orange);
}

.blog__info {
  margin-top: 43px;
  margin-bottom: 1rem;
}

.blog__title {
  font: normal normal 600 28px/36px Poppins;
  font-size: 2.5rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-1);
}

/***My route***/

.bg-gradiente {
  background: transparent
    radial-gradient(closest-side at 60% 68%, #f09559 0%, #f47422 100%);
}

.route {
  height: 440px;
}

.route-info-car {
  display: flex;
  flex-direction: column;
}

.route__title {
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: var(--color-white);
  line-height: 2.5rem;
}

.route-title-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.route-title-right {
  font-size: 2.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: var(--color-gray-1);
  display: none;
}

.route-title-left {
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: var(--color-gray-5);
  display: none;
}

.without_car {
  background: url("../../images/my-route/without_car.png") no-repeat;
  height: 250px;
  background-size: 50%;
  background-position: center;
  padding: 3rem;
  margin-top: 5rem;
}

.without_car__title {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: var(--color-gray-1);
}

.without_car__subtitle {
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: var(--color-gray-5);
  margin-bottom: 2.5rem;
  text-align: justify;
}

.route__text {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: var(--color-white);
  padding-right: 1rem;
}

.route__features {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.route__features ul {
  width: 50%;
}

.route__features .route__image {
  width: 50%;
}

.route__image img {
  width: 100%;
}

.route__features ul li {
  list-style: none;
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: var(--color-white);
}

.icon__route--find::before {
  content: url("../../images/my-route/route-icon-find.png");
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.3rem;
}

.icon__route--star::before {
  content: url("../../images/my-route/route-icon-star.png");
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.3rem;
}

.icon__route--visit::before {
  content: url("../../images/my-route/route-icon-visit1.png");
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.3rem;
}

.route__known {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2.2em;
}

.icon__route--fast_forward {
  background: url("../../images/icons/fast-forward-route.svg");
  opacity: 0.43;
  width: 28px;
  height: 38px;
}

.car-selected {
  margin-top: -50px;
}

.car-selected__container {
  display: flex;
  width: 90%;
  align-items: center;
}

.car-selected__image {
  width: 250px;
  height: 71px;
  background: var(--color-white);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.car-selected__image img {
  width: 100%;
  object-fit: fill;
  height: 100%;
}

.car-selected__details {
  margin-left: 1rem;
  width: 500px;
}

.car-selected__images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.car-selected__marca {
  height: 22px;
}

.car-selected__type {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: var(--color-gray-5);
  margin-bottom: 0;
}

.car-selected__title {
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: var(--color-orange);
  margin-bottom: 0;
}

.car-selected__price {
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: var(--color-gray-1);
  display: flex;
  flex-direction: column;
}

.car-selected__deleted {
  align-self: start;
  cursor: pointer;
}

.route-opciones {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 1.5rem;
  padding-top: 1rem;
  text-align: center;
}

.route-opciones__title {
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: var(--color-gray-1);
  line-height: 2.5rem;
  width: 100%;
}

.route-opciones__subtitle {
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: var(--color-gray-5);
}

.route-opciones__help {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: var(--color-orange);
}

.route-opciones--fast_forward {
  background: url("../../images/icons/fast-forward.svg");
  width: 33px;
  height: 45px;
  background-size: 100%;
  position: absolute;
  bottom: -38px;
  z-index: 100;
}

.route__btn {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.route__btn .btn {
  padding: 0.25rem 3rem;
}

.btn-primary-size {
  font-size: 1.5rem;
}

.route-group {
  position: relative;
}

.route__form-input {
  width: 100%;
  height: 55px;
  background: var(--color-white);
  box-shadow: 0px 3px 6px var(--color-ligth-gray);
  border-radius: 5px;
  opacity: 1;
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
}

.route__form-input:focus {
  border-color: var(--color-orange);
  box-shadow: none;
}

.route-group label {
  position: absolute;
  top: 0.5rem;
  left: 1.5rem;
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: var(--color-gray-1);
}

.route__form-check {
  border: 0.5px solid var(--color-orange);
}

.route__form-label {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: var(--color-gray-5);
  margin-left: 0.5rem;
  cursor: pointer;
}

.route__form-label a {
  text-decoration: underline;
  color: inherit;
}

.route-contact__text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.route-check {
  margin-top: 4rem;
  margin-bottom: 3rem;
  display: flex;
}

.route__form-select {
  height: 50px;
  width: 100%;
  box-shadow: 0px 3px 6px #00000012;
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.route__form-select:focus {
  border-color: var(--color-orange);
  box-shadow: none;
}

.form-down-arrow {
  background: url("../../images/icons/down-arrow.svg");
  background-repeat: no-repeat;
  width: 22px;
  height: 15px;
  position: absolute;
  top: 20px;
  right: 20px;
}

/***Buscador de intereses****/

.search-interest {
  width: 100%;
  margin-top: -10px;
}

.search-interest__subtitle {
  font-size: 1.5rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-5);
  text-align: left;
  margin-top: 1rem;
}

.interest__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.type-interest {
  width: 65px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

.type-interest__image {
  width: 53px;
  height: 50px;
  box-shadow: 0px 1px 3px #0000001d;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  position: relative;
  cursor: pointer;
}

.type-interest__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.type-interest__radio {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 50px;
  background: var(--color-white);
  border-radius: 10px;
  cursor: pointer;
}

.type-interest__radio:checked {
  background: var(--color-orange);
}

.type-interest p {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-3);
  text-align: center;
}

.my-interest {
  min-width: 245px;
  width: 100%;
  min-height: 78px;
  background: var(--color-white);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 1rem;
}

.my-interest-flex {
  align-items: center;
  justify-content: flex-start;
}

.my-interest__small {
  font-size: 1.4rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-1);
  margin-bottom: 0;
  opacity: 0.37;
}

.option-item-insterest {
  min-width: 79px;
  height: 26px;
  background: var(--color-soft-gray);
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.option-item-text {
  font-size: 1.2rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-1);
  padding: 1rem;
  margin-right: 1rem;
  margin-bottom: 0;
}

.title--modal {
  font-size: 2rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-1);
  margin-bottom: 0;
}

.subtitle--modal {
  font-size: 1.5rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-5);
}

.modal__header--left {
  align-items: flex-start;
}

.modal__title--insterest {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/***Configuracion default***/

.btn-outline-primary {
  border-color: var(--color-primary);
  color: var(--color-primary);
  font-size: 1.5rem;
  background: var(--color-white);
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: none;
}

.btn-outline-primary:hover {
  color: var(--color-primary);
  background-color: var(--color-white);
  border-color: var(--color-primary);
  box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: var(--color-primary);
  background-color: var(--color-white);
  border-color: var(--color-primary);
  box-shadow: none;
}

.bg-orange {
  background: var(--color-orange);
}

.btn-primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.btn-primary.active:focus,
.btn-primary:active:focus,
.btn-primary:hove,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.btn-secondary:hover {
  box-shadow: none;
}

.btn-primary.active,
.btn-primary:active,
.btn-primary:hover {
  background-color: rgb(226 67 34 / 70%);
  border-color: rgb(255 255 255);
  outline: none;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  outline: none;
}

.btn-secondary {
  background-color: var(--color-gray-5);
  border-color: var(--color-gray-5);
}

.btn-secondary.active,
.btn-secondary:active,
.btn-secondary:hover {
  background-color: var(--color-gray-5);
  border-color: var(--color-gray-5);
  outline: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--color-primary);
}

.panel__title--orange {
  color: var(--color-orange);
}

.text-transparent {
  background: transparent;
}

.modal-90w {
  width: 90%;
  margin: 0 auto;
}

/**Página de resultado****/

.text--return:hover {
  color: var(--color-gray-1);
  text-decoration: none;
}

.route-opciones--gray {
  color: var(--color-gray-5);
}

.card__banner img {
  width: 100%;
  object-fit: cover;
  max-height: 174px;
}

.quote-mobile {
  display: block;
  padding-top: 1rem;
}

.carroceria_imagen {
  width: 50px;
}

.benefits-car__image {
  text-align: center;
}

.benefits-car__image img {
  width: 80%;
}

.main--top-new {
  margin-top: -23rem;
}

.group_trans_fuel {
  display: flex;
  flex-direction: column;
}

.car-banner__detail-5 {
  margin-bottom: 5rem;
}

.info-car__description--info {
  width: 200px;
  height: auto;
  align-self: flex-end;
}

.slider-blog .swiper-slide {
  height: 80px;
}

.col_featured--used {
  height: 263px;
}

.modal {
  z-index: 105000;
}

.infinite-scroll-component {
  overflow: inherit !important;
}

.banner-main--home img {
  object-fit: contain !important;
}

.grafic-home__electric {
  height: 102px;
  padding: 0;
  border-radius: 15px;
}

.grafic-home__electric img {
  border-radius: 15px;
  object-fit: scale-down;
}

.advertising-cursor {
  cursor: pointer;
}

.header-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  transition: 0.7s;
}

.banner-home__position_4 {
  height: 320px;
  margin-bottom: -3rem;
  margin-top: 0;
}

.banner-home__zindex {
  z-index: 1000;
}

.banner__financing {
  margin-top: 0;
  margin-bottom: 8rem;
}

.service-map__image .mapa-local-path path.selected {
  fill: #f47422;
  animation-name: parpadeo;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

.fig-myroute {
  background-color: var(--color-orange);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  padding: 0.5rem;
}

.fig-myroute img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-top: 0.25rem;
}

.background-top-white {
  background: #ffffff;
  box-shadow: 0px 3px 6px #0000000f;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.btn-fs-16 {
  font-size: 1.6rem;
}

.quote-group__form {
  margin-top: 0.25rem !important;
  margin-bottom: 0 !important;
}

.quote-group__form .form__contact-label,
.quote-group__main .form__contact-label {
  font-size: 1.2rem !important;
}

.featured-moto__slide-w .swiper-pagination .swiper-pagination-bullet {
  width: 13px !important;
  height: 13px !important;
  background: var(--color-white);
  opacity: 1;
}

.featured-moto__slide-w .swiper-pagination .swiper-pagination-bullet-active {
  background: #bcbcbc;
}

.slide-vehicle__item {
  width: 150px;
  height: 145px;
  margin: 0 auto;
  overflow: hidden;
}

.slide-vehicle__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quote-photo {
  display: flex;
  justify-content: space-between;
}

.quote-photo button {
  width: 45%;
}

.background-liner-fff0 {
  background: linear-gradient(#fff 60%, #f0f0f0 30%);
}

.slider-home {
  height: 195px;
  position: relative;
  cursor: pointer;
}

.slider-home__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-gray-3);
  border-radius: 20px;
}

.rc-slider-track {
  height: 0.5rem !important;
  background-color: var(--color-orange) !important;
}

.rc-slider-step {
  height: 1rem !important;
}

.rc-slider-rail {
  height: 0.5rem !important;
  border-radius: 3px;
}

.rc-slider-handle {
  height: 20px !important;
  width: 20px !important;
  border: solid 2px var(--color-orange) !important;
  background-color: var(--color-orange) !important;
  opacity: 1 !important;
  margin-top: -8px !important;
}

.rc-slider-handle:active {
  border-color: var(--color-orange) !important;
  box-shadow: 0 0 5px var(--color-orange) !important;
}

.rc-slider-dot {
  background-color: transparent !important;
  border: 2px solid transparent !important;
}

.rc-slider-mark-text {
  color: #212529 !important;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
}

.filter__item .search__type {
  margin-bottom: 0;
}

.width-price {
  width: 95%;
  margin-bottom: 2.5rem;
}

.rc-slider-mark-text:first-child {
  left: 0% !important;
  transform: translateX(0%) !important;
  width: 100% !important;
  text-align: left !important;
}

.rc-slider-mark-text:last-child {
  left: 100% !important;
  transform: translateX(-100%) !important;
  width: 100% !important;
  text-align: right !important;
}

.rc-slider-mark {
  top: 25px !important;
}

.share-text::before {
  content: url("../../images/icons/share-black.svg");
  width: 20px;
  height: 17px;
  display: inline-block;
  vertical-align: middle;
}

.share-text:hover::before {
  content: url("../../images/icons/share--orange.svg");
  width: 20px;
  height: 17px;
  display: inline-block;
  vertical-align: middle;
}

.share-text {
  color: var(--color-black);
  font-weight: 400;
  font-size: 1.4rem;
}

.share-text:hover {
  color: var(--color-orange);
}

.container-stock {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.label-stock {
  color: red;
  font-size: 1.3rem;
  font-weight: 600;
  margin-left: 1rem;
  margin-bottom: 0;
}

.price-line {
  text-decoration: line-through;
}

.multiple__brand__control {
  font-size: 1.5rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-5);
  margin-bottom: 0;
  border: 1px solid var(--color-gray-5) !important;
  border-radius: 5px 5px 0 0 !important;
  margin-left: -0.2rem;
  width: 101% !important;
}

.multiple__brand__control--is-focused {
  border: 1px solid var(--color-orange) !important;
  box-shadow: 1px solid var(--color-orange) !important;
}

.multiple__brand__value-container {
  padding: 2px 8px 2px 0 !important;
}

.multiple__brand__menu {
  font-size: 1.4rem !important;
  margin-top: 0 !important;
  margin-left: -0.2rem;
  border-left: 1px solid var(--color-gray-5) !important;
  width: 100% !important;
}

.select-multiple__brand {
  position: absolute !important;
  left: 0;
  bottom: -38px;
  right: 0;
  z-index: 100;
}

.multiple__brand__group-heading {
  color: var(--color-gray-1) !important;
  font-size: 1.4rem !important;
}

.form-filter__container {
  max-height: 250px;
  overflow-x: hidden;
}

.multiple__brand__menu-list::-webkit-scrollbar {
  width: 10px !important;
}

.multiple__brand__menu-list::-webkit-scrollbar-track {
  background-color: var(--color-white) !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.multiple__brand__menu-list::-webkit-scrollbar-thumb {
  background: #8a8b8d !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 30px !important;
}

.multiple__brand__option {
  cursor: pointer !important;
}

.group-brand__clic,
.group-model__clic {
  position: relative;
}

.multiple__brand__input-container::before {
  content: url("../../images/icons/search.svg");
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.multiple__brand__placeholder {
  margin-left: 3rem !important;
}

.multiple__brand__input {
  min-width: 200px !important;
  margin-left: 2.5rem !important;
}

.footer-services--link {
  color: var(--color-white);
  cursor: pointer;
}

.footer-services--link:hover {
  color: #212529;
}

.footer-services {
  margin-bottom: 0.5rem;
}

.menu__item--service {
  width: 200px;
  background: var(--color-white);
  font-size: 1.5rem;
  text-align: center;
  margin: 0 auto 2.5rem;
  padding: 5px;
  color: var(--color-orange);
  font-weight: 400;
}

.wp-block-embed__wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.wp-block-embed__wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.service-result-removolque {
  width: 300px;
  background: var(--color-white);
  border-radius: 20px;
  height: 250px;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  cursor: pointer;
}

.result-item__removolque {
  width: 220px;
  margin: 0 auto;
  height: 200px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;
}

.result-removolque--title {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--color-orange);
  text-transform: uppercase;
  margin-top: 1rem;
}

.result-item__removolque--img {
  width: 95%;
  height: 100%;
  object-fit: contain;
}

.flex-row {
  flex-direction: row;
}

.route-selected {
  width: 10%;
}

.form__filter-route {
  width: 25px !important;
  height: 25px !important;
  border: 0.5px solid #939393 !important;
}

.form__filter-route:before {
  top: 12px !important;
  left: 5px !important;
  width: 19px !important;
}

.form__filter-route:after {
  top: 16px !important;
  left: 2px !important;
  width: 8px !important;
}

.form__filter-route:checked {
  border: none !important;
}

.m-6 {
  margin-top: 6rem;
}

.container-flex {
  display: flex;
  flex-direction: column;
}

.filter-selected-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-direction: column;
}

.container-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.container-input__number {
  width: 85%;
  font-size: 1.2rem;
  height: 3rem;
  padding: 1rem 0.5rem;
}

.container-input-group {
  width: 47%;
  border: 0.6px solid var(--color-gray-5);
  border-radius: 3px;
  background: white;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: 0.25rem;
}

.price_from_text {
  padding: 0.275rem 0.25rem !important;
  background-color: #fff !important;
  border: 1px solid #fff !important;
  font-size: 1.2rem !important;
}

.flex-column {
  flex-direction: column;
}

.filter-price .container-input {
  flex-direction: column;
}

.filter-price .container-input .container-input-group {
  width: 100%;
}

.container-none,
.col-12-none {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  padding-top: 1rem;
}

.background-top-transparent .menu__desktop--item li a,
.background-top-transparent .menu__desktop--item li .dropdown-service {
  color: var(--color-white);
}

.blog-section__button {
  font-size: 1.6rem;
}

.blog-section__button::after {
  content: url("../../images/icons/down-arrow-left.svg");
  display: inline-block;
  display: inline-block;
  transform: rotate(180deg);
  margin-left: 0.75rem;
  vertical-align: middle;
}

.col_featured--g-default {
  background: linear-gradient(#fff 60%, #f0f0f0 30%);
}

.card-color {
  background-color: var(--color-soft-gray);
  border: 0;
}

.slider-home-loading {
  height: 135px;
}

.slider-home-loading__skeleton {
  border-radius: 20px;
}

.span-telephone-contact-car {
  position: absolute;
  top: 2.5rem;
  left: 1rem;
  font-size: 1.2rem;
  color: #495057;
}

@media only screen and (min-width: 350px) {
  .slider-home {
    height: 245px;
  }

  .header__template--default {
    height: 355px;
  }

  .row-top-brand {
    margin-top: 3rem;
  }

  .service__details--used {
    width: 162px;
    height: 155px;
  }
}

@media only screen and (min-width: 360px) {
  .banner__top_mobile--35 {
    margin-top: 25px;
  }

  .main__call_action--default {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 375px) {
  .normal-advanced--text {
    font-size: 1.2rem;
  }
  /**.service__details {
		width: 140px;
		height: 140px;
	}**/

  .main__title--default {
    font-size: 3rem;
    line-height: 3rem;
    width: 70%;
  }

  .square__text {
    font-size: 1.3rem;
    top: 7px;
    left: 11px;
  }

  .search__type--title,
  .card__title {
    font-size: 1.6rem;
  }

  .medium__square__text {
    font-size: 1.4rem;
  }

  .search_type_transmission--m .search-medium__square {
    width: 120px;
  }

  .card__subtitle {
    font-size: 1.5rem;
  }

  /**.service__details {
	    width: 140px;
	    height: 140px;
	}**/

  .featured__title {
    font-size: 1.2rem;
  }

  .banner__top--35 {
    height: 185px;
  }

  .banner__top_mobile--35 {
    margin-top: 60px;
  }

  .service-result-removolque {
    width: 240px;
  }
}

@media only screen and (min-width: 400px) {
  /**My route***/
  .route__features ul li {
    font-size: 1.2rem;
  }
  .route-opciones__title-p-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .route-opciones__subtitle {
    font-size: 1.5rem;
  }

  .header__template--default {
    height: 380px;
  }

  .container__interest__title {
    font-size: 2.7rem;
  }

  .container__interest__title--other {
    font-size: 2.5rem;
  }

  .panel__title {
    font-size: 2.7rem;
  }

  .service--used {
    font-size: 2.2rem;
  }

  .featured__price {
    font-size: 1.8rem;
  }

  .featured__title {
    font-size: 1.4rem;
  }

  .modal__header--title {
    font-size: 3.4rem;
  }

  .modal__footer p {
    font-size: 2rem;
    margin-bottom: 2.5rem;
  }

  .normal-advanced--text {
    font-size: 1.5rem;
  }

  .search-normal__square {
    width: 127px;
  }

  .square__text {
    font-size: 1.5rem;
    top: 5px;
    left: 18px;
  }

  .search-advanced_form__control,
  .search-advanced--label {
    font-size: 1.5rem;
  }

  .search-medium__square {
    width: 95px;
  }

  /**.service__details {
	    width: 160px;
	    height: 160px;
	}**/

  .service__title {
    font-size: 1.5rem;
  }

  /**.quote__container {
		width: 330px;
	}**/

  .blog__imagen {
    width: 100%;
    height: 200px;
  }

  .btn__text {
    font-size: 1.5rem;
  }

  .simulador__title {
    font-size: 2rem;
  }

  .tag_container__text {
    font-size: 2.4rem;
  }

  .benefits-car__title {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .benefits-car__title span {
    font-size: 2rem;
  }

  .main__call_action--default {
    font-size: 1.9rem;
  }

  .card__title {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .form-select__subtitle {
    font-size: 1.5rem;
  }

  .search-form-check--route {
    width: 15px;
  }

  .card__title--normal,
  .search__type--title {
    font-size: 1.8rem;
  }

  .type-interest {
    margin-right: 1.2rem;
  }

  .interest__container {
    justify-content: initial;
  }

  .without_car__title {
    font-size: 1.7rem;
  }

  .panel__title {
    line-height: 3rem;
  }

  .banner img {
    height: 100%;
    object-fit: cover;
  }

  .awto__title,
  .tag__title {
    font-size: 1.5rem;
  }

  .tag__image--awto {
    padding-right: 2rem;
  }

  .awto__subtitle,
  .awto__subtitle_2,
  .tag__subtitle {
    font-size: 1.3rem;
  }

  .tag__image--width {
    width: 75%;
    padding-right: 2rem;
  }

  .slider-home {
    height: 270px;
  }

  .title-main__blog {
    font-size: 1.6rem;
  }
}

@media only screen and (min-width: 500px) {
  .service-result-removolque {
    width: 200px;
    margin-right: 3rem;
  }

  .result-item__removolque {
    width: 180px;
  }

  .slider-home {
    height: 330px;
  }

  .header__template--default {
    height: 445px;
  }

  .service__container--default {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 562px) {
  .header__template--default {
    height: 485px;
  }

  .slider-home {
    height: 369px;
  }
}

@media only screen and (min-width: 600px) {
  .slider-home {
    height: 155px;
  }

  .card__container--top {
    margin-top: -3rem;
  }

  .blog__imagen__home {
    object-fit: contain;
  }

  .header__template--default {
    height: 270px;
  }

  .service__details--used {
    width: 165px;
    height: 170px;
  }

  .slide-service .swiper-wrapper {
    height: 210px;
  }
}

@media only screen and (min-width: 768px) {
  .slider-home {
    height: 220px;
  }

  .fig-myroute {
    margin-top: 1rem;
  }

  .row-top-brand {
    margin-top: 4rem;
  }

  .blog-section-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .blog-section__button {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .header__template--default {
    height: 100%;
    margin-bottom: 2rem;
  }

  .header-home .swiper-container .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
  }

  .header-home
    .swiper-container
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #d05032;
  }

  .container-none {
    max-width: 720px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .col-12-none {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .main__call_action {
    padding: 0px 8px 2px;
  }

  .slider-home-loading {
    height: 220px;
  }

  .card__container--top {
    margin-top: 0;
  }

  .banner img {
    height: 100%;
    object-fit: contain;
  }

  .result-item__removolque {
    width: 270px;
    height: 200px;
  }

  .service-result-removolque {
    width: 300px;
    height: 290px;
  }

  .carousel__new .swiper-slide {
    width: 185px !important;
  }

  .container-flex {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 992px) {
  .slider-home {
    height: 285px;
  }

  .container-none {
    max-width: 960px;
  }

  .service-result-removolque {
    width: 270px;
    height: 305px;
    margin-bottom: 5rem;
  }

  .slider-home-loading {
    height: 285px;
  }
}

@media only screen and (min-width: 1200px) {
  .container-none {
    max-width: 1140px;
  }

  .slider-home {
    height: 350px;
  }

  .service-result-removolque {
    width: 300px;
    height: 315px;
    margin-right: 7rem;
  }

  .slider-home-loading {
    height: 350px;
  }

  .slide-service .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 44px;
  }
}

.span-telephone-contact {
  position: absolute;
  top: 2.6rem;
  left: 1rem;
  font-size: 1.3rem;
}

.form__contact-input--telephone-route {
  padding-left: 44px !important;
}

.service-result__text {
  font-size: 1.3rem;
  font-weight: 400;
  color: var(--color-dark-gray);
}

.grecaptcha-badge {
  display: none;
}

.modal__closed--temporizador {
  top: -3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modal__closed--subtitle {
  color: var(--color-white);
  font-size: 1.5rem;
  width: 200px;
  text-align: right;
  margin-bottom: 0;
  padding-right: 1rem;
}

.modal__closed--icon {
  width: 70%;
}

.LoadingCircle {
  opacity: 1;
  width: 35rem;
  height: 35rem;
  color: var(--color-orange);
}

.LoadingUserScreen {
  background-color: rgb(245 240 235 / 80%);
  position: fixed;
  z-index: 10000000000000;
  opacity: 1;
  width: calc(100vw);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in 200ms;
}

.LoadingUserScreenOpacityNone {
  opacity: 0;
}

.LoadingUserScreenNone {
  display: none;
}

.LoadingContainer img {
  position: absolute;
  margin-left: 30px;
  margin-top: 159px;
  width: 300px;
}

.background-absolute {
  background: var(--color-soft-gray);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.services-autorized {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5rem 2em;
  padding: 3rem 0;
}

.services-primary {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5rem 2em;
  padding: 3rem 0;
}

@media only screen and (min-width: 610px) {
  .services-autorized {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 3rem;
  }

  .services-primary {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 3rem;
  }
}

@media only screen and (min-width: 992px) {
  .services-autorized {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3rem;
    margin-bottom: 30rem;
  }

  .services-primary {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3rem;
    margin-bottom: 5rem;
  }
}

@media only screen and (min-width: 1200px) {
  .services-autorized {
    column-gap: 3rem;
  }

  .services-primary {
    column-gap: 3rem;
  }
}
