.compare{
	font-family: 'Poppins', sans-serif;
}

.compare-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.compare-title h1 {
	font-size: 2rem;
	font-weight: 600;
	color: var(--color-gray-1);
	margin-top: 2.5rem;
}

.compare-subtitle {
	font-size: 1.4rem;
	font-weight: normal;
	color: var(--color-gray-5);
	margin-bottom: 3rem;
    margin-top: 2rem;
}

.compare__container {
	display: flex;
	overflow: hidden;
}

.compare-result {
	display: flex;
	width: 80%;
}

.compare-result__item {
	width: 162px!important;
	/**border: 1px solid red;**/
}

.compare-temp {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 11rem; 
	height: 32px;
	width: 130px;
	margin-bottom: 1rem;
}

.compare-temp__title {
	font-size: 1.2rem;
	font-weight: 500;
	color: var(--color-orange);
    margin-bottom: 0rem;	
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: justify;
}

.compare-temp__fast {
	content: url('../../images/icons/fast-forward-compare.svg');
	width: 26px;
	height: 19px;
	margin-right: 1rem;
	cursor: pointer;
}

.compare__info {
	min-height: 43px;
	display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.compare__info p {
	font-size: 1.3rem;
	font-weight: normal;
	color: var(--color-gray-5);
	margin-bottom: 0;
	padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
}

.compare__info.active {
	background: rgb(237 237 237 / 26%);
}

.compare__imagen {
	height: 97px;
    background: var(--color-white);
    box-shadow: 0px 1px 2px #0000002b;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.compare__imagen img {
	width: 118px;
}

.compare__title {
	font-size: 1.2rem;
	font-weight: 500;
	color: var(--color-orange);
	margin-top: 1.5rem;
    margin-bottom: 1rem;	
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: left;
    height: 32px;
}

.compare-button {
	display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.compare-button button {
	font-size: 1.8rem;
	width: 155px;
	padding: 1rem .5rem;
	margin: 4rem .5rem;
}

.slide-compare .swiper-slide {
	width: 150px!important;
}

.compare-result .swiper-container {
    margin-left: 0;
    margin-right: 0;
}