.main {
  text-align: center;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
}

.container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.title {
  color: var(--color-white);
  font-size: 2.2rem;
  margin-bottom: 0;
}

.title-coupon {
  color: var(--color-white);
  font-size: 2.1rem;
  margin-bottom: 0;
}

.title-moto {
  color: var(--color-white);
  font-size: 2.7rem;
  margin-bottom: 3.5rem;
  margin-top: 0.5rem;
  line-height: 2.8rem;
  font-weight: 600;
}

.title-tire {
  color: var(--color-white);
  font-size: 1.9rem;
  margin-bottom: 0;
}

.subtitle {
  color: var(--color-white);
  font-size: 1.2rem;
}

.subtitle-tire {
  color: var(--color-white);
  font-size: 1.2rem;
}

.subtitle-coupon {
  color: var(--color-white);
  font-size: 1.25rem;
  margin-bottom: 0;
}

.subtitle-moto {
  color: var(--color-white);
  font-size: 1.1rem;
  margin-bottom: 0;
}

.image-container__car {
  height: 116px;
}

.image-container__car img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container__tire {
  height: 132px;
}

.image-container__tire img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container__moto {
  height: 140px;
  display: flex;
  padding-top: 47px;
  margin-bottom: 30px;
  justify-content: flex-start;
}

.image-container__moto img {
  width: 60%;
  height: 100%;
  object-fit: contain;
}

.image-container__coupon {
  height: 150px;
  width: 150px;
  margin: 4rem auto 0;
}

.image-container__coupon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.join-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.join-title {
  color: var(--color-white);
  margin-bottom: 0;
}

.list-item {
  text-align: left;
  color: var(--color-white);
  margin-top: 1.5rem;
}

.item-description {
  margin-bottom: 0;
  margin-left: 1rem;
  line-height: 1.7rem;
  font-size: 1.4rem;
  margin-right: 0rem;
  text-align: left;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.footer {
  width: 180px;
  margin: 5rem auto 0;
}

.footer img {
  width: 90% !important;
}

.footer-coupon {
  width: 180px;
  margin: 3rem auto 0;
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 3.5rem;
  margin-bottom: 3rem;
}

.options-btn {
  border: 1px solid var(--color-white);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 136px;
  padding: 1rem;
  cursor: pointer;
  position: relative;
  background-color: #ffffff2e;
}

.options-btn:hover,
.options-btn:active {
  background: var(--color-warn-orange);
}

.options-btn__text {
  margin-bottom: 0;
  font-size: 1.2rem;
  color: var(--color-white);
  cursor: pointer;
}

.options-btn__image {
  width: 29px;
  height: 19px;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-left: 1rem;
}

.options-btn__image img {
  width: 100%;
  height: 100%;
}

.item-image {
  align-self: self-start;
  width: 19px;
  height: 19px;
  background-color: #fff;
  border-radius: 50%;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.25rem;
}

.item-image p {
  font-size: 1.2rem;
  color: #f47422;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  font-weight: 600;
}

.btn-next {
  height: 42px;
  border-radius: 5px !important;
  width: 100%;
  font-size: 2rem;
  border: 1px solid var(--color-white) !important;
  margin-top: 2rem;
  font-size: 1.5rem !important;
}

.btn-next:disabled {
  background: var(--color-gray-3) !important;
  color: var(--color-white) !important;
}

.notification-text__title {
  font-size: 3rem;
  font-weight: normal;
  color: var(--color-white);
}

.notification-text__subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: var(--color-white);
}

.file-photo {
  overflow: hidden;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.error-container {
  background: var(--color-soft-gray);
  border-radius: 5px;
  padding: 1rem;
}

.error-container__description {
  color: red;
  font-size: 1.3rem;
  margin-bottom: 0;
}

.error-container__description::before {
  content: url("../assets/images/icons/alert.svg");
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 1rem;
  vertical-align: middle;
}

.btn-circle-primary {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: rgb(0 0 0 / 62%);
  cursor: pointer;
}

.btn-circle-primary:hover {
  background: var(--color-gray-5);
}

.options-capture {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.form-group__concourse {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0.25rem !important;
  margin-bottom: 0 !important;
}

.form-concourse__check {
  border-radius: 1px;
  width: 20px;
  height: 15px;
  appearance: none;
  outline: none;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
  border: 1px solid var(--color-white);
  margin-top: 0.5rem;
}

.form-concourse__check:checked {
  background: var(--color-white);
  border: none;
}

.form-concourse__check:checked::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 3px;
  width: 12px;
  height: 2px;
  background: var(--color-orange);
  transform: rotate(-45deg);
}

.form-concourse__check:checked::after {
  content: "";
  position: absolute;
  top: 9px;
  left: 2px;
  width: 4px;
  height: 2px;
  background: var(--color-orange);
  transform: rotate(45deg);
}

.form-concourse__label,
.form-concourse__label a {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: var(--color-white);
  margin-left: 0.5rem;
  margin-bottom: 0;
  cursor: pointer;
  text-align: left;
  font-size: 1.2rem !important;
}

.form-concourse__label a {
  text-decoration: underline;
}

.form-concourse__label a:hover {
  color: var(--color-white);
}

.back-contact {
  font-size: 1.5rem;
  font-weight: normal;
  color: var(--color-white);
  cursor: pointer;
  margin-top: 2rem;
  margin-bottom: 0;
}

.back-contact:hover {
  text-decoration: underline;
}

.back-contact::before {
  content: url("../assets/images/icons/down-arrow-1.svg");
  width: 19px;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
}

.text-base {
  margin-top: 1rem;
  color: var(--color-white);
  font-size: 0.8rem;
  width: 81%;
  margin: 3rem auto 0 !important;
}

.text-base a {
  color: var(--color-white);
}

.text-base a:hover {
  text-decoration: underline;
  color: var(--color-white);
}

.modal-400 {
  width: 100% !important;
  max-width: none !important;
  margin: auto !important;
}

.text-bold {
  font-weight: 700;
}

.text-lowercase {
  text-transform: lowercase;
}

/**********************Landing****************/
.main-concourse {
  text-align: center;
  padding: 2rem 1rem 0;
  height: 1240px;
  font-family: "Poppins", sans-serif;
}

.main-logo {
  width: 160px;
  margin: 0 auto;
}

.header-title {
  font-size: 2rem;
  font-weight: 400;
  color: var(--color-white);
  text-align: left;
  line-height: 3rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.header-span {
  font-weight: bold;
}

.header-subtitle {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-white);
  text-align: left;
  line-height: 2rem;
  margin-bottom: 1rem;
}

.btn-main {
  font-size: 1.3rem !important;
  background: #e64517 !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px !important;
  width: 100%;
  height: 64px;
  color: var(--color-white) !important;
}

.btn-icon__uphold::before {
  content: url("../assets/images/concourse/uphload_photo.svg");
  width: 29px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.25rem;
}

.btn-icon__file::before {
  content: url("../assets/images/concourse/icon-file.svg");
  width: 29px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.25rem;
}

.logo {
  width: 100%;
  margin: 2rem auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-photo {
  width: 100%;
  margin: 3rem auto 2rem;
}

.step-item {
  background: transparent linear-gradient(180deg, #e64517 0%, #f47422 100%);
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 10px;
  height: 130px;
  padding: 3rem 1.5rem 0;
  text-align: left;
  position: relative;
  margin-bottom: 4rem;
  width: 100%;
}

.step-number {
  font-size: 7rem;
  font-weight: 900;
  color: var(--color-white);
  position: absolute;
  top: -3.5rem;
  right: 2rem;
}

.step-title {
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-white);
}

.step-subtitle {
  font-size: 1.1rem;
  font-weight: 400;
  color: var(--color-white);
}

.title-conditional {
  color: var(--color-white);
  font-weight: 600;
  font-size: 2.5rem;
  text-align: left;
  margin-top: 4rem;
}

.step-container {
  margin-top: 3rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.main-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header_container {
  width: 100%;
}

.main-photo__d {
  display: none;
}

.bases-container {
  background: transparent linear-gradient(180deg, #f0f0f096 0%, #dedede 100%);
  box-shadow: 0px 3px 6px #00000012;
  border-radius: 10px;
  height: 100%;
  padding: 1.5rem;
  margin-bottom: 3rem;
  position: relative;
}

.bases-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-gray-1);
}

.bases-title__search {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--color-gray-1);
}

.bases-title--orange,
.bases-description--orange {
  color: var(--color-orange);
}

.bases-description {
  font-size: 0.9rem;
  font-weight: 400;
  text-align: justify;
  color: var(--color-gray-1);
  margin-top: 2rem;
}

.bases-btn {
  width: 100%;
  font-size: 1.5rem !important;
}

.bases-btn--dsk,
.bases-image--auto {
  display: none;
}

.mt-15 {
  margin-top: 15rem;
}

.bases-description--text {
  color: var(--color-gray-1);
  font-size: 1.1rem;
  text-align: justify;
  padding: 0 0 4rem 0;
  margin-top: 10rem;
}

.bases-link {
  color: var(--color-orange);
  text-decoration: none;
  margin-left: 1rem;
}

.bases-link:hover {
  text-decoration: underline;
}

.btn-close__icon {
  width: 100%;
  margin-bottom: 1rem;
  margin-top: -2rem;
}

.btn-close__icon img {
  width: 15px;
  cursor: pointer;
}

.text-underline {
  text-decoration: underline !important;
}

.btn-cotizar {
  margin-top: 3rem !important;
  margin-bottom: 0.75rem;
}

.title-logo {
  width: 50%;
  margin-left: 0;
  height: 100%;
  object-fit: cover;
}

.header-title-text {
  color: #fff;
  text-align: left;
  font-size: 1.3rem;
  padding-top: 1rem;
}

.text-logo {
  text-align: left;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
}

.background-moto {
  position: relative;
  background: #0180a3;
}

.background-moto__photo,
.background-moto__photodsk {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}

.background-moto__photodsk {
  display: none;
}

.background-moto__absolute {
  background-image: url("../assets/images/concourse/background-transparent-mobile.png");
  position: absolute;
  height: 55%;
  /* border: 1px solid red; */
  left: 0;
  right: 0;
  bottom: 0;
  background-size: contain;
}

.logo-title {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 49px;
  min-height: 134px;
}

.logo-title img {
  width: 63%;
  object-fit: contain;
}

.main-container {
  width: 100%;
}

.container-logos {
  display: flex;
  flex-direction: column;
}

.text-base a {
  display: block;
}

.options-btn__image--take {
  width: 23px;
  height: 16px;
}

@media only screen and (min-width: 350px) {
  .main {
    padding: 2rem 1rem 5rem;
  }

  .title {
    font-size: 2.2rem;
  }

  .subtitle {
    font-size: 0.95rem;
  }

  .header-title {
    font-size: 2.6rem;
  }

  .text-logo {
    font-size: 1.5rem;
  }

  .header-title-text {
    font-size: 1.5rem;
  }

  .header-subtitle,
  .btn-main {
    font-size: 1.4rem;
  }

  .step-subtitle {
    font-size: 1.25rem;
  }

  .main-concourse {
    height: 1310px;
  }

  .bases-title {
    font-size: 1.8rem;
  }

  .bases-title__search {
    font-size: 2rem;
  }

  .bases-description--text {
    margin-top: 15rem;
  }
}

@media only screen and (min-width: 370px) {
  .logo-title img {
    width: 64%;
  }

  .image-container__moto img {
    width: 64%;
  }
}

@media only screen and (min-width: 385px) {
  .join-title {
    font-size: 2.2rem;
  }

  .item-description {
    line-height: 1.9rem;
    font-size: 1.6rem;
  }

  .options-btn__text {
    font-size: 1.4rem;
  }

  .options-btn {
    width: 150px;
  }

  .options-btn__image {
    width: 29px;
    height: 25px;
  }

  .options-btn__image--take {
    width: 23px;
    height: 19px;
  }

  .main-concourse {
    height: 1340px;
  }

  .bases-title__search {
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: 400px) {
  .main-concourse {
    height: 1353px;
  }
}

@media only screen and (min-width: 414px) {
  .main {
    padding: 2rem 1rem 2rem;
    width: 100%;
    margin: 0 auto;
  }

  .modal-400 {
    width: 400px !important;
  }

  /**.logo{
	    width: 350px;
	    margin: 2rem auto 0;
	}**/

  .main-photo {
    width: 320px;
  }

  .header-title {
    font-size: 2.1rem;
  }

  .header-subtitle {
    font-size: 1.7rem;
  }

  .btn-main {
    font-size: 1.7rem !important;
    width: 342px;
  }

  .title-conditional {
    font-size: 2.5rem;
  }

  .step-item {
    height: 135px;
    width: 100%;
  }

  .step-subtitle {
    font-size: 1.6rem;
  }

  .main-concourse {
    height: 1328px;
  }

  .bases-title {
    font-size: 2rem;
  }

  .bases-container {
    padding: 3.5rem;
  }

  .bases-title__search {
    font-size: 2.8rem;
  }
}

@media only screen and (min-width: 600px) {
  .main-concourse {
    height: 1280px;
  }

  .header-title {
    font-size: 2.2rem;
  }

  .bases-description--text {
    margin-top: 23rem;
  }
}

@media only screen and (min-width: 768px) {
  .background-moto__photo {
    display: none;
  }
  .background-moto__photodsk {
    display: block;
  }
  .container-logos {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 50px;
  }

  .text-base {
    font-size: 1.3rem;
    width: 100%;
    text-align: left;
  }

  .text-base a {
    display: inline-block;
  }

  .footer {
    display: none;
  }

  .text-base {
    margin: 6rem auto 0 !important;
  }

  .background-moto {
    position: relative;
  }

  .background-moto__absolute {
    background-image: url("../assets/images/concourse/background-transparent.png");
    position: absolute;
    height: 50%;
    /* border: 1px solid red; */
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
  }

  .main-container {
    width: 70%;
  }

  .join-title {
    font-size: 2.5rem;
  }

  .item-description {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  .options-btn {
    justify-content: flex-start;
    width: 200px;
  }

  .options-btn__image {
    width: 39px;
    height: 29px;
  }

  .options-btn__image--take {
    width: 35px;
    height: 23px;
  }

  .main {
    padding: 0rem 1rem 2rem;
  }

  .options-btn__text {
    font-size: 1.6rem;
    margin-left: 1rem;
  }

  .image-container__moto {
    margin-bottom: 0;
  }

  .logo-title {
    width: 200px;
  }

  .logo-title img {
    width: 100%;
  }

  .title-conditional {
    margin-top: 0;
  }

  .image-container__moto img {
    width: inherit;
  }

  .text-logo {
    font-size: 1.5rem;
    font-weight: 600;
    padding-left: 5rem;
    margin-top: 3rem;
  }

  .container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .step-item {
    height: 130px;
    padding: 1rem 1.5rem 0;
    width: 335px;
  }

  .step-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 5rem;
  }

  .step-number {
    font-size: 9rem;
    top: -6rem;
  }

  .main-header {
    flex-direction: row;
    margin-top: 3rem;
  }

  .main-photo__d {
    display: block;
    width: 50%;
  }

  .main-photo__item {
    width: 100%;
    height: 350px;
  }

  .main-photo__item img {
    height: 80%;
    object-fit: cover;
  }

  .main-info_d {
    width: 50%;
  }

  .header_container {
    width: 100%;
    margin: 3rem 0;
  }

  .btn-main {
    width: 250px;
    font-size: 1.3rem !important;
    height: 50px;
  }

  .header-subtitle {
    font-size: 1.3rem;
  }

  .main-logo {
    width: 210px;
  }

  .header_btn {
    text-align: left;
  }

  .main-concourse {
    max-height: 940px;
  }

  .title-conditional {
    font-size: 3.5rem;
  }

  .bases-image {
    display: none;
  }

  .bases-title {
    font-size: 2.4rem;
  }

  .bases-btn {
    width: 250px;
    height: 45px;
    font-size: 2rem !important;
  }

  .bases-container {
    padding: 2rem;
    display: flex;
    flex-direction: row;
    min-height: 200px;
    margin-top: 6rem;
  }

  .bases {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .bases-btn--dsk {
    width: 45%;
    display: block;
  }

  .bases-image--dsk {
    width: 300px;
    margin-top: -7rem;
  }

  .bases--order {
    align-items: flex-end;
    padding-top: 2rem;
  }

  .bases-image--auto {
    width: 350px;
    position: absolute;
    top: -7rem;
    height: 250px;
    left: 0rem;
    display: block;
  }

  .bases-image--auto img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .bases-description--text {
    font-size: 1.3rem;
    margin-top: 6rem;
  }

  /**.logo{
	    margin: 2rem 0 0;
	}**/

  .bases-title__search {
    font-size: 3rem;
  }

  .item-image {
    width: 25px;
    height: 25px;
    padding: 1.1rem;
    margin-top: 0.35rem;
  }

  .item-image p {
    font-size: 1.5rem;
  }

  .options {
    justify-content: flex-start;
    gap: 5rem;
  }
}

@media only screen and (min-width: 992px) {
  .main-container {
    width: 50%;
    margin-left: 4rem;
  }

  .main-photo__item {
    height: 400px;
  }

  .header_container {
    width: 400px;
  }

  .header-title {
    font-size: 2.7rem;
    line-height: 3.5rem;
  }

  .header-subtitle {
    font-size: 1.6rem;
  }

  .step-item {
    height: 130px;
    padding: 1rem 1rem 0;
    width: 285px;
  }

  .step-subtitle {
    font-size: 1.4rem;
  }

  .step-container {
    justify-content: space-around;
    width: 900px;
    margin: 6rem auto;
  }

  .main-concourse {
    max-height: 805px;
  }

  .bases-title {
    font-size: 2.3rem;
  }

  .bases-image--auto {
    width: 450px;
    top: -5rem;
    left: -1rem;
  }

  /**.logo{
	    margin: 2rem 3rem 0;
	}**/

  .bases-title__search {
    font-size: 3.5rem;
  }
}

@media only screen and (min-width: 1200px) {
  .bases-description--text {
    margin-top: 19rem;
  }

  .text-base {
    font-size: 1.4rem;
  }

  .logo-title {
    width: 250px;
    margin-top: 40px;
  }

  .image-container__moto {
    width: 300px;
  }

  .image-container__moto img {
    width: 150%;
  }

  .join-title {
    font-size: 3.5rem;
  }

  .item-description {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .options-btn {
    width: 220px;
  }

  .options-btn__text {
    font-size: 2rem;
    margin-left: 1rem;
  }

  .background-moto {
    background-position: top center;
  }

  .text-logo {
    padding-left: 5.5rem;
  }

  .header-title {
    font-size: 4rem;
    line-height: 4.5rem;
  }

  .header-title-text {
    font-size: 2.6rem;
    line-height: 3.5rem;
  }

  .header_container {
    width: 515px;
    margin: 3rem 0;
  }

  .header-subtitle {
    font-size: 1.7rem;
    margin-top: 2rem;
  }

  .main-photo__item {
    height: 550px;
  }

  .btn-main {
    width: 350px;
    font-size: 1.7rem !important;
    height: 50px;
  }

  .main-concourse {
    min-height: 880px;
  }

  .step-container {
    justify-content: space-around;
    min-width: 900px;
    margin: 6rem auto;
    width: 100%;
  }

  .step-item {
    width: 340px;
    height: 148px;
    padding: 1rem 3rem 1rem;
  }

  .step-title {
    font-size: 2.2rem;
  }

  .title-conditional {
    font-size: 5rem;
  }

  .step-subtitle {
    font-size: 1.7rem;
  }

  .bases-container {
    padding: 3rem;
    margin-top: 10rem;
  }

  .bases-title {
    font-size: 2.7rem;
  }

  .bases-image--auto {
    width: 500px;
  }

  .bases-description {
    font-size: 1.2rem;
  }

  .bases-description--text {
    font-size: 1.5rem;
    padding: 3rem 0 9rem;
  }

  .bases-image--dsk {
    width: 350px;
    margin-top: -9rem;
  }

  /**.logo {
		width: 450px;
		margin: 2rem 1.5rem 0;
	}**/

  .bases-title__search {
    font-size: 4rem;
  }
}
