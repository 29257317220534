.blog-card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 2.2rem;
}

.blog-card__image {
	height: 150px;
    width: 100%;
}

.blog-card__image .card-image {
	height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.blog-card__description {
    width: 100%;
}

.blog-card__title {
    font-size: 1.8rem;
    color: var(--color-orange);
    font-weight: 600;
    margin-top: 1rem;
    text-align: left;
}

.blog-news {
    height: 270px;
}

.blog-news .swiper-wrapper .swiper-slide {
    height: 100%!important;
}

.blog-card__image .card-image__spinner {
    height: 95%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.services-blog {
	height: 360px;
}

.title-main__blog {
	font-size: 1.4rem;
    color: var(--color-gray-2);
    font-weight: 600;
	margin-top: 1rem;
}

.subtitle-author {
    color: var(--color-orange);
    border-right: 1px solid #8c8c8c;
    padding-right: .5rem;
    padding-left: .5rem;
}

.description-main__blog {
    font-size: 1.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 21px;
}

.subtitle-date {
	border-right: 1px solid #8c8c8c;
	padding-right: .5rem;
	padding-left: .5rem;
	margin-right: .5rem;
}

.subtitle-category {
	color: var(--color-orange);
}

.subtitle-main__blog {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.7rem;
    height: 35px;
    margin-bottom: 0;
}

.blog__text .blog__info--link {
	color: var(--color-dark-gray);
}

.blog__text {
	height: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.blog__text--link {
	font-size: 1.3rem;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    color: var(--color-primary);
    text-decoration: none;
    margin-top: 1rem;
    align-self: flex-end;
    margin-bottom: 0;
}

.blog__text--link:hover {
	text-decoration: none;
}

.services-blog .swiper-slide {
	height: 100%!important;
}

.blog__container {
	margin-top: 45px;
}

@media only screen and (min-width: 370px) {
    .blog-card__image {
        height: 180px;
    }

    .blog-news {
        height: 290px;
    }
}

@media only screen and (min-width: 410px) {
    .blog-news {
        height: 330px;
    }

    .blog-card__image {
        height: 200px;
    }

    .blog-card__title {
        font-size: 2rem;
    }
        
}

@media only screen and (min-width: 600px) {
    .blog-news {
        height: 380px;
    }

    .blog-card__image {
        height: 285px;
    }


    .blog-card__title {
        font-size: 1.6rem;
    }
        
}

@media only screen and (min-width: 768px) {
    .blog-news {
        width: 100%;
        height: 300px;
    }

    .services-blog {
        width: 100%;
    }

    .blog-news .swiper-wrapper {
        width: 100%;
    }

    .blog-news .swiper-wrapper .swiper-slide, .services-blog .swiper-wrapper .swiper-slide {
        width: 47%!important;
        margin-right: 3rem!important;
    }

    .blog-card__image {
        height: 200px;
    }

    .blog-card__title {
        font-size: 1.7rem;
    }

    .title-main__blog {
        font-size: 1.7rem!important;
        line-height: 2.5rem;
        margin-top: 2rem;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 80px;
        margin-bottom: 0;
	}

    .description-main__blog {
		font-size: 1.3rem;
    	text-align: justify;
	}

    .blog__text  {
		height: 350px;
	}

    .blog__container {
		display: flex;
		justify-content: space-between;
	}
}

@media only screen and (min-width: 992px) {
    .blog-news {
        height: 350px;
    }

    .blog-news .swiper-wrapper .swiper-slide, .services-blog .swiper-wrapper .swiper-slide {
        width: 31%!important;
        margin-right: 3rem!important;
    }

    .blog-card__image {
        height: 160px;
    }

    .blog-card__title {
        font-size: 1.9rem;
    }

    .services-blog {
        height: 350px;
    }

    .title-main__blog {
		font-size: 1.9rem!important;
	}
}

@media only screen and (min-width: 1200px) {
    .blog-news {
        height: 320px;
    }

    .blog-card__image {
        height: 185px;
    }

    .blog-card__title {
        font-size: 2rem;
    }

    .services-blog {
        height: 400px;
    }

    

    .title-main__blog {
		font-size: 2.1rem!important;
		line-height: 3rem;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 123px;
	}

    .description-main__blog {
		font-size: 1.4rem;
	}

    .blog__text {
	    width: 100%;
	}

	.blog__text--link {
		font-size: 1.6rem;
	}

}