.banner-moto__main {
	height: 80px;
	padding: 0;
	margin: 0 -15px 0 -15px;
}

.banner-moto__main img, .banner-moto__type img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.banner-moto__type {
	height: 80px;
	padding: 0;
	margin: 0 -15px 0 -15px;
}

.banner-moto__find {
	height: 350px;
}

 .banner-moto__find img {
    height: 100%;
    object-fit: contain;
    width: 100%;
}

.moto-result {
	width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 2rem;
}

.moto-result img {
	height: 100%;
    object-fit: contain;
    width: 100%;
}

.electrical-one {
	margin-left: -15px;
    margin-right: -15px;
    height: 88px;
}

.electrical-one img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.electrical-two {
	height: 290px;
}

.electrical-two img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media only screen and (min-width: 414px) {
	.electrical-one {
		height: 100px;
	}

	.electrical-two {
		height: 350px;
	}
}

@media only screen and (min-width: 500px) {
	.electrical-one {
		height: 130px;
	}

	.electrical-two {
		height: 500px;
	}
}

@media only screen and (min-width: 768px) {
	.banner-moto__main, .banner-moto__type {
		height: 140px;
	}

	.electrical-one {
		height: 105px;
	}

	.electrical-two {
		height: 240px;
	}
}

@media only screen and (min-width: 992px) {
	.electrical-one {
		height: 135px;
	}

	.electrical-two {
		height: 360px;
	}
}

@media only screen and (min-width: 1200px) {
	.banner-moto__main, .banner-moto__type {
		height: 165px;
	}

	.electrical-one {
		height: 165px;
	}

	.electrical-two-two {
		height: 400px;
	}
}