.panel {
	background: var(--color-white);
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 6px;
	min-height: 320px;
	margin-bottom: 2rem;
	padding-bottom: 2rem;
}

.group {
	padding: 0 1rem;
}

.group__item {
	display: flex;
	flex-direction: row;
}

.group__item-type {
	width: 67px;
	height: 18px;
	background: var(--color-orange);
	border-radius: 5px;
	font-weight: 400;
	font-size: 1.2rem;
	color: var(--color-white);
	margin-bottom: .5rem;
	text-align: center;
}

.group__item-title {
	color: var(--color-gray-5);
	font-size: 1.1rem;
	font-weight: 600;
	margin-bottom: 0;
}

.group__item-price {
	color: var(--color-gray-1);
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 0;
}

.group__item-taxes {
	font-size: .8rem;
}

.group__item-brand {
	width: 30%;
}

.group__item-image {
	width: 100%;
}

.features-item__icons {
	height: 100%;
	width: 17px;
}

.features-item__icon {
	height: 12px;
	width: 12px;
}

.features-item__image {
	width: 100%;
    height: 100%;
}

.group__features {
	display: flex;
	flex-direction: row;
	margin-top: 1rem;
	justify-content: space-between;
	margin-bottom: 2rem;
}

.features-item {
	display: flex;
	flex-direction: row;
	border-right: 1px solid rgb(33 37 41 / 10%);
    padding-right: 0.5rem;
}

.features-item:last-child {
	border-right: none;
	padding-right: 0;
}

.features-item:not(:first-of-type) {
	margin-left: 0.5rem;
}

.features-item-title {
	color: var(--color-gray-1);
	font-size: .75rem;
	font-weight: 400;
	margin-bottom: 0;
}

.features-item-value {
	color: var(--color-gray-5);
	font-size: .75rem;
	font-weight: 400;
	margin-bottom: 0;
	margin-top: -0.1rem;
}

.group__item-description {
	width: 85%;
}

.group__footer-link {
	color: var(--color-orange);
	font-size: 1.4rem;
	font-weight: 400;
	text-decoration: underline;
}

.group__footer-link:hover {
	color: var(--color-orange);
}

.group__footer-link:after {
	content: url("../assets/images/icons/share-orange.svg");
	width: 23px;
    height: 23px;
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: middle;
}

.group__footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.group__footer-item {
	width: 50%;
	text-align: center;
}

.group__footer-btn {
	width: 100%;
	font-size: 1.5rem!important;
}

.disclaime-price {
	margin-bottom: 2rem;
    font-size: .8rem;
    color: var(--color-gray-5);
    text-align: justify;
}

@media only screen and (min-width: 350px) {
	.features-item-title, .features-item-value {
		font-size: .9rem;
	}

	.group__item-title, .group__item-price {
	    font-size: 1.25rem;
	}

	.group__item-taxes {
		font-size: 1rem;
	}
}

@media only screen and (min-width: 370px) {
	.features-item-title, .features-item-value {
		font-size: 1rem;
	}

	.group__item-title, .group__item-price {
	    font-size: 1.35rem;
	}

	.group__item-taxes {
		font-size: 1.1rem;
	}
}

@media only screen and (min-width: 414px) {
	.features-item-title, .features-item-value {
		font-size: 1.2rem;
	}

	.features-item__icon {
	    height: 15px;
	    width: 15px;
	    margin-top: 0.35rem;
	}

	.features-item__icons {
	    margin-right: 0.25rem;
	}

	.group__features {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}

	.group__item-title, .group__item-price {
	    font-size: 1.5rem;
	}

	.group__item-taxes {
		font-size: 1.2rem;
	}

	.group__item-type {
	    font-size: 1.3rem;
	}

	.group__footer-btn {
		font-size: 2rem;
	}

	.group__footer-link {
		font-size: 1.6rem!important;
	}

	.group {
		padding: 0 1.4rem;
	}
}

@media only screen and (min-width: 500px) {
	.group {
	    padding: 0 2rem;
	}
}