.page-car__section_1 {
  display: flex;
  flex-direction: column;
}

.panel-detail__car {
  border: 0;
}

.mySwiperMain {
  height: 100%;
  width: 100%;
}

.carousel-thumb {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
}

.mySwiperMain .swiper-button-next:after,
.mySwiperMain .swiper-button-prev:after {
  font-size: 2rem;
}

.mySwiperMain .swiper-slide {
  height: 243px;
}

.mySwiperMain .swiper-slide img {
  width: 100%;
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.mySwiperSecondary {
  height: 80px;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiperSecondary .swiper-slide {
  width: 25%;
  height: 100%;
  box-shadow: 0px 0px 4px #0000001f;
  border-radius: 6px;
  opacity: 0.4;
  border-radius: 6px;
}

.mySwiperSecondary .swiper-slide img {
  border-radius: 6px;
  width: 100%;
}

.mySwiperSecondary .swiper-slide-thumb-active {
  opacity: 1;
}

.form-contact__car {
  background: var(--color-soft-gray);
  border: 0;
  margin-top: 3rem;
}

.form-custom__button {
  width: 100%;
  font-size: 2rem;
  padding: 0.5rem 1rem;
}

.panel-detail__car {
  margin-top: 1.5rem;
}

.car-feature--details {
  width: 80%;
}

.info-car {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page-car__section_2 {
  display: flex;
  flex-direction: column;
}

.car-service {
  display: flex;
  flex-direction: column;
}

.page-car {
  margin-right: auto;
  margin-left: auto;
}

.accordion-car__row {
  margin-left: -15px;
  margin-right: -15px;
}

.banner_up_right {
  height: 393px;
  display: none;
}

.banner_up_right img {
  width: 100%;
  height: 100%;
}

.file-to-car {
  border: 0;
  box-shadow: none;
}

.details-car {
  padding-left: 2rem;
}

.accordion-car {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 4.5rem;
}

.details-car__group {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.details-car--title {
  font-size: 1.5rem;
  font-weight: normal;
  color: var(--color-gray-5);
  width: 50%;
  margin-bottom: 0;
}

.accordion .card {
  border-left: 0;
  border-right: 0;
  padding: 0 2rem;
}

.accordion .card:first-child {
  border-top: 0;
}

.accordion .card-header {
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--color-gray-1);
  background-color: var(--color-white);
  border-bottom: 0;
  cursor: pointer;
}

.breadcrumb__container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 2rem;
}

.carousel-car {
  position: relative;
}

.favorite-car {
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 2rem;
}

.share-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 0;
  padding-top: 2rem;
  width: 100%;
  z-index: 100;
  padding-bottom: 1.5rem;
  position: relative;
}

.carousel-thumb .share-container {
  padding-right: 2rem;
}

.favorite-car img {
  width: 25px;
  height: 25px;
}

.my-route__icon--gray {
  color: var(--color-black);
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 0;
}

.my-route__icon--orange {
  color: var(--color-orange);
  font-weight: 400;
  font-size: 1.4rem;
  margin-bottom: 0;
}

.favorite-car p:hover {
  color: var(--color-orange);
}

.my-route__icon--gray::before {
  content: url("../../images/my-route/icon-favorite.svg");
  width: 20px;
  height: 17px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.7rem;
  margin-top: -0.5rem;
}

.my-route__icon--orange::before,
.my-route__icon--gray:hover::before {
  content: url("../../images/my-route/icon-favorite-orange.svg");
  width: 20px;
  height: 17px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.7rem;
  margin-top: -0.5rem;
}

.padding-body {
  padding: 1.25rem 1.5rem;
}

.page-car {
  font-family: "Poppins", sans-serif;
  margin-bottom: 3rem;
  margin-top: 2rem;
}

.car-service-button--arrow {
  content: url("../../images/icons/down-arrow-mobile.svg");
  width: 17px;
  height: 11px;
  padding-left: 0.5rem;
  vertical-align: middle;
  display: inline-block;
}

.page-car__title {
  font-size: 2.7rem;
  font-weight: 600;
  color: var(--color-gray-1);
}

.info-car {
  font-family: "Poppins", sans-serif;
  width: 100%;
}

.info-car__description {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-car__description--type {
  width: 67px;
  height: 18px;
  background: var(--color-orange);
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: normal;
  color: var(--color-white);
  text-align: center;
}

.info-car__description--modelo {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-gray-5);
  margin-bottom: 0;
}

.container-info-row {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.info-car__description--price {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-gray-1);
}

.info-car__marca {
  width: 72px;
  height: 54px;
  align-self: flex-start;
  cursor: pointer;
}

.info-car__marca img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.infor-car__share--link {
  text-decoration: underline;
  font-size: 1.2rem;
  font-weight: normal;
  color: var(--color-orange);
}

.infor-car__share {
  text-align: right;
}

.infor-car__share--link:after {
  content: url("../../images/icons/share-orange.svg");
  width: 23px;
  height: 23px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 1rem;
}

.infor-car__share--link:hover {
  color: var(--color-orange);
}

.car-features {
  display: flex;
  flex-direction: row;
  width: 30%;
  flex-wrap: wrap;
  border-right: 1px solid rgb(33 37 41 / 20%);
}

.car-features:last-child {
  border-right: none;
}

.info-car-features {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  margin-top: 1rem;
}

.car-features__imagen {
  width: 16px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
}

.car-features__imagen--km {
  content: url("../../images/interests/naranja/fuel-level-meter_1_.svg");
}

.car-features__imagen--transmision {
  content: url("../../images/interests/naranja/transmission.svg");
}

.car-features__imagen--combustible {
  content: url("../../images/interests/naranja/gas-station.svg");
}

.moto-features__imagen--displacement {
  content: url("../../images/motorcycle/icon-displacement.png");
}

.moto-features__imagen--engine {
  content: url("../../images/motorcycle/icon-engine.png");
}

.moto-features__imagen {
  width: 17px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 2rem;
}

.car-feature__title {
  font-size: 0.8rem;
  font-weight: normal;
  color: var(--color-gray-1);
  margin-bottom: 0;
}

.car-feature__value {
  font-size: 1rem;
  font-weight: normal;
  color: var(--color-gray-5);
  margin-bottom: 0;
}

.car-banner {
  margin-top: 0;
  margin-bottom: 0;
}

.mapa-local {
  position: relative;
  margin-top: -3rem;
}

.mapa-local svg {
  overflow: hidden;
  vertical-align: middle;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  border: 0;
}

.mapa-local path {
  fill: #d3a68a7a;
}

.mapa-local path:focus,
.mapa-local path.locals:focus {
  fill: #f47422;
}

.mapa-local path[aria-checked="true"],
.mapa-local path.locals[aria-checked="true"] {
  fill: #f47422;
}

.mapa-local path[aria-current="true"],
.mapa-local path.locals[aria-current="true"] {
  fill: #f47422;
}

/*******Servicios****************************/
.car-service {
  width: 100%;
}

.car-service__image {
  width: 100%;
  height: 156px;
}

.car-service__image img {
  width: 100%;
  object-fit: cover;
}

.car-service__title_1 {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--color-white);
  margin-top: -8rem;
  text-align: center;
}

.car-service__title_2 {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--color-white);
  text-align: left;
}

.car-service__description {
  padding: 1rem 1.5rem 0 1.5rem;
}

.car-service__title {
  font-size: 1.4rem;
  font-weight: normal;
  color: var(--color-gray-5);
}

.car-service-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  cursor: pointer;
}

.car-service-button--image {
  width: 55px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.car-service-button--image img {
  width: 100%;
}

.car-service-button--text {
  width: 70%;
}
.car-service-button--title {
  font-size: 1rem;
  font-weight: normal;
  color: var(--color-gray-1);
  margin-bottom: 0;
}

.car-service-button--subtitle {
  font-size: 0.9rem;
  font-weight: normal;
  color: var(--color-gray-5);
  margin-bottom: 0;
}

/**.car-service-button--arrow {
	background: url('../../images/down-arrow-details.svg') no-repeat;
	width: 7px;
	height: 14px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 1rem;
}

.car-service-button:nth-child(6) {
	border-bottom: 0;
}**/

.col_featured--car {
  background: linear-gradient(#fff 60%, #f0f0f0 30%);
}

.tag_container--car {
  width: 239px;
  margin-top: 3rem;
  font-size: 2.4rem;
  padding-left: 2rem;
}

.card__feature--car {
  border-radius: 0;
}

/*******Formulario Custom*****************/

.form-custom {
  font-family: "Poppins", sans-serif;
}

.form-custom__title {
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--color-gray-1);
  margin-bottom: 2.5rem;
  margin-top: 1.5rem;
}

.form-custom__group {
  position: relative;
  width: 48%;
}

.form-custom__group label {
  font-size: 1.2rem;
  font-weight: normal;
  color: var(--color-gray-1);
  position: absolute;
  padding-left: 1rem;
  padding-top: 0.5rem;
}

.form-custom__input {
  height: 50px;
  background: var(--color-white);
  border: 1px solid var(--color-gray-9);
  border-radius: 5px;
  padding-top: 2rem;
  padding-left: 1rem;
  font-size: 1.2rem;
  font-weight: normal;
}

.form-custom__input:focus {
  color: var(--color-gray-5);
  background-color: var(--color-white);
  box-shadow: 0px 3px 6px #f474221d;
  border: 0.4000000059604645px solid #f47422;
  outline: 0;
}

.form-custom__row {
  display: flex;
  justify-content: space-between;
}

.form-custom--label {
  font-size: 1rem;
  font-weight: normal;
  color: var(--color-gray-5);
  cursor: pointer;
}

.form-custom__type {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}

.form-custom--checkbox {
  width: 15px;
}

.form-custom--label a {
  text-decoration: underline;
  color: inherit;
}

.form-custom--check {
  background: var(--color-white);
  border: 1px solid var(--color-gray-3);
  border-radius: 1px;
  width: 15px;
  height: 15px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
}

.form-custom--check:focus {
  outline: none;
}

.form-custom--check:checked {
  background: var(--color-orange);
  border: none;
}

.form-custom--check:checked::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 3px;
  width: 12px;
  height: 2px;
  background: var(--color-white);
  transform: rotate(-45deg);
}
.form-custom--check:checked::after {
  content: "";
  position: absolute;
  top: 9px;
  left: 2px;
  width: 4px;
  height: 2px;
  background: var(--color-white);
  transform: rotate(45deg);
}

.accordion .card {
  margin-left: 0;
}

/***********************Resultado de búsqueda******************************************/
.options-search {
  position: relative;
  background: var(--color-soft-gray);
}

.options-search__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
}

.options-search__type {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  color: var(--color-gray-1);
  margin-bottom: 0;
  cursor: pointer;
}

.options-search--filter:before {
  content: url("../../images/icons/filter-black.svg");
  display: inline-block;
  vertical-align: middle;
  height: 18px;
  width: 18px;
  margin-right: 0.5rem;
}

.options-search--order:before {
  content: url("../../images/icons/ordenar-black.svg");
  display: inline-block;
  vertical-align: middle;
  height: 18px;
  margin-right: 0.5rem;
}

.options-sort {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-white);
  position: absolute;
  left: 0;
  right: 0;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 100000;
  height: 414px;
}

.options-filter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: var(--color-white);
  position: absolute;
  left: 0;
  right: 0;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 100000;
  min-height: 592px;
  padding-bottom: 2rem;
}

.options-sort__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  padding-left: 2rem;
  padding-right: 3rem;
  border-bottom: 1px solid rgb(33 37 41/20%);
}

.options-sort__header img {
  cursor: pointer;
}

.options-sort__header p {
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-gray-1);
  display: flex;
  align-items: center;
}

.filter-menu__btn {
  text-align: center;
  margin-top: 9rem;
}

.options-sort__menu {
  width: 100%;
}

.options-filter__header span {
  background: url("../../images/icon/down-arrow-filter.svg") no-repeat;
  margin-right: 1rem;
  transform: rotate(180deg);
  width: 22px;
  height: 15px;
  cursor: pointer;
  margin-left: -1.5rem;
}

.options-filter__item .search_type_price,
.options-filter__item .search-advanced__details {
  width: 90%;
}

.options__return {
  display: flex;
  align-items: center;
  justify-content: center;
}

.options-filter__menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
  cursor: pointer;
}

.options-sort__menu p,
.options-filter__menu p {
  height: 46px;
  display: flex;
  font-size: 1.4rem;
  font-weight: normal;
  margin-bottom: 0;
  color: var(--color-gray-5);
  cursor: pointer;
  align-items: center;
  margin-left: 3rem;
}

.options-filter__menu span {
  background: url("../../images/icon/down-arrow-filter.svg");
  background-repeat: no-repeat;
  width: 22px;
  height: 15px;
  cursor: pointer;
}

.options-filter__item {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
}

.options-filter__item .search__type--title {
  margin-bottom: 3rem;
}

.options-filter__item .search_type_details {
  justify-content: flex-start;
}

.options-filter__item .search-normal__container {
  margin-right: 2rem;
}

.options-sort__menu:hover {
  background-color: var(--color-soft-orange);
}

.options-sort__menu:hover > p {
  color: var(--color-orange);
}

.search-programada--return {
  display: block;
}

.filter__container {
  min-height: 200px;
  width: 100%;
  padding: 1rem 1.5rem;
  background: var(--color-soft-gray);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
}

.form-filter-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.form__filter-label {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  padding-left: 0.25rem;
  font-weight: normal;
  color: var(--color-gray-1);
  cursor: pointer;
}

.form__filter-check:focus {
  outline: none;
}

.form__filter-check:checked {
  background: var(--color-orange);
  border: none;
}

.form__filter-check:checked::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 3px;
  width: 12px;
  height: 2px;
  background: var(--color-white);
  transform: rotate(-45deg);
}
.form__filter-check:checked::after {
  content: "";
  position: absolute;
  top: 9px;
  left: 2px;
  width: 4px;
  height: 2px;
  background: var(--color-white);
  transform: rotate(45deg);
}

.form__filter-check {
  background: var(--color-white);
  border: 1px solid var(--color-gray-3);
  border-radius: 1px;
  width: 15px;
  height: 15px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
  border: 0.5px solid var(--color-gray-6);
}

.form-filter__title,
.filter__item .search__type--title {
  font-family: "Poppins", sans-serif;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--color-gray-1);
}

.filter__item {
  border-bottom: 1px solid rgb(244 116 34 / 42%);
  padding-bottom: 1.5rem;
  padding-top: 2rem;
}

.filter__item:last-child {
  border-bottom: none;
}

.filter__button {
  width: 100%;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.filter__item .search-advanced_form {
  width: 100%;
  margin-bottom: 1.5rem;
}

.filter__item .search-advanced_form .search-advanced_form__control {
  height: 30px;
}

.option-filter__container {
  background: var(--color-white);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.option-filter__container .option-item-text {
  font-size: 1rem;
}

.option-filter__title {
  font-family: "Poppins", sans-serif;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-gray-1);
}

.option-filter__item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.option-filter-clear {
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  cursor: pointer;
  color: var(--color-orange);
  margin-bottom: 0.5rem;
}

.filter__button button {
  padding: 0.375rem 1rem;
  font-size: 1.1rem;
}

.option-filter__link {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 2rem;
}

.form-filter__container {
  max-height: 250px;
  overflow-x: hidden;
}

.form-filter__container::-webkit-scrollbar,
.marca-list::-webkit-scrollbar {
  width: 10px;
}

.form-filter__container::-webkit-scrollbar-track,
.marca-list::-webkit-scrollbar-track {
  background-color: var(--color-white);
  border-radius: 5px;
  box-shadow: none;
}

.form-filter__container::-webkit-scrollbar-thumb,
.marca-list::-webkit-scrollbar-thumb {
  background: #8a8b8d;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
}

.form-filter__container p {
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  cursor: pointer;
  color: var(--color-gray-3);
}

.input-brand--filter {
  height: 29px;
  font-size: 1rem;
  padding-left: 3.5rem;
}

.input-filter--icon {
  background: url("../../images/icon/search-brand-filter.svg");
  background-repeat: no-repeat;
  width: 22px;
  height: 15px;
  position: absolute;
  top: calc(50% - 7px);
  left: 12px;
}

.range-price__value {
  background: var(--color-white);
  box-shadow: 0px 3px 6px #00000012;
  border: 0.3px solid #f97e3b;
  border-radius: 5px;
  height: 29px;
  width: 80%;
  margin-top: 1rem;
}

.filter-price {
  display: flex;
  flex-direction: column;
}

.filter-price .input-range__track {
  background: var(--color-gray-3);
  height: 0.5rem;
}

.filter-price .input-range__slider {
  background: var(--color-orange);
  border: 1px solid var(--color-orange);
  width: 1rem;
  height: 1rem;
  margin-top: -0.8rem;
}

.filter-price .input-range__track--active {
  background: var(--color-orange);
  opacity: 1;
}

.banner-filter {
  height: 280px;
  width: 100%;
  margin-top: 1rem;
}

.banner-filter img {
  width: 100%;
}

.d-result-compare {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.d-result-compare img {
  width: 50%;
  cursor: pointer;
}

.d-result-compare p {
  height: 30px;
  background: var(--color-white);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  top: 1rem;
  right: -7rem;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--color-orange);
  position: absolute;
  top: 1rem;
  right: -18rem;
  width: 195px;
  text-align: center;
  cursor: pointer;
}

.d-result-compare__button {
  position: fixed;
  bottom: 1rem;
  cursor: pointer;
  display: none;
  box-shadow: 0px 3px 6px #f0f0f0;
  z-index: 10000;
  width: 80px;
  height: 80px;
  background: var(--color-orange);
  border-radius: 50%;
}

.d-result-route__button {
  right: 1rem;
  background: #ffe1ce;
  border-radius: 50%;
  bottom: 1rem;
  cursor: pointer;
  display: none;
  box-shadow: 2px 1px 10px 0px rgb(0 0 0 / 16%);
  z-index: 10000;
  width: 80px;
  height: 80px;
}

.d-result-route__button .d-result-compare p {
  width: 30px;
  right: 0;
  top: -1rem;
  color: #433229;
  font-weight: bold;
}

/**************Ordenar**************************/
.options-search__container {
  position: relative;
}

.card-sort {
  background: var(--color-white);
  border-radius: 0.7rem;
  box-shadow: 0px 3px 6px var(--color-gray-8);
  position: absolute;
  right: -40px;
  top: 33px;
  z-index: 10000;
  width: 150px;
}

.card-sort .options-sort__menu p {
  height: 35px;
}

.modal-content-spinner {
  display: block;
  background: none;
  background: rgb(255 255 255 / 80%);
}

.modal-spinner {
  display: block;
  background: none;
}

.modal-spinner .modal-content {
  border: none;
  background: none;
}

.modal-spinner .loading-car__text {
  font-size: 2rem;
  font-weight: normal;
  color: #000;
  margin-bottom: 3rem;
  text-align: center;
  margin-top: -10rem;
  width: 100%;
}

.modal-spinner .loading--tire {
  margin-top: -4rem;
}

.loading-result {
  overflow: hidden;
  margin: auto;
  outline: none;
}

.loading-result div[aria-label="animation"] {
  width: 100% !important;
}

.message-result {
  background: #feeee4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  height: 52px;
  width: 203px;
  position: absolute;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  right: 0;
  top: 5rem;
}

.message-result p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  font-weight: normal;
  color: var(--color-orange);
  margin-bottom: 0;
}

.message-result span {
  background: url("../../images/check.svg");
  background-repeat: no-repeat;
  width: 22px;
  height: 15px;
  margin-left: 1rem;
}

.price-car {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.map-concessionaire .file-to-car {
  border: 0;
  box-shadow: none;
  margin-bottom: 0;
}

.info-car__description--price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-car__description--price .price-financiamiento {
  color: var(--color-dark-gray);
  font-size: 0.9rem;
}

.info-car__view {
  margin-top: 2rem;
}

@media only screen and (min-width: 375px) {
  .message-result {
    width: 300px;
    height: 37px;
  }

  .info-car__description--price {
    font-size: 1.8rem;
  }

  .car-feature__title {
    font-size: 1rem;
  }

  .info-car__description--price .price-financiamiento {
    font-size: 1.1rem;
  }
}

@media only screen and (min-width: 768px) {
  .info-car__view {
    margin-top: 0;
  }

  .share-container {
    padding-bottom: 0;
  }
}
