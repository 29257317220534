.modal-result {
	font-family: 'Poppins', sans-serif;
}

.modal-result--header {
	font-size: 2rem;
	font-weight: bold;
	color: var(--color-white);
	text-transform: uppercase;
	margin-bottom: 3.5rem;
}

.modal-result .modal-content {
	background: var(--color-orange);
	border-radius: 0;
}

.modal-result__title {
	font-size: 1.5rem;
	font-weight: 500;
	color: var(--color-white);	
	text-align: center;
	margin-bottom: 4.8rem;
}

.modal-result__form{
	width: 282px;
	height: 55px;
	margin: 0 auto;
	border-radius: 5px;
	max-width: 100%;
}

.modal-result__form__input {
	border-radius: 5px;
}

.modal-result__form .form-control {
	height: 55px;
	font-size: 1.2rem;
	font-weight: normal; 
	color: var(--color-gray-10);
	border-radius: 5px 0px 0px 5px;
}

.modal-result__form .form-control:focus {
    border-color: var(--color-gray-10);
    box-shadow: none;
}

.modal-result__form .input-group-append {
	height: 55px;
	background: var(--color-dark-gray);
	border-radius: 0px 5px 5px 0px;
}

.input-group-append .btn-send-email {
	background: var(--color-dark-gray);
	border: 1px solid var(--color-dark-gray);
}

.btn-send-email  {
	border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.modal-result__footer button {
	margin-top: 6rem;
	text-decoration: underline;
	font-size: 2rem;
	font-weight: 400;
	color: var(--color-white);
	margin-bottom: 3rem;
}

.modal-result__footer button:focus {
	box-shadow: none;
}

.modal-result__footer button:hover {
	color: var(--color-white);
}


.search-programada{
	display: flex;
	align-items: center;
}

.search-programada:before {
	content: url('../../images/search-result.png');
	width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    margin-right: .3rem;

}

.search-programada__title {
	font-size: 1rem;
	font-weight: 500;
	font-family: 'Poppins', sans-serif;
	color: var(--color-gray-5);
	margin-bottom: 0;
}

.search-programada__small {
	text-decoration: underline;
	font-size: 1rem;
	font-weight: 600;
	font-family: 'Poppins', sans-serif;
	letter-spacing: 0px;
	color: var(--color-orange);
	border: none;
    background: transparent;
}

.search-programada__small:hover {
	color: var(--color-orange);
}

.search-result__title {
	font-family: 'Poppins', sans-serif;
	font-size: 1.8rem;
	font-weight: 500;
	color: var(--color-gray-1);
	margin-top: 2rem;
}

.search-result__small {
	font-family: 'Poppins', sans-serif;
	font-size: 1.2rem;
	font-weight: normal;
	color: var(--color-gray-5);
}

.result-details__slide {
	display: flex;
	height: 150px;
}

.slide-car {
	width: 100%;
}

.slider-route {
	width: 15%;
	text-align: right;
}

.slider-route img {
	width: 25px;
}

.slider-route p {
	font-size: .8rem;
	font-weight: normal;
	color: var(--color-orange);
	margin-top: .3rem;
	margin-bottom: 0;
}

.result-details__image {
	margin: 0 auto;
	height: 150px;
}

.slider-car--height{
	height: 150px;
}

.result-details__image  img {
	height: 100%;
	width: 100%;
	object-fit: scale-down;
}

.slider-car--height .swiper-slide {
	width: 100%!important;
}

.slider-car--height .swiper-pagination-bullet{
	width: 6px;
	height: 6px;
}

.slider-car--height>.swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 2px;
}

.slider-car--height .swiper-pagination-bullet.swiper-pagination-bullet-active {
	background: #000000;
	opacity: 0.11;
}

.slider-car--height .swiper-pagination-bullet{
	background: #000000;
	opacity: 0.04;
}

.result-details {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
}

.result-details__type {
	width: 67px;
	height: 18px;
	background: var(--color-orange);
	border-radius: 5px;
	font-size: 1.2rem;
	font-weight: normal;
	color: var(--color-white);
	text-align: center;
	line-height: 1.5rem;
	font-family: 'Poppins', sans-serif;
	margin-bottom: 1rem;
}

.result__description {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.result__description--modelo {
	font-size: 1.5rem;
	font-weight: 600;
	color: var(--color-gray-5);
	font-family: 'Poppins', sans-serif;
	margin-bottom: 0;
}

.result__description--price {
	font-size: 2rem;
	font-weight: 600;
	color: var(--color-gray-1);
	font-family: 'Poppins', sans-serif;
	margin-bottom: 0;
}

.result-adicional {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 2rem;
}

.result-adicional__title {
	font-size: 1rem;
	font-weight: normal;
	font-family: 'Poppins', sans-serif;
	color: var(--color-gray-1);
	margin-bottom: 0;
}

.result-adicional--image {
	width: 16px;
    height: 12px;
}

.result-adicional--image img {
	width: 100%;
}

.result-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.result-footer__comparar {
	display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.result-footer__comparar .search-form {
	height: 42px;
    border-top: 1px solid rgb(33 37 41 / 10%);
    border-left: 1px solid rgb(33 37 41 / 10%);
    padding-left: 1rem;
}

.modal-search .modal-content {
	padding: 0;
}

.modal-search .modal-content .card__container {
	box-shadow: none;
}

.result-foooter {
	background: var(--color-white);
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: 1000;
	left: 0;
    right: 0;
}

.container-btn-rc {
	display: flex;
    align-items: center;
}

.container-btn-rc .btn-lg {
	font-size: 1.5rem;
    padding: .5rem;
    margin-right: 1rem;
}

.result__container {
	margin-bottom: 7rem;
}

.banner-result {
	margin-bottom: 3rem;
    padding-left: 0;
    padding-right: 0;
}

.option-search__container {
	width: 100%;
	display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-select__next {
	position: relative;
    margin-top: -12px;
}

.option-search__container .swiper-container {
	margin-left: 0;
   margin-right: 0
}

.option-search__container .swiper-slide {
    width: auto!important;
}


@media only screen and (min-width: 375px) {
	.search-result__title{
		font-size: 2rem;
	}

	.search-programada__title {
		font-size: 1.3rem;
	}
	.search-programada__small {
		font-size: 1.3rem;
	}
}

@media only screen and (min-width: 768px) {
	.result-details-loading {
		flex-direction: row;
	}
}