.main__header {
	background: var(--color-orange);
	height: 263px;
}

.main__container {
	display: flex;
	flex-direction: column;
	padding-top: 12.5rem;
}

.plr-moto {
	padding-left: 30px!important;
    padding-right: 30px!important;
}

.main__title {
	width: 280px;
	margin: 0 auto;
}

.title-h3 {
	color: var(--color-white);
	font-size: 1.25rem;
	font-weight: 400;
	margin-bottom: 0;
	text-align: center;
}

.title-h1 {
	color: var(--color-white);
	font-size: 2.5rem;
	font-weight: 600;
	margin-top: 0;
	text-align: center;
	margin: .5rem auto 3rem;
}

.main__moto {
	width: 260px;
    margin: -1rem auto;
}

.main__moto .main__image {
	width: 100%;
}

.title-main {
	color: var(--color-gray-1);
	text-align: center;
	font-size: 1.8rem;
	font-weight: 600;
	margin-top: 5rem;
}

.text-orange {
	color: var(--color-orange);
}

.type-moto__container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	margin: 3rem auto;
}

.type-moto__mb {
	margin: 3rem auto 0;
}

.moto-item {
	background: var(--color-white);
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 10px;
	width: 120px;
    height: 135px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    cursor: pointer;
}

.moto-item:hover {
	box-shadow: 0px 3px 6px #F47422;
	border: 1px solid #F47422;
}

.moto-item__image {
	width: 100px;
    margin-top: 1.5rem;
}

.moto-item__title {
	color: var(--color-gray-5);
	font-size: 1.9rem;
	font-weight: 600;
	margin-top: -2rem;
}

.brand-row {
	height: 140px;
	background: var(--color-soft-gray);
}

.title-brand {
	font-size: 1.4rem;
	font-weight: 600;
	color: var(--color-gray-5);
	margin-top: 2.5rem;
}

.brand-item {
	width: 160px;
}

.brand-item .brand-item__image {
	width: 100%;
	height: 47px;
	object-fit: contain;
}

.brand-container {
	display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.brand-group {
	display: flex;
	flex-direction: column;
	height: 140px;
}

.moto-type__group {
	text-align: center;
	margin-top: 5rem;
}

.moto-type__subtitle {
	color: var(--color-orange);
	font-size: 2rem;
	font-weight: 500;
	margin-bottom: 0;
}

.moto-type__title {
	color: var(--color-gray-1);
	font-size: 4rem;
	font-weight: 600;
    margin-top: -1rem;
}

.moto-type__info {
	color: var(--color-gray-5);
	font-size: 1.3rem;
	font-weight: 400;
	text-align: center;
	margin-top: 2rem;
	margin-bottom: 3rem;
}

.moto-type__image {
	width: 100%;
	margin: 0 auto;
}

.moto-type__image .moto-type__img {
	width: 100%;
}

.moto-findout__title {
	color: var(--color-gray-1);
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 2rem;
    background: var(--color-white);
    margin-top: -2.5rem;
    width: 81px;
}

.moto-findout__line, .moto-findout__line-title {
	border: 1px solid #222529;
	margin-top: 6rem;
}

.moto-findout__container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}

.moto-findout__item {
	width: 125px;
    min-height: 245px;
	background: var(--color-white);
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
	padding: 1rem;
	margin-bottom: 3rem;
}

.moto-findout__brand {
	color: var(--color-gray-5);
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 0;
}

.moto-findout__name {
	color: var(--color-orange);
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 0;
}

.moto-findout__description {
	height: 66px;
}

.moto-findout__price {
	color: var(--color-gray-1);
	font-size: .8rem;
	font-weight: 600;
	text-transform: lowercase;
	margin-bottom: 0;
}

.moto-findout__primary {
	font-size: 1.2rem!important;
	font-weight: 600;
	width: 100%;
	margin-top: 1.5rem;
}

.moto-findout__logo {
	width: 50px;
	height: 20px;
}

.moto-findout__image {
	width: 100px;
    height: 109px;
    margin: 0 auto;
}

.moto-findout__image .moto-findout__img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.moto-findout__link {
	font-size: 1.6rem;
	color: var(--color-orange);
	font-weight: 500;
	text-decoration: underline;
}

.moto-findout__link:hover {
	color: rgb(244 116 34 / 70%);
}

.moto-findout__scooter{
	text-align: center;
    margin-bottom: 3rem;
    margin-top: 1rem;
}

.moto-findout__sport {
	text-align: center;
    margin-bottom: 0;
    margin-top: 1rem;
}

.moto-need__title {
	color: var(--color-gray-1);
	font-size: 3rem;
	font-weight: 600;
	width: 260px;
    margin: 0 auto;
}

.moto-need {
	margin-top: 8rem;
	text-align: center;
}

.moto-need__mt {
	margin-top: 6rem;
}

.moto-need__image {
	width: 70px;
    height: 35px;
    margin: -3rem auto 3rem;
    background: var(--color-white);
}

.moto-need__image .moto-accesorio {
	width: 100%;
    height: 100%;
    object-fit: contain;
}

.bg-f0f0f0 {
	background: var(--color-soft-gray);
}

.bg-fff {
	background: var(--color-white);
}

.moto-findout__footer {
	position: relative;
	width: 100%;
}

.moto-findout__view {
	position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    text-align: center;
    background: transparent;
}

.moto-type__container {
	display: flex;
	flex-direction: column;
}

.moto-type__item {
	width: 100%;
}

.mt-text {
	margin-top: -5rem;
}

.moto-findout__white {
	height: 55px;
	background: var(--color-white);
}

.find-site__title {
	color: var(--color-white);
	font-size: 2rem;
    font-weight: 500;
    text-align: center;
    padding-top: 13rem;
    padding-bottom: 5rem;
}

.find-site__row {
	background: var(--color-orange);
	margin-top: -9rem;
	padding-bottom: 3rem;
}

.featured-title {
	color: var(--color-gray-1);
	font-size: 2.5rem;
	font-weight: 600;
	padding-top: 6rem;
	padding-bottom: 4rem;
}

.col-featured {
	padding-right: 0!important;
	padding-bottom: 3rem;
}

.col-featured .container-featured {
	padding-right: 0!important;
}

.featured-moto__slide {
	height: 300px;
}

.featured-moto__slide .swiper-slide-w {
	width: 140px!important;
}

.featured-moto__slide .swiper-slide-w .moto-findout__item {
	width: 140px;
}

.moto-home__title {
	color: var(--color-gray-1);
	font-size: 2.4rem;
	font-weight: 600;
	width: 260px;
    margin: 0 auto;
}

.moto-home__info {
	color: var(--color-gray-5);
	font-size: 1.05rem;
	font-weight: 400;
	text-align: center;
	margin-top: 1rem;
	margin-bottom: 3rem
}

.moto-home__container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 2rem;
}

.moto-home__item {
	width: 124px;
	height: 95px;
	border-radius: 5px;
	margin-bottom: 2rem;
	position: relative;
}

.moto-home__item:hover .moto-home__type, .moto-home__item:focus .moto-home__type  {
	height: 97%;
	transition: height 0.25s ease-in;
}

.moto-home__type {
	width: 97%;
    height: 30px;
    background: rgb(244 116 34 / 49%);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    position: absolute;
    bottom: 3px;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    transition: height 0.15s ease-out;
}

.moto-home_type-title {
	font-size: 1.5rem;
	color: var(--color-white);
	font-weight: 600;
	margin-bottom: 0;
}

.moto-home__btn {
	height: 46px;
	font-size: 2rem!important;
	font-weight: 500;
	color: var(--color-white);
	width: 100%;
	margin-top: 1rem;
	margin-bottom: 3rem;
	padding-top: 0.75rem!important;
}

.home-moto__image {
	width: 45px;
    height: 45px;
    padding: 1rem;
    background: var(--color-soft-orange);
    border-radius: 50%;
    margin: -3.5rem auto 3rem;
}

@media only screen and (min-width: 350px) {
	.moto-findout__item {
		width: 141px;
	}

	.moto-findout__name {
		font-size: 1.2rem;
	}

	.moto-findout__price {
		font-size: .9rem;
	}

	.find-site__title {
		font-size: 2.4rem;
	}

	.featured-moto__slide .swiper-slide-w {
		width: 150px!important;
	}

	.featured-moto__slide .swiper-slide-w .moto-findout__item {
		width: 150px;
	}

	.moto-home__item {
		width: 140px;
    	height: 107px;
	}
}

@media only screen and (min-width: 370px) {
	.title-main {
		font-size: 2.2rem;
		margin-top: 7rem;
	}

	.moto-item {
		width: 145px;
		height: 145px;
	}

	.title-h1 {
		font-size: 2.8rem;
	}

	.main__title {
		width: 300px;
	}

	.title-h3 {
		font-size: 1.45rem;
	}

	.moto-type__info {
	    font-size: 1.4rem;
	}

	.moto-findout__item {
		width: 145px;
	}

	.moto-type__title {
		font-size: 5rem;
	}

	.moto-type__subtitle {
		font-size: 2.5rem;
	}

	.find-site__title {
		width: 300px;
    	margin: 0 auto;
	}

	.featured-moto__slide .swiper-slide-w {
		width: 170px!important;
	}

	.featured-moto__slide .swiper-slide-w .moto-findout__item {
		width: 170px;
	}

	.moto-home__item {
		width: 145px;
    	height: 112px;
	}
}

@media only screen and (min-width: 414px) {
	.type-moto__container {
		width: 356px;
	}

	.moto-item {
		width: 157px;
	}

	.title-h3 {
		font-size: 1.6rem;
	}

	.title-h1 {
		font-size: 3.2rem;
	}

	.main__title {
		width: 350px;
	}

	.title-main {
		font-size: 2.4rem;
	}

	.moto-type__info {
	    font-size: 1.6rem;
	}

	.moto-type__image {
		width: 350px;
	}

	.moto-findout__item {
		width: 165px;
		min-height: 255px;
	}

	.moto-findout__price {
		font-size: 1.1rem;
	}

	.moto-findout__image {
	    width: 113px;
	    height: 115px;
	}

	.banner-moto__find {
	    height: 450px;
	}

	.moto-home__container {
		width: 350px;
    	margin: 2rem auto 0;
	}

	.moto-home__item {
		width: 166px;
    	height: 128px;
	}

	.moto-home__info {
	    font-size: 1.4rem;
	}

	.moto-home__title {
	    font-size: 2.8rem;
	    width: 100%;
	}
}

@media only screen and (min-width: 600px) {
	.find-site__title {
	    width: 500px;	
	}

	.featured-moto__slide .swiper-slide-w {
		width: 190px!important;
	}

	.featured-moto__slide .swiper-slide-w .moto-findout__item {
		width: 190px;
	}

	.moto-home__item {
		width: 200px;
    	height: 152px;
	}

	.moto-home__container {
		width: 450px;
	}

	.moto-home__info {
	    font-size: 1.6rem;
	}

	.moto-home__title {
	    font-size: 2.7rem;
	    width: 100%;
	}

	.moto-home__btn {
		width: 450px;
	}
}

@media only screen and (min-width: 768px) {
	.type-moto__container {
		width: 685px;
		margin: 3rem auto 6rem;
	}

	.moto-item {
		height: 150px;
		width: 150px;
	}

	.main-title {
		width: 385px;
	}

	.title-h1 {
		font-size: 3rem;
    	text-align: left;
	}

	.title-h3 {
    	text-align: left;
	}

	.main__container {
		flex-direction: row;
		align-items: center;
		padding-top: 11rem;
	}

	.main__moto {
		width: 400px;
	}

	.moto-item__image {
	    width: 112px;
	}

	.title-main {
	    font-size: 3rem;
	    margin-top: 1rem;
	}

	.brand-group {
		display: flex;
	    flex-direction: row;
	    align-items: center;
	    justify-content: space-around;
	}

	.title-brand {
    	font-size: 2rem;
    }

    .brand-item {
	    width: 200px;
	}

	.brand-item .brand-item__image {
		height: 60px;
	}

	.moto-need__title {
		width: 100%;
	}

	.moto-findout__line-title {
		width: 300px;
    	margin: 0 auto;
	}

	.moto-type__container {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 3rem;
		margin-top: 3rem;
	}

	.moto-type__group {
		text-align: left;
		margin-top: 1rem;
	}

	.moto-type__item .moto-type__info {
		text-align: left;
		font-size: 1.4rem;
	}

	.moto-type__image {
		width: 300px;
	}

	.moto-findout__item {
	    width: 200px;
	    min-height: 275px;
	    padding: 2rem;
	}

	.moto-findout__name {
	    font-size: 1.5rem;
	}

	.moto-findout__price {
	    font-size: 1.2rem;
	}

	.moto-findout__image {
	    width: 150px;
	    height: 125px;
	}

	.moto-findout__view {
	    top: 2px;
	}

	.find-site__row {
	    margin-top: -15rem;
	}

	.find-site__title {
	    font-size: 3.5rem;
	    padding-top: 20rem;
	    width: 700px;
	}

	.featured-moto__slide .swiper-slide-w {
		width: 220px!important;
		min-height: 285px;
	}

	.featured-moto__slide .swiper-slide-w .moto-findout__item {
		width: 220px;
		min-height: 285px;
	}

	.featured-moto__slide {
		margin-right: 0;
		height: 400px;
	}

	.col-featured .container-featured {
	    padding-right: 15px;
	}

	.col-featured {
	    padding-right: 15px;
	}

	.featured-title {
	    font-size: 4.5rem;
	}

	.find-site__row {
		padding-bottom: 7rem;
	}

	.moto-findout__title {
		font-size: 2rem;
	    margin-top: -3rem;
	    width: 98px;
	}

	.moto-findout__logo {
		width: 95px;
    	height: 31px;
	}

	.moto-findout__logo img {
		width: 100%!important;
	    height: 100%;
	    object-fit: fill;
	}

	.moto-home__container {
		width: 100%;
		margin-top: 4rem;
	}

	.moto-home__item {
	    width: 162px;
	    height: 124px;
	}

	.moto-home__type {
	    height: 40px;
	}

	.moto-home_type-title {
    	font-size: 1.8rem;
    }

    .moto-home__info {
    	font-size: 2rem;
    }

    .moto-home__title {
	    font-size: 3rem;
	    width: 100%;
	}

	.moto-home__btn {
		background: var(--color-white)!important;
	    color: var(--color-orange)!important;
	    width: 270px;
	}

	.moto-home__btn:hover {
		background: var(--color-primary)!important;
    	color: var(--color-white)!important;
	}

	.line-home {
    	width: 366px;
    }

    .home-moto__image {
	    margin: -2.5rem auto 3rem;
	}
}

@media only screen and (min-width: 992px) {
	.type-moto__container {
		width: 900px;
	}

	.moto-item {
		width: 200px;
		height: 200px;
	}

	.moto-item__title {
		font-size: 2.5rem;
		margin-top: -3rem;
	}

	.moto-item__image {
	    width: 150px;
	}

	.title-main {
	    font-size: 4.5rem;
	    margin-top: 0rem;
	}

	.moto-type__item .moto-type__info {
	    font-size: 1.6rem;
	}

	.moto-type__item {
	    width: 450px;
	}

	.moto-type__image {
	    width: 350px;
	}

	.moto-type__container {
		margin-top: 7rem;
	}

	.moto-need__title {
    	font-size: 4rem;
	}

	.moto-type__info {
	    font-size: 1.9rem;
	}

	.mt-text {
	    margin-top: 2rem;
	}

	.moto-findout__link {
	    font-size: 2rem;
	    text-transform: uppercase;
	}

	.moto-findout__item {
	    width: 240px;
	    min-height: 310px;
	}

	.moto-findout__image {
	    width: 165px;
	    height: 155px;
	}

	.moto-findout__name {
	    font-size: 1.7rem;
	}

	.moto-findout__price {
	    font-size: 1.4rem;
	}

	.moto-findout__primary{
    	font-size: 1.5rem!important;
    }

    .moto-findout__container {
    	margin-bottom: 5rem;
    	margin-top: 4rem;
    }

    .moto-findout__view {
	    top: -28px;
	}

	.find-site__title {
	    font-size: 4rem;
	    width: 800px;
	}

	.moto-findout__description {
		height: 86px;
	}

	.moto-home__item {
		width: 229px;
    	height: 173px;
	}

	.moto-home__type {
	    height: 45px;
	}

	.moto-home_type-title {
    	font-size: 2rem;
    }

    .moto-home__title {
	    font-size: 3.5rem;
	}

	.home-moto__image {
		width: 60px;
		height: 60px;
		padding: 1.5rem;
	    margin: -3.5rem auto 3rem;;
	}
}


@media only screen and (min-width: 1200px) {
	.type-moto__container {
	    width: 1000px;
	}

	.title-h1 {
	    font-size: 5rem;
	}

	.main__title {
	    width: 550px;
	}

	.main__moto {
	    width: 550px;
	}

	.main__header {
	    height: 380px;
	}

	.title-h3 {
	    font-size: 2.2rem;
	}

	.brand-item {
	    width: 250px;
	}

	.brand-item .brand-item__image {
		height: 70px;
	}

	.title-brand {
    	font-size: 2.5rem;
    }

    .moto-type__item {
	    width: 660px;
	}

	.moto-type__item .moto-type__info {
	    font-size: 1.9rem;
	}

	.moto-type__title {
	    font-size: 8rem;
	    margin-bottom: 0;
	}

	.moto-type__subtitle {
	    font-size: 3.5rem;
	}

	.moto-type__image {
	    width: 420px;
	}

	.moto-home__item {
		width: 260px;
    	height: 197px;
	}
}

