.badge-new {
  color: #fff;
  background-color: #d05032;
  margin-left: 0.5rem;
  font-weight: normal !important;
  font-size: 1.1rem !important;
  border-radius: 0 !important;
}

.title {
  color: #212529;
  font-size: 2rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.item-offer {
  box-shadow: 0px 3px 13px #0000000d;
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
  padding-bottom: 1rem;
}

.item-offer__category {
  color: #858584;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0;
}

.item-offer__brand {
  color: #212529;
  font-size: 1.8rem;
  font-weight: 600;
}

.btn-send {
  height: 32px;
  font-size: 1.5rem !important;
  font-weight: 500;
  color: #d83009;
  width: 100%;
  border-radius: 5px !important;
  margin-top: 1.5rem;
}

.btn-send:hover {
  color: var(--color-white);
}

.item-offer__photo {
  height: 115px;
  width: 100%;
}

.item-offer__photo img {
  width: 100%;
  height: 100%;
}

.container-offer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5rem;
  grid-auto-rows: 1fr;
  margin-bottom: 10rem;
}

.item-offer__body {
  padding: 1rem;
}

.background-all {
  background: #f0f0f0;
}

.item-offer__description {
  background: rgb(244 116 34 / 5%);
  border-radius: 5px;
  color: var(--color-gray-1);
  font-size: 12px;
  height: 84px;
  font-family: "Poppins", sans-serif;
  padding: 1rem;
  text-overflow: ellipsis;
}

.item-offer__description p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.item-empty__description {
  height: 84px;
}

.item-offer__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 370px) {
  .item-offer__photo {
    height: 150px;
  }

  .item-offer__category {
    font-size: 1.2rem;
  }

  .item-offer__brand {
    font-size: 1.9rem;
  }
}

@media only screen and (min-width: 414px) {
  .item-offer__category {
    font-size: 1.3rem;
  }

  .item-offer__brand {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 500px) {
  .container-offer {
    grid-template-columns: repeat(2, 1fr);
  }

  .item-offer__photo {
    height: 110px;
  }
}

@media only screen and (min-width: 768px) {
  .container-offer {
    grid-template-columns: repeat(2, 1fr);
  }

  .item-offer__photo {
    height: 180px;
  }

  .title {
    font-size: 2.5rem;
  }

  .item-offer__description {
    font-size: 14px;
    height: 100px;
  }

  .item-empty__description {
    height: 100px;
  }
}

@media only screen and (min-width: 992px) {
  .container-offer {
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
  }

  .item-offer__photo {
    height: 150px;
  }

  .item-offer__body {
    padding: 1.5rem;
  }

  .item-offer__category {
    font-size: 1.5rem;
  }

  .item-offer__brand {
    font-size: 1.9rem;
    margin-top: -0.5rem;
  }

  .title {
    font-size: 3.5rem;
    margin-top: 12rem;
    margin-bottom: 5rem;
  }

  .item-offer__description {
    font-size: 15px;
    height: 84px;
  }

  .item-empty__description {
    height: 84px;
  }

  .item-offer__description p {
    height: 63px;
  }
}

@media only screen and (min-width: 1200px) {
  .item-offer__body {
    padding: 2em;
  }

  .btn-send {
    height: 40px;
    font-size: 1.8rem !important;
    border-radius: 5px !important;
  }

  .item-offer__description,
  .item-empty__description {
    height: 85px;
  }
}
