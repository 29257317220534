@media only screen and (min-width: 768px) {
  .service__backdrop--ser {
    background: url("../../images/background/home_service_desktop.jpg")
      no-repeat;
    background-size: 100% 80%;
    height: 240px;
  }

  .services-container .services-container--small {
    font-size: 1.5rem;
  }

  .services-container .services-container--store {
    font-size: 4rem;
    margin-bottom: 3rem;
    margin-top: 7rem;
  }

  .service-container {
    width: 70%;
  }

  .d__header {
    margin-top: -20rem;
  }

  .search__type--title {
    font-size: 1.8rem;
  }

  .service__backdrop--ser .search-advanced_form__control,
  .search-advanced--label {
    font-size: 1.4rem;
  }

  .service__container--home .service__details {
    width: 160px;
    height: 150px;
  }

  .panel__title--service {
    text-align: left;
  }

  .panel__title--service__top {
    margin-top: 100px;
  }

  .result-services {
    justify-content: flex-start;
  }

  .result-services__store {
    margin-bottom: 800px;
  }

  .services-item__title {
    font-size: 1.8rem;
    line-height: 1.8rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .services-item__image img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .d-services__imagen img {
    height: 180px;
  }

  .service-panel-item__title {
    font-size: 1.4rem;
  }

  .service-panel-button button {
    width: 100%;
    font-size: 1.2rem;
  }

  .service-panel .card__body {
    padding: 3rem 2rem;
  }

  .service-panel-footer--left {
    padding: 0;
  }

  .service-result-car {
    width: 200px;
    height: 270px;
  }
  .result-car__title {
    font-size: 1.6rem;
    line-height: 1.5rem;
    height: 30px;
  }

  .result-item__car {
    width: 170px;
    height: 120px;
  }

  .result-item__price {
    font-size: 1.5rem;
    margin-top: 1rem;
  }

  .result-item__transmision {
    font-size: 1.2rem;
  }

  .d-services__return--left {
    right: 25px;
  }

  .d-services__imagen {
    height: 180px;
  }

  .d-services__concessionaire {
    margin-top: 6rem;
    margin-bottom: -1rem;
  }

  .d-services__descripcion h1,
  .d-services__concessionaire .title_concessionaire {
    font-size: 3rem;
  }

  .service-result__small {
    padding-right: 2rem;
  }

  .service-features__panel {
    flex-direction: row;
  }

  .d-services__return {
    top: 31px;
  }

  #typeOfService {
    font-size: 1.3rem;
  }

  .service__container--other {
    grid-template-columns: repeat(2, 1fr);
    width: 50%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 992px) {
  .service-container {
    width: 60%;
  }

  .service__container--home .service__details {
    width: 200px;
    height: 180px;
  }

  .service-panel-item__title {
    font-size: 1.7rem;
  }

  .service-panel-button button {
    font-size: 1.5rem;
  }

  .service-panel .card__body {
    flex-direction: row;
    justify-content: space-around;
  }

  .service-panel-footer--left {
    margin-top: 0;
    width: 30%;
    padding-left: 2rem;
  }

  .service-features__panel {
    width: 60%;
  }

  .service__title {
    font-size: 1.5rem;
    line-height: 2.2rem;
    width: 100%;
  }

  /**.service__container--default {
		grid-template-columns: repeat(5, 1fr);
	}**/

  .service__new_result--default {
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 15rem;
  }

  .service__container--other {
    grid-template-columns: repeat(2, 1fr);
    width: 40%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1200px) {
  .service__container--home .service__details {
    color: var(--color-white);

    font-size: 1.6rem;

    font-weight: bold;

    margin-bottom: 0px !important;

    font-family: "Poppins", sans-serif;

    position: absolute;

    top: 50%;

    left: 50%;

    text-transform: uppercase;

    line-height: 3.5rem;
    width: 235px;
    height: 220px;
  }

  .services-item {
    width: 100%;
  }

  .services-item__image {
    width: 100%;
    height: 140px;
  }

  .service-panel-item__title {
    font-size: 2rem;
  }

  .d-services__descripcion h1,
  .d-services__concessionaire .title_concessionaire {
    font-size: 4rem;
  }

  .service-result-car {
    width: 220px;
  }

  /**.service__details {
	    width: 250px;
	    height: 250px;
	}**/

  .service__title {
    font-size: 2.2rem;
    line-height: 2.8rem;
    width: 100%;
  }

  .service__new_result--default .service__title {
    font-size: 2rem;
    line-height: 3rem;
    width: 100%;
  }
}
