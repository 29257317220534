.bg-service-tire {
  background: linear-gradient(#fff 22%, #f0f0f0 22%);
}

.service-map-background {
  background: linear-gradient(#f0f0f0 13%, #fff 6%);
}

.loading-car {
  height: 100vh;
  width: 100%;
}

.loading-car__text {
  font-size: 1.3rem;
  font-weight: normal;
  color: var(--color-gray-5);
  margin-bottom: 3rem;
  text-align: center;
  margin-top: -8rem;
}

.message-main {
  display: none;
}

.message-alert {
  background: #feeee4;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  width: 281px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-alert p {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
  color: var(--color-orange);
  margin-bottom: 0;
}

.message-alert--icon {
  width: 28px;
  height: 28px;
  background: var(--color-white);
  border-radius: 50%;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-alert--icon img {
  width: 50%;
}

.message-alert--vin {
  position: absolute;
  z-index: 100;
  top: -39px;
  right: 12px;
}

.modal-95w {
  width: 95%;
  max-width: none !important;
  margin: 0 auto;
  padding: 1.5rem;
}

.modal-75w .modal-content {
  padding: 1rem;
}

.notification-icon {
  width: 120px;
  height: 120px;
  background: #ffe1ce;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.5rem;
}

.notification__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
}

.notification-text {
  text-align: center;
}

.notification-text__title {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  color: var(--color-gray-1);
  margin-bottom: 1.5rem;
}

.notification-text__subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  color: var(--color-gray-5);
}

.modal-footer {
  border-top: none;
}

/******************Menu****************************************/
.menu {
  padding: 2.2rem 2rem;
  position: fixed;
  z-index: 1000000;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.menu__logo {
  display: flex;
}

.menu__logo--closed {
  /**width: 20%;**/
  cursor: pointer;
}

.menu__logo--white {
  width: 225px;
  text-align: center;
  margin: 0 auto;
}

.menu__logo--white img {
  width: 100%;
}

.menu__item {
  height: 308px;
  margin-top: 4.5rem;
}

.menu__item ul {
  padding: 0;
}

.menu__item ul li {
  list-style: none;
  text-align: center;
  margin-bottom: 1rem;
}

.menu__item--link:hover {
  text-decoration: none;
  color: var(--color-white);
}

.menu__item--link {
  text-decoration: none;
  font-size: 2rem;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--color-white);
  cursor: pointer;
}

.menu__item--link:focus {
  color: var(--color-white);
  text-decoration: none;
}

.menu__item--link:hover {
  color: #212529;
}

/*******************Menu mobile************************************/
.menu__desktop--item {
  display: flex;
  flex-direction: row;
  list-style: none;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
}

.menu__desktop--item li {
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu__desktop--item li a {
  font-size: 1.5rem;
  font-weight: normal;
  color: var(--color-gray-1);
  transition: 0.7s;
}

.menu__desktop--item li .dropdown-service {
  font-size: 1.5rem;
  font-weight: normal;
  color: var(--color-gray-1);
  transition: 0.7s;
  margin-bottom: 0;
}

.dropdown-service {
  cursor: pointer;
}

.dropdown-service:after {
  content: url("../../images/icons/down-arrow-black.svg");
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
}

.header__route figure {
  text-align: center;
  margin-bottom: 0;
}

.header__route {
  position: relative;
}

.number-cars {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: red;
  position: absolute;
  top: -6px;
  right: -8px;
}

.number-cars p {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  height: 20px;
  width: 20px;
  font-size: 1rem;
}

.header__route figure figcaption {
  font-size: 1rem;
  font-weight: normal;
  color: var(--color-white);
}

.menu__desktop {
  display: none;
}

/*******************Footer************************************/
.footer__container {
  margin-top: 65px;
  display: flex;
  flex-direction: column;
}

.footer__logo {
  margin-bottom: 50px;
}

.schedule__title-hidden {
  display: none;
}

.footer__schedule {
  margin-top: 60px;
}

.footer__list_link ul {
  list-style: none;
  padding-left: 0;
}

.footer__list_link ul li {
  padding: 8px 0px;
}

.footer__list_link ul li a {
  text-decoration: none;
  color: var(--color-white);
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
}

.menu__item ul li .footer-event {
  color: var(--color-white);
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  border: 1px solid var(--color-white);
  padding: 0.5rem 5rem;
  width: 225px;
}

.menu__item ul li.list-event .footer-event:visited,
.menu__item ul li.list-event .footer-event:hover {
  color: var(--color-white);
}

.footer__list_link ul li button {
  color: var(--color-white);
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  padding-left: 0;
}

.footer__list_link ul li a:hover,
.menu__item ul li .footer-event:hover {
  color: #212529;
}

.border-menu__bottom {
  border-bottom: 1px solid var(--color-white);
  margin-bottom: 5rem !important;
  padding-bottom: 1rem;
}

.footer__schedule,
.schedule__cars a {
  color: white;
  font-size: 1.5rem;
}

.schedule__title {
  font-weight: bold;
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--color-white);
  color: var(--color-white);
}

.schedule__cars,
.schedule__services {
  margin-top: 20px;
}

.schedule__cars p,
.schedule__services p {
  margin-bottom: 0;
}

.social_media {
  padding-top: 25px;
  padding-bottom: 65px;
  display: flex;
}

.icon-media--width {
  width: 45px;
}

.footer__copyright {
  background: rgb(33 37 41 / 20%);
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.footer__copyright .text__copyright {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 0;
  color: var(--color-white);
}

.bg-gray {
  background: var(--color-soft-gray);
}

.btn-close-modal {
  cursor: pointer;
}

.tag-pointer {
  cursor: pointer;
}

.mapa-local svg[aria-label="tooltip"] {
  z-index: 1000;
}

.catalogy-selectbox {
  width: 100%;
  margin: auto;
  position: relative;
}

.selectbox__select {
  background: var(--color-white);
  width: 100%;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease all;
  margin-bottom: 15px;
  padding: 15px;
  position: relative;
  z-index: 200;
  border: none;
  height: 55px;
}

.selectbox__select--contenido h1 {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: var(--color-gray-1);
  margin-bottom: 0;
}

.selectbox__select.active,
.selectbox__select:hover {
  border: none;
}

.selectbox__select.active .catalogy_down_arrow {
  background: url("../../images/icons/down-arrow-orange.svg") no-repeat;
}

.selectbox__select:hover .catalogy_down_arrow {
  background: url("../../images/icons/down-arrow-orange.svg") no-repeat;
}

.selectbox__select.active:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-top: 15px solid var(--color-gray-3);
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid transparent;
  position: absolute;
  bottom: -30px;
  left: calc(50% - 15px);
}

.catalogy_down_arrow {
  background: url("../../images/icons/down-arrow-search.svg") no-repeat;
  display: inline-block;
  width: 15px;
  height: 18px;
  vertical-align: bottom;
  margin-top: 0.75rem;
  transform: rotate(90deg);
}

.catalogy_down_arrowY {
  transform: rotate(270deg);
  margin-top: -0.75rem;
}

.selectbox__opciones {
  background: var(--color-white);
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
  max-height: 250px;
  overflow: auto;
  z-index: 100;
  width: 100%;
  display: none;
  position: absolute;
}

.selectbox__opciones.active {
  display: block;
}

.selectbox__opciones--opcion {
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s ease all;
  height: 37px;
}

.selectbox__opciones .selectbox__opciones--opcion {
  padding: 10px 15px;
}

.selectbox__opciones .selectbox__opciones--opcion:hover {
  background: var(--color-soft-orange);
  cursor: pointer;
}

.selectbox__opciones .selectbox__opciones--opcion:hover .titulo,
.selectbox__opciones .selectbox__opciones--opcion:hover .descripcion {
  color: #000;
}

.selectbox__opciones--opcion .titulo {
  margin-bottom: 0;
  font-size: 1.5rem;
  color: var(--color-gray-1);
  font-weight: normal;
}

.selectbox__opciones--opcion .titulo-item {
  margin-bottom: 0;
  font-size: 1.5rem;
  color: var(--color-gray-1);
  font-weight: normal;
}

.selectbox__select--contenido .titulo {
  margin-bottom: 0;
}

.catoly-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.message-error {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: red;
}

.my-route-back {
  cursor: pointer;
}

.error_not_search img {
  width: 100%;
}

.opacity-services--used {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  border-radius: 7px;
  height: 100%;
  width: 100%;
}

.service__details--used:hover .opacity-services--used {
  background-color: rgb(245 116 36 / 54%);
}

.slide-service .swiper-button-next,
.slide-service .swiper-button-prev {
  top: 40% !important;
  color: #f47422;
}

.slide-service .swiper-button-prev {
  left: 0;
}

.slide-service .swiper-button-next {
  right: -2px;
}

.slide-service .swiper-button-prev.swiper-button-disabled,
.slide-service .swiper-button-next.swiper-button-disabled {
  color: transparent;
}

.slide-service .swiper-wrapper {
  height: 176px;
}

.popover-body {
  width: 200px;
  text-align: center;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

/**********Carroceria******************/
.carousel-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 1rem;
}

.carroceria__imagen {
  position: relative;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carroceria_check {
  width: 70px;
  height: 28px;
  background: transparent;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.icon--city {
  background-image: url("../../images/carroceria/gris/city.png");
}

.carroceria__imagen:hover .carroceria__text {
  color: var(--color-orange);
}

.icon--city:checked,
.carroceria__imagen:hover .icon--city {
  background-image: url("../../images/carroceria/naranja/city_select.png");
}

.icon--city:checked + label {
  color: var(--color-orange) !important;
}

.icon--camioneta {
  background-image: url("../../images/carroceria/gris/camioneta.png");
}

.icon--camioneta:checked,
.carroceria__imagen:hover .icon--camioneta {
  background-image: url("../../images/carroceria/naranja/camioneta_select.png");
}

.icon--camioneta:checked + label {
  color: var(--color-orange) !important;
}

.icon--coupe {
  background-image: url("../../images/carroceria/gris/coupe.png");
}

.icon--coupe:checked,
.carroceria__imagen:hover .icon--coupe {
  background-image: url("../../images/carroceria/naranja/coupe_select.png");
}

.icon--coupe:checked + label {
  color: var(--color-orange) !important;
}

.icon--hatchback {
  background-image: url("../../images/carroceria/gris/hatchback.png");
}

.icon--hatchback:checked,
.carroceria__imagen:hover .icon--hatchback {
  background-image: url("../../images/carroceria/naranja/hatchback_select.png");
}

.icon--hatchback:checked + label {
  color: var(--color-orange) !important;
}

.icon--sedan {
  background-image: url("../../images/carroceria/gris/sedan.png");
}

.icon--sedan:checked,
.carroceria__imagen:hover .icon--sedan {
  background-image: url("../../images/carroceria/naranja/sedan_select.png");
}

.icon--sedan:checked + label {
  color: var(--color-orange) !important;
}

.icon--station_wagon {
  background-image: url("../../images/carroceria/gris/station_wagon.png");
}

.icon--station_wagon:checked,
.carroceria__imagen:hover .icon--station_wagon {
  background-image: url("../../images/carroceria/naranja/station_wagon_select.png");
}

.icon--station_wagon:checked + label {
  color: var(--color-orange) !important;
}

.icon--suv {
  background-image: url("../../images/carroceria/gris/suv.png");
}

.icon--suv:checked,
.carroceria__imagen:hover .icon--suv {
  background-image: url("../../images/carroceria/naranja/suv_select.png");
}

.icon--suv:checked + label {
  color: var(--color-orange) !important;
}

.icon--comercial {
  background-image: url("../../images/carroceria/gris/comercial.svg");
}

.icon--comercial:checked,
.carroceria__imagen:hover .icon--comercial {
  background-image: url("../../images/carroceria/naranja/comercial_select.svg");
}

.icon--comercial:checked + label {
  color: var(--color-orange) !important;
}

.custom-label-all:hover {
  color: var(--color-orange);
}

.carroceria__text {
  margin-top: 3px;
}

.carousel-items--filter {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}

.carousel-items--filter .carroceria__imagen {
  width: 90px;
  margin-top: 2rem;
}

.options-filter__item .form__filter-label {
  font-size: 1.5rem;
}

.title-filter-group,
.options-filter__item .search__type--title,
.options-filter__item .form-filter__title {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-gray-1);
  margin-bottom: 2rem;
}

.options-filter .option-filter-d {
  flex: none;
}

._hj_feedback_container
  ._hj-1O2Rr__MinimizedWidgetMiddle__container._hj-1QnQT__MinimizedWidgetMiddle__right
  ._hj-21t0-__MinimizedWidgetMiddle__label {
  border: 1px solid white;
  box-shadow: 0px 3px 6px #f0f0f0;
}

._hj_feedback_container ._hj-21t0-__MinimizedWidgetMiddle__label {
  font-size: 15px;
  width: 45px;
}

.modal_initial-result .modal__closed {
  position: absolute;
  right: 2.5rem;
  top: 2rem;
}

.modal_initial-result .modal-content {
  padding: 0;
  background: transparent;
  border: none;
}

.not-found {
  height: 90vh;
  margin: auto;
  width: 100%;
}

.not-found img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal-home .modal-content {
  padding: 0;
  background: transparent;
  border: none;
}

.modal-home {
  width: 90%;
  margin: 0 auto;
  border: none;
  background: transparent;
}

.modal-home__image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-home__image img {
  max-width: 100%;
  height: auto;
}

.disclaime-price {
  text-align: left;
  order: 4;
  width: 100%;
}

.disclaime-price__text {
  margin-bottom: 2rem;
  font-size: 0.8rem;
  color: var(--color-gray-5);
  text-align: justify;
}

.btn-soap__header {
  vertical-align: middle;
  height: 30px;
  padding-left: 1rem;
  padding-right: 1rem;
  color: var(--color-white);
  font-size: 1.5rem;
  background-color: var(--color-primary);
  border-radius: 5px;
  padding-top: 0.2rem;
}

.btn-soap__header:hover {
  background-color: rgb(226 67 34 / 70%);
}

.header__white .menu__desktop .menu__desktop--item li .menu__desktop--link {
  color: var(--color-white);
}

.header__white
  .menu__desktop
  .menu__desktop--item
  .menu-dropdown
  .dropdown-service:after,
.background-top-transparent
  .menu__desktop
  .menu__desktop--item
  .menu-dropdown
  .dropdown-service:after {
  content: url("../../images/icons/up-arrow-white.svg");
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
}

.header__white .menu__desktop .menu__desktop--item li .btn-soap__header {
  color: var(--color-gray-1);
}

.header__white .header__route .fig-myroute {
  background-color: var(--color-gray-4);
}

.header__white .btn-soap__header,
.btn-soap__header--white {
  vertical-align: middle;
  height: 30px;
  padding: 0.5rem 2rem;
  color: var(--color-gray-2);
  font-size: 1.5rem;
  background-color: var(--color-white);
  border-radius: 5px;
}

.header__white .btn-soap__header:hover,
.btn-soap__header--white:hover {
  background-color: rgb(255 255 255 / 70%);
}

.menu-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-container {
  position: absolute;
  background-color: transparent;
  min-width: 183px;
  z-index: 1;
  top: 22px;
  right: -387px;
}

.menu-dropdown > .dropdown-container {
  display: none;
}

.dropdown-content .menu-option {
  color: var(--color-gray-1);
  padding: 1.5rem 2rem;
  display: block;
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  width: 100%;
  text-align: left;
  background: var(--color-white);
}

.dropdown-content .menu-option:hover {
  background-color: var(--color-primary);
}

.menu-option--first {
  border-radius: 5px 0px 0px 0px;
  cursor: pointer;
}

.menu-option--last {
  border-radius: 0px 5px 0px 0px;
}

.menu-dropdown:hover > .dropdown-container {
  display: flex;
}

.menu-dropdown:hover > .dropdown-service:after {
  transform: rotate(180deg);
}

.mt-7 {
  margin-top: 7rem;
}

.options-search--top {
  margin-top: 6.3rem;
}

.options-container--top {
  margin-top: 1rem;
}

.mt-header {
  margin-top: 6.5rem;
}

.btn-calendar {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  color: white;
  display: none;
}

.card-height {
  height: 100px;
}

.marque-link {
  height: 40px;
  background: var(--color-orange);
  margin-top: 3rem;
}

.marque-link__title {
  margin-bottom: 0;
  font-size: 1.3rem;
  color: var(--color-white);
  text-align: center;
}

.marque-link__title:hover {
  color: #212529;
}

.marque-link__container .swiper-wrapper {
  height: 30px;
  width: auto;
  padding-top: 1rem;
}

.marque-link__container .swiper-wrapper .swiper-slide {
  text-align: center;
  height: 30px;
}

.map-link-in {
  padding-bottom: 3rem;
}

.options-photo {
  width: 100%;
}

video,
.options-photo {
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  height: 280px;
}

.screenshot-image {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.preview-photo__boton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.photo-boton {
  height: 37px;
  border-radius: 5px;
  width: 45%;
  font-size: 2rem;
  border: none;
  margin-top: 2rem;
}

.menu__item ul li:last-child {
  border-bottom: 1px solid var(--color-white);
  padding-bottom: 3rem;
}

.header__white .btn-soap__header:hover,
.btn-soap__header--white:hover {
  color: var(--color-gray-1);
}

@media only screen and (min-width: 380px) {
  .carousel-items {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (min-width: 600px) {
  .btn-calendar {
    display: block;
  }

  .carousel-items {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (min-width: 768px) {
  .carousel-items {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 1200px) {
  .carousel-items {
    grid-template-columns: repeat(4, 1fr);
  }
}

.menu-bold {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
