.form-custom__title, .form-main--title {
	font-size: 1.8rem;
	font-weight: 500;
	color: var(--color-gray-1);
	margin-bottom: 2.5rem;
    margin-top: 1.5rem;
}

.form-custom__row {
	display: flex;
	justify-content: space-between;
}

.form-custom__group, .form-car__group {
	position: relative;
	width: 48%;
}

.form-custom__group label, .form-car__group label {
	font-size: 1.2rem;
	font-weight: normal;
	color: var(--color-gray-1);
	position: absolute;
    padding-left: 1rem;
    padding-top: .5rem;
}

.form-custom__input, .form-car__input {
	height: 50px!important;
	background:var(--color-white)!important;
	border: 1px solid var(--color-gray-9)!important;
	border-radius: 5px!important;
	padding-top: 2rem!important;
	padding-left: 1rem!important;
	font-size: 1.2rem!important;
	font-weight: normal!important;
}

.form-custom__input:focus, .form-car__input:focus {
	color: var(--color-gray-5)!important;
    background-color: var(--color-white)!important;
    box-shadow: 0px 3px 6px #F474221D!important;
	border: 0.4000000059604645px solid #F47422!important;
    outline: 0!important;
}

.form-custom__type {
	display: flex;
	margin-top: 1rem;
	margin-bottom: 2.5rem;
}


.form-custom--check {
	background: var(--color-white);
    border: 1px solid var(--color-gray-3);
    border-radius: 1px;
    width: 15px;
    height: 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    margin-right: 5px;
    position: relative;
    cursor: pointer;
}

.form-custom--check:focus {
    outline: none;
}

.form-custom--check:checked {
	background: var(--color-orange);
	border: none;
}

.form-custom--check:checked::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 3px;
    width: 12px;
    height: 2px;
    background: var(--color-white);
    transform: rotate(-45deg);
}
.form-custom--check:checked::after {
	content: "";
    position: absolute;
    top: 9px;
    left: 2px;
    width: 4px;
    height: 2px;
    background: var(--color-white);
    transform: rotate(45deg);
}

.form-custom--checkbox {
	width: 20px;
}

.form-custom--label, .form-car--label {
	font-size: 1rem;
	font-weight: normal;
	color: var(--color-gray-5);
	cursor: pointer;
}

.form-custom--label a, .form-car--label a {
	text-decoration: underline;
	color: inherit;
}

.form-custom__button {
	width: 100%;
	font-size: 2rem!important;
	padding: .5rem 1rem!important;
}


.form-custom {
	font-family: 'Poppins', sans-serif;
	width: 100%;
}

.form-contact__custom {
	border: 0!important;
	margin-top: 3rem!important;
}

.form-contact__car {
	border: 0!important;
}

.card__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    padding: 1.25rem 2rem!important;
    background: var(--color-soft-gray);
    border-radius: 0.7rem;
}

.card__container {
	width: 100%;
	margin: 0 auto;
	border-radius: 0.7rem!important;
	box-shadow: 0px 3px 6px var(--color-gray-8);
}

.service-form-contact {
	width: 100%;
    margin-top: 4rem;
    margin-bottom: 4rem;
    border: none;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

.form__contact-input {
    width: 100%!important;
    height: 55px!important;
    background: var(--color-white);
    box-shadow: 0px 3px 6px var(--color-ligth-gray);
    border-radius: 5px!important;
    opacity: 1;
    padding: 3rem 1.5rem 1.5rem 1.5rem!important;
    font-size: 1.3rem!important;
    font-family: 'Poppins', sans-serif;
    font-weight: normal!important;
}

.form__contact-input--telephone {
    padding-left: 44px!important;
}

.form-group-row {
    position: relative;
    width: 100%;
    margin-bottom: 2rem!important;
}

.form-group-row label {
    position: absolute;
    top: 0.5rem;
    left: 1.5rem;
    font-size: 1.3rem;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    color: var(--color-gray-1);
    cursor: pointer;
}

.form-opciones__group {
    display: flex;
    flex-direction: column;
}

.form-contact__title {
    font-size: 2rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: var(--color-gray-1);
    line-height: 2.5rem;
    margin-bottom: 0;
}

.form-opciones__subtitle {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    font-weight: normal;
    color: var(--color-gray-5);
    margin-bottom: 0;
}

.form-contact__text {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    flex-direction: column;
}

.service-opciones__help {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    color: var(--color-orange);
    align-self: flex-end;
    margin-bottom: 0;
    margin-top: 1rem;
}

.form__contact-check {
    border: 0.5px solid var(--color-orange);
}

.form__contact-check {
    background: var(--color-white);
    border: 1px solid var(--color-gray-3);
    border-radius: 1px;
    width: 15px;
    height: 15px;
    appearance: none;
    outline: none;
    margin-right: 5px;
    position: relative;
    cursor: pointer;
}

.form__contact-check:checked {
    background: var(--color-orange);
    border: none;
}

.form__contact-label {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    color: var(--color-gray-5);
    margin-left: 0.5rem;
    margin-bottom: 0;
    cursor: pointer;
}

.form__contact-label a {
    text-decoration: underline;
    color: inherit;
}

.form__contact-check:checked::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 3px;
    width: 12px;
    height: 2px;
    background: var(--color-white);
    transform: rotate(-45deg);
}

.form__contact-check:checked::after {
    content: "";
    position: absolute;
    top: 9px;
    left: 2px;
    width: 4px;
    height: 2px;
    background: var(--color-white);
    transform: rotate(45deg);
}

.form-group-checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.padding-body {
    padding: 1.25rem 1.5rem!important;;
}

.service-form-contact--white {
    background: var(--color-white);
    border: 0;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 10px!important;
	margin-bottom: 4rem;
}

.service-form-contact--white .card__body {
    background: var(--color-white);
}

.form__contact-input--telephone-car {
    padding-left: 43px!important;
}

@media only screen and (min-width: 600px) {
	.service-form-contact {
		width: 80%;
	}
}

@media only screen and (min-width: 768px) {
	.service-form-contact {
		width: 70%;
	}

	.form-opciones__group {
	    flex-direction: row;
	}

	.form-contact__container {
	    width: 70%;
	}

    .service-form-contact--white {
        width: 70%;
    }
}

@media only screen and (min-width: 992px) {
	.form-custom {
		width: 90%;
	}

	.form-main--title {
		font-size: 2rem;
	}

	.form-custom__group label {
		font-size: 1.5rem;
	}

	.form-custom__input {
		height: 60px!important;
	}

	.form-custom--label {
	    font-size: 1.3rem;
	}

	.group-contact .form-custom__button {
		width: 50%;
	}

	.group-contact {
		text-align: right;
	}

	.service-form-contact {
		width: 50%;
	}
}