.services__title {
	font-size: 2rem;
	font-weight: bold;
	font-family: 'Poppins', sans-serif;
	color: var(--color-white);
	text-align: center;
	margin-top: -3rem;
    margin-bottom: 1.5rem;
}




.d-service__backdrop {
	height: 148px;
	position: relative;
}

.d-service__logo-flex {
	display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -92px;
    margin-bottom: 1.5rem;
}

.d-service__logo {
    width: 125px;
	height: 125px;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 50%;
	background: var(--color-white);
	display: flex;
    align-items: center;
    justify-content: center;
}

.d-service__logo img {
	margin-top: -2rem;
}

.d-service__backdrop img {
	object-fit: cover;
	width: 100%;
}

.d-service__title {
	font-size: 2rem;
	font-weight: 600;
	color: var(--color-gray-1);
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 0;
}

.service-panel {
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 20px;
	margin-bottom: 3.5rem;
}

.service-panel-item {
	width: 100%;
	margin-bottom: .8rem;
	display: flex;
    flex-direction: row;
}

.service-panel-item__title {
	font-size: 1.3rem;
	font-weight: 500;
	color: var(--color-gray-1);
	margin-bottom: 0;
}

.service-panel-item__value {
	font-size: 1.3rem;
	font-weight: normal;
	color: var(--color-gray-5);
	margin-bottom: 0;
}

.service-panel-button {
	width: 100%;
	margin-bottom: 1rem;
}

.service-panel-button button {
	width: 155px;
	height: 34px;
	font-size: 1.3rem;
	font-weight: normal;
}

.service-search__body {
	padding: 0;
}

.service-search__tab {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 36px;
	font-family: 'Poppins', sans-serif;
	border-radius: 0.7rem;
}

.service-search__tab p{
	width: 100%;
	text-align: center;
	margin-bottom: 0;
	font-size: 1.5rem;
	font-weight: 500;
	color: var(--color-gray-5);
	background: var(--color-white);
	height: 100%;
	display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 0.7rem;
    cursor: pointer;
}

/**.tire-options p:last-child {
	border-top-right-radius: 0.7rem;
}**/



/**.tire-options p.active:last-child {
	border-top-left-radius: 0;
}**/


.service-search__container {
	width: 100%;
	padding: 1rem 2rem;
}

.service-search__row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1.5rem;
}

.service-search__column {
	width: 48%;
	position: relative;
}

.service-search__select {
	box-shadow: 0px 3px 6px #00000012;
	border-radius: 5px;
    height: 57px;
    font-size: 1.2rem;
    font-weight: normal;
    color: var(--color-gray-5);
    padding-top: 2rem;
    padding-left: .5rem;
    cursor: pointer;
}

.service-search__select:focus {
	color: #495057;
    background-color: #fff;
    border-color: #F47422;
    outline: 0;
    box-shadow: none;
}

.service-search__column label {
	font-size: 1.5rem;
	font-weight: 500;
	color: var(--color-gray-1);
	position: absolute;
    left: 1rem;
    top: .5rem;
}

.service-search {
	border: 0;
}

.service-result {
	margin-bottom: 3rem;
	padding-top: 3rem;
}

.service-result__small {
	font-size: 1.3rem;
	font-weight: normal;
	color: var(--color-gray-5);
	margin-bottom: 0;
	padding-right: 1rem;
}

.service-result__container {
	display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.service-result-item {
	width: 120px;
	/**height: 213px;**/
	background: var(--color-white);
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 10px;
	display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-right: 1rem;
    justify-content: center;
}

.result-item__title {
	font-size: 1.2rem;
	font-weight: 500;
	color: var(--color-gray-1);
	margin-bottom: .5rem;
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    height: 36px;
}

.result-item__image {
	width: 79px;
	height: 79px;
	border-radius: 36px;
	margin-bottom: .8rem;
}

.result-item__image img {
	width: 100%;
    height: 100%;
    object-fit: contain;
}

.result-item__number {
	font-size: 1rem;
	font-weight: normal;
	letter-spacing: 0.67px;
	color: var(--color-gray-5);
	margin-bottom: 0;
}

.result-item--sku {
	font-size: .7rem;
	letter-spacing: 0.47px;
}

.result-item__brand {
	display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #00000029;
    padding-bottom: .8rem;
    width: 125px;
	height: 40px;
	margin-top: 1rem;
}

.result-item__bimage {
	width: 130px;
    display: flex;
	align-items: center;
	justify-content: center;
    height: 40px;
}

.result-item__bimage img {
	width: 80%;
    height: 100%;
    object-fit: scale-down;
}

.result-item__quote {
	width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.result-item__quote-check {
	border: 0.6px solid var(--color-orange);
	border-radius: 2px;
	cursor: pointer;
}

.result-item__quote-label {
	font-size: 1.5rem;
	font-weight: normal;
	color: #858584;
	margin-top: .8rem;
	margin-left: .8rem;
	cursor: pointer;
}

.service-map {
	margin-bottom: 2rem;
}

.service-map__image img {
	width: 100%;
}


/************Formulario de Contacto*******************/
.form-contact {
	width: 100%;
}

.service-form-contact {
	background: var(--color-soft-gray);
	border: 0;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 10px;
	margin-bottom: 4rem;
}

.form-group-row {
	position: relative;
	width: 100%;
	margin-bottom: 2rem;
}

.form__contact-input {
	width: 100%;
    height: 55px;
    background: var(--color-white);
    box-shadow: 0px 3px 6px var(--color-ligth-gray);
    border-radius: 5px;
    opacity: 1;
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    font-size: 1.3rem;
	font-family: 'Poppins', sans-serif;
	font-weight: normal;
}

.form__contact-input:focus {
	border-color: var(--color-orange);
	box-shadow: none;
}

.form-group-row label {
	position: absolute;
    top: .5rem;
    left: 1.5rem;
    font-size: 1.3rem;
	font-family: 'Poppins', sans-serif;
	font-weight: normal;
	color: var(--color-gray-1);
	cursor: pointer;
}

.form-contact__text {
	display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    flex-direction: column;
}

.form__contact-check {
	border: 0.5px solid var(--color-orange);
}

.form__contact-select {
	height: 50px;
    width: 100%;
    box-shadow: 0px 3px 6px #00000012;
    border-radius: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.form__contact-select:focus {
	border-color: var(--color-orange);
	box-shadow: none;
}

.form-down-arrow {
	background: url('../../images/icons/down-arrow.svg');
	background-repeat: no-repeat;
	width: 22px;
	height: 15px;
	position: absolute;
	top: 20px;
    right: 20px;
}

.service-opciones__help {
	font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    color: var(--color-orange);
    align-self: flex-end;
    margin-bottom: 0;
    margin-top: 1rem;
}

.form-contact__title {
	font-size: 2rem;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	color: var(--color-gray-1);
	line-height: 2.5rem;
	margin-bottom: 0;
}

.form__contact-check:focus {
    outline: none;
}

.form__contact-check:checked {
	background: var(--color-orange);
	border: none;
}

.form__contact-check:checked::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 3px;
    width: 12px;
    height: 2px;
    background: var(--color-white);
    transform: rotate(-45deg);
}
.form__contact-check:checked::after {
	content: "";
    position: absolute;
    top: 9px;
    left: 2px;
    width: 4px;
    height: 2px;
    background: var(--color-white);
    transform: rotate(45deg);
}

.form-group-checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.form-group-checkbox .form__contact-label {
	font-size: 1rem;
	font-family: 'Poppins', sans-serif;
	font-weight: normal;
	color: var(--color-gray-5);
	margin-left: .5rem;
	margin-bottom: 0;
	cursor: pointer;
}

.form__contact-label a {
	text-decoration: underline;
	color: inherit;
}

.form__contact-check {
	background: var(--color-white);
    border: 1px solid var(--color-gray-3);
    border-radius: 1px;
    width: 15px;
    height: 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    margin-right: 5px;
    position: relative;
    cursor: pointer;
}

.service-form-contact--white {
	background: var(--color-white);
}

.service-form-contact--concesionarie {
	margin-top: 4rem;
}

.form-icon--question {
	background: url('../../images/icon/question.svg');
	width: 21px;
    height: 21px;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(22%, -78%);
    cursor: pointer;
}

.form-opciones__group {
	display: flex;
	flex-direction: column;
}

.item-resumen {
	min-height: 70px;
	width: 100%;
    background: var(--color-white);
    box-shadow: 0px 3px 6px var(--color-ligth-gray);
    border-radius: 5px;
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    border: 0.3px solid rgb(206, 212, 218);
}

.item-resumen__text {
	margin-bottom: 0;
	font-size: 1.3rem;
	font-family: 'Poppins', sans-serif;
	font-weight: normal;
}

/**************Neumaticos**********************/
.service__backdrop {
	height: 137px;
	position: relative;
	width: 100%;
	margin-top: 6rem;
}

.service__backdrop img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.carousel_tire__title {
	font-size: 2.5rem;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	color: var(--color-gray-3);
	text-align: center;
	margin-top: 5rem;
	line-height: 3rem;
	margin-bottom: 4rem;
}

.carousel_tire {
	margin-bottom: 4rem;
}


.gray-form-contact {
	padding-top: 6rem;
	background: rgb(133 133 132 / 24%);
	padding-bottom: 5rem;
}

.service-map__title {
	font-size: 2rem;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	color: var(--color-gray-1);
}


/******************Resultado de busqueda servicios*****************************************/
/***********Resultado de búsqueda******************************/
.services-container {
	font-family: 'Poppins', sans-serif;
	margin-top: 4rem;
	padding: 0;
}

.services-container h2 {
	font-size: 2rem;
	font-weight: 600;
	color: var(--color-gray-1);
	margin-bottom: 1.5rem;
}

.services-container .services-container--small {	
	font-size: 1.2rem;
	font-weight: normal;
	color: var(--color-gray-5);
	margin-bottom: 2.5rem;
}

.services-container .services-container--store {	
	font-size: 2rem;
	font-weight: 600;
	color: var(--color-gray-1);
	margin-bottom: 3rem;
	margin-top: 5rem;
}


.result-services {
	display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0;
}

.result-services__store {
	margin-bottom: 300px;
}


.services-item {
	width: 100%;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 10px;
	background: var(--color-white);
	cursor: pointer;
	margin-right: 0;
	overflow: hidden;
}

.services-item__title {
	font-size: 1.3rem;
	font-weight: 600;
	color: var(--color-gray-1);
	margin-bottom: 0;
	text-align: center;
	height: 60px;
	display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.3rem;
    padding-left: .5rem;
    padding-right: .5rem;
}

.services-item__image {
	width: 100%;
}

.services-item__image img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.form-contact--top {
	margin-top: -7rem;
    padding-top: 9rem;
}

.form-contact--store {
	padding-bottom: 7rem;
    padding-top: 9rem;
}


/*******************Detalle del servicio************************************/

.d-services__descripcion {
	margin-top: 6rem;
	margin-bottom: 2rem;
}

.d-services__concessionaire {
	margin-top: 7rem;
	margin-bottom: -1.5rem;
}

.d-services__descripcion h1, .d-services__concessionaire  .title_concessionaire {
	font-size: 2rem;
	text-align: center;
	font-weight: 600;
	margin-bottom: 3rem;
	text-transform: uppercase;
}


.services-details__title {
	font-family: 'Helvetica Neue', sans-serif;
	font-size: 2rem;
	font-weight: 600;
	color: var(--color-orange);
	margin-bottom: 2rem;
	margin-top: 1rem;
	text-transform: uppercase;
}

.services-details__title--top {
	margin-top: 4rem;
}


.services-details__subtitle {
	font-family: 'Poppins', sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--color-black);
    text-align: left;
}

.services-details__subtitle--orange {
	font-family: 'Poppins', sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--color-orange);
    text-align: left;
}

.services-details_text-1 {
	font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    font-weight: normal;
    text-align: left;
}

.services-details ul {
	margin-bottom: 2rem;
}

.services-details ul li {
	list-style: none;
	text-align: justify;
}

.services-details ul li:before { 
	content: '-'; 
	margin-right: .5rem; 
	font-size: 1.5rem;
	vertical-align: middle;
	color: var(--color-gray-5);
}

.d-services__imagen {
	margin-top: 2rem;
	position: relative;
	height: 150px;
}

.d-services__imagen img {
	object-fit: cover;
    width: 100%;
    border-radius: 5px;
    height: 100%;
}

.d-services__return {
    position: absolute;
    top: 31px;
    cursor: pointer;
}

.service__backdrop--tire {
	background: url('../../images/background/tire.png') no-repeat;
}

.d-services__return--right {
    right: 25px;
}

.d-services__return--left {
    left: 25px;
}


.service-panel-item__imagen {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: .5rem;
}

.service-panel-item__footer {
	margin-top: 1rem;
}

.service-panel-footer--left {
	padding-left: 3rem;
}

.service-features__imagen--category{
	content: url('../../images/search-services/categoria.png');
}

.service-features__imagen--local {
	content: url('../../images/search-services/local.png');
}

.service-features__imagen--phone {
	content: url('../../images/search-services/telefono.png');
}

.services-feature--details {
	margin-left: .5rem;
}

.d-service__marca {
	position: absolute;
	bottom: 0;
	transform: translate(-50%, 50%);
    left: 50%;
}

/**************************************************/
.d-concessionaire__logo {
	width: 115px;
	height: 115px;
	background: var(--color-white);
	border-radius: 50%;
	box-shadow: 0px 3px 6px #00000029;
	display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, 8%);
    overflow: hidden;
}

.d-concessionaire__logo img {
	width: 80%;
}

.result-item__price {
	font-family: 'Poppins', sans-serif;
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--color-orange);
	margin-bottom: 0;
	width: 100%;
	text-align: center;
}

.result-item__transmision {
	font-family: 'Poppins', sans-serif;
	font-size: 1rem;
	font-weight: normal;
	color: var(--color-gray-5);	
	width: 100%;
	padding-bottom: 1rem;
	border-bottom: 1px solid #00000029;
	margin-bottom: 0;
	text-align: center;
}

.result-item__car {
	width: 100px;
	height: 65px;
	border-radius: 61px;
}

.result-item__car img {
	width: 100%;
    height: 100%;
    object-fit: contain;
}

.service-result-car {
	width: 112px;
    height: 222px;
	background: var(--color-white);
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 10px;
	display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    padding: .5rem 1rem;
    margin-right: 1rem;
    cursor: pointer;
	margin-right: 1rem;
    justify-content: center;
}

.result-car__title {
	font-size: 1rem;
	font-weight: 500;
	color: var(--color-gray-1);
	margin-bottom: .5rem;
    margin-top: 1rem;
    width: 100%;
    text-align: center;
}

.service-result__h1 {
	font: normal normal 600 20px/32px Poppins;
	font-size: 2rem;
	font-weight: 600;
	color: var(--color-gray-3);
	margin-bottom: 0;
}

.service-result__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 3.5rem;
}

.service-result__header--top {
	margin-top: 4rem;
}


.bg-concessionaire {
    background: var(--color-soft-gray);
	padding-bottom: 80px;
}

.service-result__title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 3rem;
}

.service-panel  .card__body {
	align-items: flex-start;
	padding: 2rem;
}

.service-features__panel {
	width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}


#typeOfService {
	font-size: 1.2rem;
}

.service__backdrop--ser {
    background: url('../../images/background/services.png') no-repeat;
	background-size: 100%;
}

.file-to-car__title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--color-gray-5);
}

.list-carroceria .swiper-slide {
	width: 70px!important;
	text-align: center;
}

.d-concessionaire__description {
	font-size: 1.4rem;
	font-weight: normal;
	color: var(--color-gray-5);
	text-align: center;
	margin-bottom: 5rem;
}

.d-concessionaire__category {
	font-weight: normal;
	color: var(--color-gray-5);
	text-align: center;
    font-size: 1.4rem;
    margin-bottom: 4rem;
}

.info__measure {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.service-info__measure {
	font-size: 1.2rem;
	font-weight: 400;
	color: var(--color-gray-2);
}

.info__measure--icon {
	display: flex;
}

.info__measure--icon::before {
	content: url('../../images/icon-info.png');
	width: 19px;
	height: 19px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 1rem;
}

.service-link__measure {
	margin-left: .5rem;
	color: var(--color-orange);
	text-decoration: underline;
	cursor: pointer;
}

.service-link__active {
	margin-left: .5rem;
	color: var(--color-dark-gray);
	text-decoration: underline;
	cursor: pointer;
}

.close-panel__measure {
	text-align: right;
    margin-bottom: 1rem;
    height: 15px;
}

.close-panel__measure img {
    height: 100%;
}


@media only screen and (min-width: 375px) {
	.result-car__title {
		font-size: 1.2rem;
	}

	.result-item__price {
		font-size: 1.3rem;
	}

	.service-result__small {
		padding-right: 2rem;
	}

	.service-result-item {
		width: 150px;
	}

	.service-search__tab p{
		font-size: 1.5rem;
	}

	.service-result__container {
		justify-content: center;
	}
}

@media only screen and (min-width: 360px) {
	.service-result-car {
	    height: 261px;
	    margin-right: 1.5rem;
	    width: 124px;
	}

	.result-car__title {
	    font-size: 1.4rem;
	}

	.result-item__car {
	    width: 120px;
	    height: 80px;
	}

	.result-services {
	    justify-content: space-between;
	}

}

@media only screen and (min-width: 400px) {
	.service-result-car {
	    width: 145px;
	    height: 253px;
	    margin-right: 3rem;
	}

	.service__backdrop--tire {
		background-size: 100%;
	}
}
