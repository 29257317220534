@media only screen and (min-width: 768px) {

	.compare-button {
		justify-content: flex-end;
	}

	.compare-button button {
		width: 25%!important;
	}

	.compare-result__item {
	    width: 200px!important;
	}

	.compare__title {
		font-size: 1.3rem;
	}

    .compare-temp {
    	width: 160px;
    }

    .compare-title h1 {
    	font-size: 3rem;
    }

    .compare-subtitle {
    	font-size: 1.4rem;
    }

	.compare__info p {
	    font-size: 1.3rem;
	}

	.slide-compare .swiper-slide {
	    width: 200px!important;
	}
}

@media only screen and (min-width: 992px) {
	.compare__info p {
    	font-size: 1.5rem;
    }

    .compare-subtitle {
    	font-size: 1.8rem;
    }

    .compare__title {
		font-size: 1.7rem;
	}

	.compare-temp {
    	width: 200px;
    }

    .slide-compare .swiper-slide {
		width: 300px!important;
	}

	.compare-result__item {
	    width: 300px!important;
	}
}

@media only screen and (min-width: 1200px) {

}