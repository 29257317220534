.container-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.bg-gray {
  background-color: var(--color-gray-11);
}

.container-right {
  width: 100%;
}

.image-dsk img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title-h1 {
  position: absolute;
  font-size: 1.65rem;
  font-weight: 600;
  color: var(--color-warn-orange);
  margin-bottom: 0;
  width: 100%;
}

.subtitle {
  font-size: 1.7rem;
  font-weight: 600;
  color: var(--color-warn-orange);
  margin-bottom: 0;
  margin-top: 2rem;
}

.bg-main {
  background: var(--color-soft-orange);
}

.col {
  margin-top: 9rem;
}

.image-meec {
  height: 65px;
  margin-top: 7rem;
  margin-bottom: 3rem;
}

.image-meec img {
  height: 100%;
}

.info-title {
  color: var(--color-black);
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 0.25rem;
}

.info-description {
  font-weight: 400;
  font-size: 1.3rem;
  margin-top: 2rem;
  text-align: left;
}

.container-main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
  align-items: flex-start;
}

.container-expo__subtitle {
  margin-top: 5rem;
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1.7rem;
  font-weight: 600;
  color: var(--color-warn-orange);
}

.container-expo__description {
  font-size: 1.3rem;
  text-align: left;
  border-bottom: 1.5px solid var(--color-warn-orange);
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.location-title {
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-warn-orange);
  margin-bottom: 0;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 2rem;
}

.brand-title {
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-dark-gray);
  margin-bottom: 0;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.location-description {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-black);
}

.brand-description {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-dark-gray);
}

.location-container {
  margin-top: 2rem;
}

.location-meec {
  height: 30px;
}

.location-meec img {
  height: 100%;
}

.location-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.location-container__title {
  color: var(--color-dark-gray);
  font-size: 1.75rem;
}

.location-map img {
  width: 100%;
}

.location-visit {
  margin-top: 2rem;
}

.location-visit__title {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-warn-orange);
  width: 100%;
  text-align: center;
}

.location-visit__subtitle {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-dark-gray);
  width: 100%;
}

.location-partners {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1rem;
  padding-top: 3rem;
}

.location-partners__bottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1rem;
  padding-top: 0rem;
  padding-bottom: 7rem;
}

.location-partners__title {
  color: var(--color-warn-orange);
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0;
  width: 105px;
}

.location-partners__photo_1 img,
.location-partners__photo_2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.location-partners__photo_1,
.location-partners__photo_2 {
  width: 80px;
}

.location-map {
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
}

.brand-logo img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.brand-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  gap: 4rem 4rem;
  grid-auto-rows: 50px;
  margin-bottom: 5rem;
  margin-top: 3rem;
}

.bg-photo {
  position: relative;
}

.brand-background {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

.brand-col {
  padding-top: 6rem;
  padding-bottom: 2rem;
}

.container-expo__row {
  padding-bottom: 5rem;
}

@media only screen and (min-width: 385px) {
  .title-h1 {
    font-size: 1.8rem;
  }

  .location-description,
  .brand-description {
    font-size: 1.3rem;
  }

  .location-visit__title {
    margin-bottom: 2.5rem;
  }

  .location-visit {
    margin-top: 2rem;
  }
}

@media only screen and (min-width: 500px) {
  .title-h1 {
    font-size: 2.2rem;
  }

  .image-meec {
    height: 75px;
    margin-top: 9rem;
  }

  .info-title {
    font-size: 1.4rem;
  }

  .subtitle {
    font-size: 2rem;
  }

  .container-expo__subtitle {
    font-size: 2rem;
  }

  .info-description {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 768px) {
  .location-visit__title {
    font-size: 2rem;
  }

  .location-visit__subtitle {
    font-size: 1.4rem;
  }

  .location-partners__photo_1,
  .location-partners__photo_2 {
    width: 120px;
  }

  .location-partners {
    gap: 3rem;
  }

  .location-partners__bottom {
    gap: 3rem;
    padding-top: 2rem;
    padding-bottom: 10rem;
  }

  .location-partners__title {
    font-size: 1.4rem;
  }

  .location-map img {
    width: 80%;
  }

  .brand-container {
    grid-template-columns: repeat(5, 1fr);
    gap: 3rem 4rem;
  }
}

@media only screen and (min-width: 992px) {
  .container-main {
    margin-top: 4rem;
    flex-direction: row;
    justify-content: space-around;
    overflow: hidden;
    align-items: flex-start;
  }

  .container-main__left {
    width: 40%;
    padding-right: 3rem;
  }

  .container-main__right {
    width: 60%;
  }

  .title-h1 {
    font-size: 2.4rem;
    margin-top: 0.75rem;
  }

  .subtitle {
    font-size: 2.2rem;
    margin-top: 3rem;
  }

  .container-expo__subtitle {
    font-size: 2.2rem;
  }

  .location-visit__subtitle {
    font-size: 1.7rem;
  }

  .location-visit__title {
    font-size: 2.2rem;
    margin-bottom: 3rem;
  }

  .location-partners__title {
    font-size: 2.1rem;
    width: 180px;
  }

  .location-partners__photo_1,
  .location-partners__photo_2 {
    width: 150px;
  }

  .location-title,
  .brand-title {
    font-size: 3.5rem;
    padding-bottom: 3rem;
  }

  .location-container {
    font-size: 2rem;
  }

  .location-description,
  .brand-description {
    font-size: 1.7rem;
  }

  .location-meec {
    height: 60px;
  }

  .location-container__title {
    font-size: 2.2rem;
  }

  .location-map {
    margin-bottom: 10rem;
  }

  .brand-container {
    grid-template-columns: repeat(6, 1fr);
    gap: 6rem;
    grid-template-rows: 100px 80px 100px;
  }

  .location-partners__bottom {
    padding-bottom: 20rem;
  }

  .brand-col {
    padding-bottom: 12rem;
  }

  .location-title {
    padding-top: 9rem;
  }
}

@media only screen and (min-width: 1200px) {
  .title-h1 {
    font-size: 2.5rem;
  }

  .image-meec {
    height: 95px;
    margin-top: 11rem;
  }

  .info-title {
    font-size: 1.6rem;
  }

  .container-main__left {
    width: 36%;
  }

  .container-main__right {
    width: 64%;
  }

  .info-description {
    font-size: 1.6rem;
  }

  .container-expo__description {
    font-size: 1.7rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }

  .container-expo__subtitle {
    margin-top: 7rem;
    margin-bottom: 6rem;
  }

  .location-title {
    padding-top: 7rem;
  }

  .location-partners__photo_1,
  .location-partners__photo_2 {
    width: 180px;
  }
}
