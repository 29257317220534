.quote-seller__img {
    cursor: pointer;
    height: 120px;
}

.ctitle-quote {
    font-weight: 600;
	font-size: 2rem;
	font-family: 'Poppins', sans-serif;
    color: var(--color-gray-1);
}

.form-group__quote {
    font-weight: 500;
	font-size: 1.5rem;
	font-family: 'Poppins', sans-serif;
    color: var(--color-gray-1);
}

.form-quote__input {
    height: 37px;
    font-size: 1.3rem;
}

.group__quote-line {
    border-top: 0.1rem solid black;
    margin-top: 3rem;
}

.form-quote__select {
    font-size: 1.3rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0px 3px 6px #00000012;
    border-radius: 5px;
    color: var(--color-gray-10);
    height: 37px;
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url("../../images/cotizar_vende/down-arrow.svg") no-repeat;
    background-position-x: 97%;
    background-position-y: 14px;
    padding-top: 2rem;
}

.form-quote__select--top {
    padding-top: 0;
}

.form-quote__result {
    background: #FFE1CE;
    margin-left: -30px;
    margin-right: -30px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
}

.form-quote__result .search-result {
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 0;
}

.form-quote__result .search-result--grey {
    font-weight: 500;
    color: var(--color-gray-5);
}

.form-quote__result .search-result--orange {
    font-weight: 600;
    color: var(--color-orange);
}

.form-quote__accordion .card, .form-quote__accordion .card-header {
    background: #F0F0F0;
    border-radius: 5px;
}

.accordion-quote {
    margin-top: 2rem;
}

.accordion-quote .card {
    padding: 0;
    margin-top: 1rem;
    border-bottom: none;
    border-radius: 5px;
    border: none;
}

.accordion-quote .card-header{
    background: var(--color-soft-gray);
    font-size: 1.6rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-3);
    height: 45px;
    border-radius: 7px!important;
}

.card-header .accordion-quote__imagen {
    width: 14px;
}

.accordion-quote__imagen-top {
    transform: rotate(0deg);
}

.accordion-quote__imagen-bottom {
    transform: rotate(180deg);
}

.btn__quote-car{
    height: 42px;
    border-radius: 5px;
    width: 100%;
    font-size: 2rem;
    border: none;
    margin-top: 2rem;
}

.btn__quote-car--gray{
    background: var(--color-gray-10);
}

.quote-version__container {
    background: var(--color-white);
    box-shadow: 0px 3px 6px var(--color-ligth-gray);
    border: 0.3px solid #ced4da;
    border-radius: 5px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    line-height: 1.5rem;
}

.quote-version__scroll {
    height: 200px;
    overflow-y: scroll;
}

.quote-version__scroll::-webkit-scrollbar {
    width: 10px;
}

.quote-version__scroll::-webkit-scrollbar-track {
    background-color: var(--color-white);
    border-radius: 5px;
    box-shadow: none;
}

.quote-version__scroll::-webkit-scrollbar-thumb {
    background: #8A8B8D;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 30px;
}

.quote-version__title {
    margin-bottom: 0;
    font-size: 1.2rem;
    font-weight: 500;
}

.quire-car__input {
    width: 100%;
    height: 60px;
    position: absolute;
    appearance: none;
    cursor: pointer;
}

.quire-car__input:checked + .quote-sire__selected {
    border: 1px solid var(--color-orange);
}

.quire-car__input:checked + .quote-sire__selected .quote-sire__item-title, 
.quire-car__input:checked + .quote-sire__selected .quote-sire__item-subtitle {
    color: var(--color-orange);
}

.quote-mileage__label {
    font-size: 1.4rem;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-1);
}

.quote-contact__form {
    position: relative;
}

.quote-contact__form .quote-contact__label {
    position: absolute;
    margin-bottom: 0;
    padding: .5rem 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-1);
    left: 0;
}

.quote-contact__input {
    height: 49px;
    font-size: 1.2rem;
    padding-top: 2.5rem;
    padding-left: 1rem;
    color: var(--color-gray-3);
}

.quote-contact__input:focus {
    background-color: #fff;
    border-color: rgb(244 116 34 / 50%);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(244 116 34 / 25%);
}

.quote-contact__checkbox {
    background: var(--color-white);
    border: 1px solid var(--color-gray-3);
    border-radius: 1px;
    width: 20px;
    height: 16px;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    margin-right: 5px;
    position: relative;
    cursor: pointer;
}

.quote-contact__checkbox:focus {
    outline: none;
}

.quote-contact__checkbox:checked {
	background: var(--color-orange);
	border: none;
    width: 18px;
}

.accordion-quote .card-header--selected {
    color: var(--color-gray-1);
}

.quote-contact__checkbox:checked::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 3px;
    width: 12px;
    height: 2px;
    background: var(--color-white);
    transform: rotate(-45deg);
}
.quote-contact__checkbox:checked::after {
	content: "";
    position: absolute;
    top: 9px;
    left: 2px;
    width: 4px;
    height: 2px;
    background: var(--color-white);
    transform: rotate(45deg);
}

.form-custom__lblCheckbox {
    font-size: 1.1rem;
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-1);
    margin-bottom: 0;
}

.quote-contact__title {
    font-size: 1.2rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-1);
    margin-bottom: 2rem;
}


.btn__search-patente {
    width: 52px;
    border-top-right-radius: 5px!important;
    border-bottom-right-radius: 5px!important;
}

.form-quote__patente{
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.group__quote_info {
    position: relative;
}

.group__quote-span {
    position: absolute;
    top: -1rem;
    left: calc(50% - 33px);
    background-color: var(--color-white);
    width: 70px;
    width: 70px;
    text-align: center;
}

.quote-result__title {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.quote-result__title-main {
    font-size: 2.2rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: var(--color-orange);
}

.quote-result__subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-10);
    margin-bottom: 0;
}

.quote-result__small {
    font-size: 1.5rem;
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-10);
    margin-bottom: 0;
}

.quote-price {
    background: var(--color-white);
    box-shadow: 0px 3px 6px #00000012;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 5px;
    height: 72px;
    text-align: center;
    margin-bottom: 1rem;
}

.quote-price__title {
    background-color: var(--color-orange);
    width: 240px;
    margin-bottom: 0;
    color: var(--color-white);
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    margin: -10px auto 0;
    
}

.quote-price__subtitle {
    font-size: 2rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-1);
    margin-top: 1.5rem;
}

.quote-result__info {
    font-size: 1rem;
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-3);
    text-align: center;
    margin-bottom: 3rem;
}

.quote-result__completed {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-1);
    text-align: center;
    margin-bottom: 2rem;
    line-height: 2rem;
}

.quote-result__item {
    background: var(--color-white);
    box-shadow: 0px 3px 6px #00000012;
    border: 1px solid var(--color-orange);
    border-radius: 5px;
    margin-bottom: 1.5rem;
    height: 79px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: flex-start;
    text-align: left;
}

.quote-result__item-title {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-1);
    margin-bottom: 0;
}

.quote-result__item-subtitle {
    font-size: 1.2rem;
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-1);
    margin-bottom: 0;
}

.quote-result__link {
    text-decoration: underline;
    font-size: 1.4rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: var(--color-orange);
    text-align: center;
    margin-bottom: 3rem;
    cursor: pointer;
}

.quote-sure__title {
    font-size: 2rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-1);
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: .5rem;
}

.quote-sure__subtitle {
    font-size: 1.5rem;
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-1);
    text-align: center;
    margin-bottom: .5rem;
}

.quote-sure__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 5rem;
    margin-bottom: 10rem;
}

.quote-sure__button {
    border: 1px solid var(--color-orange);
    border-radius: 5px;
    width: 138px;
    height: 122px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.quote-sure__button:hover {
    background-color: rgb(244 116 34 / 20%);
}

.sure__button-title {
    font-size: 1.8rem;
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
    color: var(--color-orange);
    margin-bottom: 0;
    margin-top: -0.5rem;
}

.sure__button--weigth {
    font-weight: 500;
}

.modal-quotes {
    font-family: 'Poppins', sans-serif;
}

/*********************************Cotizar seguro******************/
.quote-sure__main {
    text-align: center;
}

.quote-sure__main__title {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-orange);
    text-transform: uppercase;
}

.quote-sure__process {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sure-process__item {
    background: var(--color-gray-10);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: .5rem;
}

.sure-process__item.active {
    background: var(--color-orange);
}

.quote-contact__info {
    font-size: .9rem;
    font-weight: normal;
    color: var(--color-gray-3);
    text-align: right;
    margin-top: 1rem;
}

.btn-quote-sire{
    height: 42px;
    border-radius: 5px;
    width: 100%;
    font-size: 2rem;
    border: none;
    margin-top: 2rem;
}

.quote-sire__used {
    font-size: 1.5rem;
    font-weight: normal;
    color: var(--color-gray-1);
    text-align: left;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.quote-sire__item {
    margin-bottom: 1.5rem;
    position: relative;
}


.quote-sire__item-title {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color-gray-1);
    margin-bottom: 0;
}

.quote-result__item-title {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-1);
    margin-bottom: 0;
}

.quote-sire__item-subtitle {
    font-size: 1.1rem;
    font-weight: normal;
    color: var(--color-gray-1);
    margin-bottom: 0;
}

.quote-sire__step {
    font-size: 1.5rem;
    font-weight: normal;
    color: var(--color-gray-1);
    cursor: pointer;
}

.quote-sire__step::before {
    content: url("../../images/icons/down-arrow.svg");
    transform: rotate(90deg);
    position: absolute;
    margin-left: -25px;
}

.form-quote__select--height {
    height: 49px;
}

.form-quote__select:focus {
    color: #495057;
    background-color: #fff;
    border-color: var(--color-orange);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(244 116 34 / 25%);
}

.form-quote__checkbox {
    width: 100%;
    height: 60px;
    position: absolute;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    left: 0;
}

.quote-sire__selected {
    background: var(--color-white);
    box-shadow: 0px 3px 6px #00000012;
    border: 1px solid #ced4da;
    border-radius: 5px;
    height: 60px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    cursor: pointer;
}

.form-quote__checkbox:checked + .quote-sire__selected {
    border: 1px solid var(--color-orange);
}

.form-quote__checkbox:checked + .quote-sire__selected .quote-sire__item-title, 
.form-quote__checkbox:checked + .quote-sire__selected .quote-sire__item-subtitle {
    color: var(--color-orange);
}

.bg__quote-patente__result {
    background: #FFE5D6;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.quote-patente__result {
    height: 87px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 1.5rem;
}

.patente__result-info--title {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--color-orange);
    margin-bottom: 0;
}

.patente__result-info--subtitle {
    font-size: 1.5rem;
    font-weight: normal;
    color: var(--color-orange);
    margin-bottom: 0;
    margin-top: 0rem;
}

.quote-patente__result-link {
    font-size: 1.6rem;
    font-weight: normal;
    color: var(--color-gray-3);
    margin-bottom: 0;
    cursor: pointer;
}

.quote-patente__result-image {
    width: 50px;
    height: 50px;
    background: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}


.patente__image--rosa {
    background: #FFE1CE;
}

.text-error {
    color: #dc3545;
    text-align: left;
    font-size: 1.2rem;
    margin-bottom: 0;
}

.quote-result-price {
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--color-orange);
    margin-bottom: 0;
}

.quote-result-muted {
    font-size: .85rem;
    font-weight: normal;
    color: var(--color-gray-1);
    margin-bottom: 0;
}

.quote-result__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: .5px solid var(--color-gray-1);
    padding-bottom: 2rem;
}

.quote-result-uf {
    background: var(--color-orange);
    border-radius: 100px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.quote-result-uf .quote-result-uf__title {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color-white);
    text-transform: uppercase;
    margin-bottom: 0;
}

.quote-result__body {
    height: 190px;
}

.quote-result__body-title {
    margin-top: 2rem;
    font-size: 1.35rem;
    font-weight: 500;
    color: var(--color-gray-3);
}

.quote-result__body-list {
    margin-left: 2rem;
    text-align: justify;
}

.quote-result__body-item {
    font-size: 1.2rem;
    font-weight: normal;
    color: var(--color-gray-1);
    margin-bottom: 1rem;
    list-style-image: url("../../images/check.svg");
}

.row-quote__container{
    margin-right: -30px;
    margin-left: -30px;
    margin-top: -30px;
}

.quote-result__picture {
    width: 120px;
}

.quote-result__picture img {
    width: 100%;
}

.row-quote__bg {
    height: 22px;
    background: #E4E4E4;
    margin-right: -30px;
    margin-left: -30px;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.quote-patente__result-info {
    text-align: center;
}

.quote--item__result{
    display: flex;
    flex-direction: column;
}

.quote-seller {
    margin-bottom: 3rem;
}


.span-telephone {
    position: absolute;
    top: 2.6rem;
    left: 1rem;
    font-size: 1.2rem;
}

.telephone--left {
    padding-left: 3.5rem;
}


.quote-result__footer {
    position: relative;
}

.check-mark-point {
    position: absolute;
    right: 113px;
    width: 20px;
    height: 18px;
    top: 9px;
}

.check-mark-point::before {
    position: absolute;
    left: 0;
    top: 50%;
    height: 50%;
    width: 3px;
    background-color: var(--color-white);
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}

.check-mark-point::after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    background-color: var(--color-white);
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}

.soap-item__text {
    min-height: 40px;
    text-align: center;
}

.quote-result__empty {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: var(--color-gray-1);
    margin-bottom: 0.5rem;
    text-align: center;
}

.font-20 {
    font-size: 2rem;
}

.font-30 {
    font-size: 3rem;
}

.text-zero {
    color: var(--color-dark-gray);
}

.quote-result__line {
    width: 200px;
    margin: 0 auto;
    border: 1px solid var(--color-black);
    margin-bottom: 3rem
}

@media only screen and (min-width: 400px) {
    .quote-result-price {
        font-size: 2.2rem;
    }

    .quote-result-muted {
        font-size: 1.2rem;
    }
}

@media only screen and (min-width: 768px) {
    .quote--item__result{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .quote--item__offers {
        box-shadow: 0 3px 6px var(--color-gray-8);
        width: 320px;
        margin-bottom: 3rem;
    }

    .modal-90w {
        width: 90%;
        max-width: none!important;
    }

    .patente__result-info--title{
        font-size: 3rem;
    }

    .patente__result-info--subtitle {
        font-size: 2rem;
    }

    .quote-patente__result-link {
        font-size: 2.5rem;
    }

    .quote-patente__result-image {
        width: 75px;
        height: 75px;
    }
}

@media only screen and (min-width: 992px) {
    .quote--item__offers {
        width: 350px;
    }
}