.header-tires {
    width: 100%;
}

.slider-tires {
    height: 100px;
    position: relative;
}

.slider-tires__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-gray-3);
}

.tires__template--default {
    height: 100px;
    position: relative;
    margin-top: 5.5rem;
}

.header-tires__img {
	height: 100%;
    object-fit: fill;
}

.d-tire__return {
    text-align: right;
    cursor: pointer;
}

.tire__return--img {
    width: 20px;
    height: 20px;
}

.services__title__tire {
	font-size: 1.8rem;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: var(--color-white);
    text-align: center;
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
}

.services__title__authorized {
	font-size: 1.8rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: var(--color-white);
    text-align: center;
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
}

.d__header {
	margin-top: -11rem;
}

.service-search__tab p.active {
	background: var(--color-gray-3);
	color: var(--color-white);
	border-radius: 5px 5px 0px 0px;
}

@media only screen and (min-width: 380px) {
	.slider-tires, .tires__template--default {
	    height: 130px;
	}

	.services__title__tire {
		font-size: 2.2rem;
	}

	.services__title__authorized {
		font-size: 2.2rem;
	}

	.d__header {
	    margin-top: -13rem;
	}
}

@media only screen and (min-width: 410px) {
	.services__title__tire {
	    width: 320px;
	    margin: 4rem auto 1.5rem;
	}

	.services__title__authorized {
	    width: 320px;
	    margin: 4rem auto 1.5rem;
	}
}

@media only screen and (min-width: 500px) {
	.slider-tires, .tires__template--default {
	    height: 120px;
	}

	.services__title__tire {
	    width: 400px;
	    font-size: 2.5rem;
		margin: 7rem auto 1.5rem;
	}

	.services__title__authorized {
	    width: 400px;
	    font-size: 2.5rem;
		margin: 7rem auto 1.5rem;
	}

	.tire__return--img {
	    width: 25px;
	    height: 25px;
	}

	.d__header {
	    margin-top: -16rem;
	}

}

@media only screen and (min-width: 768px) {
	.slider-tires, .tires__template--default {
	    height: 250px;
	}

	.tire__return--img {
	    width: 25px;
	    height: 25px;
	}

	.services__title__tire {
	    font-size: 3rem;
	    width: 100%;
	    margin-bottom: 3rem;
	}

	.services__title__authorized {
	    font-size: 3rem;
	    width: 100%;
	    margin-bottom: 3rem;
	}

	.service-search {
		width: 90%;
		margin-bottom: 6rem;
		margin-top: 2rem;
	}

	.service-search__container {
		display: flex;
		flex-direction: row;
		padding: 1rem;
	}

	.service-search__form {
		width: 55%;
		padding-right: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}

	.info__measure {
		width: 45%;
		margin: 1rem auto;
	}

	.service-search__row {
		padding-right: 1rem;
	}

	.service-search__row:last-child  {
		padding-right: 0;
	}

	.service-search__button {
	    width: 100%;
	}

	.service-form-contact {
	    width: 70%;
	}
}


@media only screen and (min-width: 992px) {
	.slider-tires, .tires__template--default {
	    height: 270px;
	}

	.service-search  {
		width: 80%;
	}


	.service-search__form {
		width: 50%;
	}

	.info__measure {
		width: 50%;
	}

	.service-search__select {
		height: 62px;
	}

	.tire__return--img {
	    width: 30px;
	    height: 30px;
	}

	.services__title__tire {
		margin: 1rem auto 5rem;
	}

	.services__title__authorized {
		margin: 1rem auto 5rem;
	}

	.service-form-contact {
	    width: 60%;
	}
}

@media only screen and (min-width: 1200px) {
	.service-search {
	    width: 75%;
	}

	.service-form-contact {
	    width: 50%;
	}
}