.item__active {
    color: var(--color-black)!important;
    font-weight: 500!important;
    font-size: 1rem!important;
}

.item-list:nth-child(1)::before {
    content: url("assets/images/icons/home.svg");
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: .15rem;
}

.item-list {
    color: var(--color-dark-gray)!important;
    font-size: 1rem!important;
}

.item-list a {
    color: #858584;
}

.col {
    margin-top: 7rem;
    margin-bottom: 0;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0!important;
}

.container nav {
    justify-self: flex-start;
    width: 100%;
}

.nav-ol {
    padding-left: 0;
    margin-bottom: 0!important;
    padding-bottom: 0!important;
    background: transparent!important;
    justify-content: flex-start;
    align-items: center;
}

.col-result-crumb {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

@media only screen and (min-width: 414px) {
    .item-list {
        font-size: 1.5rem!important;
    }

    .item__active {
        font-size: 1.5rem!important;
    }

    
    
}

@media only screen and (min-width: 768px) {
    .container {
        align-items: center;
    }

    .col-result-crumb {
        margin-top: 9rem;
        margin-bottom: 3rem;
    }
    
    .col {
        margin-top: 9rem;
    }
}